import * as React from 'react';
import CompaniesIndex from '../../components/Companies/index';
import * as BuildingsApi from '../../api/buildings';

export default class CompaniesIndexContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      buildingId: this.props.match.params.buildingId,
      building: {},
      companies: [],
      pages: 0,
      page: 1,
      isLoading: true,
    };

    this.onPageChange = this.onPageChange.bind(this);
  }

  async onPageChange({ page }) {
    const { data, headers } = await BuildingsApi.listCompanies(this.state.buildingId, {
      limit: 10,
      offset: (page - 1) * 10,
    });

    const pages = parseInt(headers['x-total-count'], 10);

    this.setState({
      companies: data,
      pages,
      page,
    });
  }

  async componentDidMount() {
    const { data } = await BuildingsApi.get(this.state.buildingId);

    this.setState({ building: data });
    this.onPageChange({ page: 1 });
  }

  render() {
    return (
      <CompaniesIndex
        companies={this.state.companies}
        building={this.state.building}
        isLoading={this.state.isLoading}
        pages={this.state.pages}
        page={this.state.page}
        onPageChange={this.onPageChange}
      />
    );
  }
}
