import * as React from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import WhoVisitedWho from '../../components/Stats/who-visited-who';
import * as StatsApi from '../../api/stats';

class WhoIsVisitedWhoContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      buildings: [],
      form: {
        building_id: null,
        company_id: null,
        from: moment().startOf('month'),
        to: moment().endOf('day'),
        host_name: '',
        host_surname: '',
        host_email: '',
        guest_name: '',
        guest_surname: '',
        guest_email: '',
      },
      data: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    this.setState({ form: body });
    const res = await StatsApi.whoVisitedWho(body);

    this.setState({ data: res.data });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.buildings && props.buildings.length && !state.form.building_id) {

      return {
        form: {
          ...state.form,
          building_id: props.buildings[0].id,
          company_id: props.buildings[0].Companies && props.buildings[0].Companies.length
            ? 0
            : '__none__',
        },
      };
    }

    return null;
  }

  async componentDidMount() {
    const { i18n } = this.props;
    const language = i18n.language.substr(0, 2);
    moment.locale(language);
  }

  render() {
    const { t } = this.props;

    if (this.state.isLoading) {
      return null;
    }

    return (
      <WhoVisitedWho
        buildings={this.props.buildings}
        onSubmit={this.onSubmit}
        form={this.state.form}
        data={this.state.data}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  buildings: state.buildings.records.filter((b) => b.permissions.reports),
});

export default withTranslation()(connect(mapStateToProps)(WhoIsVisitedWhoContainer));
