import request from './index';

export function listSent(params) {
  return request({
    params,
    method: 'GET',
    path: '/api/meetings/sent',
  });
}

export function get(id) {
  return request({
    method: 'GET',
    path: `/api/meetings/${id}`,
  });
}

export function addInvitation(id, body) {
  return request({
    body,
    method: 'PUT',
    path: `/api/meetings/${id}/add-invitation`,
  });
}

export function removeInvitation(id, invitationId) {
  return request({
    method: 'DELETE',
    path: `/api/meetings/${id}/remove-invitation/${invitationId}`,
  });
}

export function create(body) {
  return request({
    body,
    method: 'POST',
    path: '/api/meetings',
  });
}

export function update(id, body) {
  return request({
    body,
    method: 'PUT',
    path: `/api/meetings/${id}`,
  });
}

export function cancel(id) {
  return request({
    method: 'PUT',
    path: `/api/meetings/${id}/cancel`,
  });
}

export function allowAccess(meetingId, invitationId) {
  return request({
    method: 'PUT',
    path: `/api/meetings/${meetingId}/allow-access/${invitationId}`,
  });
}

export function delayAccess(meetingId, invitationId, time) {
  return request({
    body: { delay: time },
    method: 'PUT',
    path: `/api/meetings/${meetingId}/delay-access/${invitationId}`,
  });
}