import * as React from 'react';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import countriesEN from 'i18n-iso-countries/langs/en.json';
import countriesES from 'i18n-iso-countries/langs/es.json';
import countriesPT from 'i18n-iso-countries/langs/pt.json';
import { useTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Input, MaskedInput, Select } from '../../Shared/BsInput';
import { BasicInformationSchema } from '../../../validation-schemas';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
  countries,
  setFieldValue,
  isValid,
}) => {
  let label = 'Passport';

  if (values.id_type === 'rg') {
    label = <span style={{ textTransform: 'uppercase' }}>{t('RG')}</span>;
  } else if (values.id_type === 'rne') {
    label = <span style={{ textTransform: 'uppercase' }}>{t('RNE')}</span>;
  } else if (values.id_type === 'hab') {
    label = 'Habilitaçao';
  }

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={8} lg={6}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Input
            type="text"
            value={values.name}
            onChange={handleChange}
            name="name"
            label={t('Name')}
            errors={errors}
          />
          <Input
            type="text"
            value={values.surname}
            onChange={handleChange}
            name="surname"
            label={t('Surname')}
            errors={errors}
          />
          <Select
            value={values.nationality}
            onChange={handleChange}
            name="nationality"
            label={t('Country of residence')}
            errors={errors}
            options={Object.keys(countries.countries).map((key) => ({
              label: Array.isArray(countries.countries[key]) ? countries.countries[key][0] : countries.countries[key],
              value: key,
            }))}
            margin="dense"
          />
          <DatePicker
            value={values.birthdate}
            disableFuture
            onChange={(value) => {
              setFieldValue('birthdate', value);
            }}
            ampm={false}
            fullWidth
            margin="dense"
            format="DD-MM-YYYY"
            views={["year", "month", "date"]}
            label={t('Birthdate')}
            openTo="year"
          />
          <Select
            value={values.id_type}
            onChange={handleChange}
            name="id_type"
            label={'ID Type'}
            errors={errors}
            options={[
              { value: 'rg', label: (<span style={{ textTransform: 'uppercase' }}>{t('RG')}</span>) },
              { value: 'rne', label: (<span style={{ textTransform: 'uppercase' }}>{t('RNE')}</span>) },
              { value: 'hab', label: t('Habilitaçao') },
              { value: 'passport', label: t('Passport') },
            ]}
            margin="dense"
          />
          <Input
            type="text"
            value={values.id_number}
            onChange={handleChange}
            name="id_number"
            label={label}
            errors={errors}
          />
          {
            ((values.nationality === 'BR' && values.id_type !== 'passport')
            || (values.id_type === 'rne')) && (
              <MaskedInput
                type="text"
                placeholder="xxx.xxx.xxx-xx"
                value={values.cpf}
                onChange={handleChange}
                name="cpf"
                id="cpf-input"
                label={<span style={{ textTransform: 'uppercase' }}>{t('CPF number')}</span>}
                errors={errors}
              />
            )
          }
          <Input
            type="text"
            value={values.company}
            onChange={handleChange}
            name="company"
            label={t('Company')}
            errors={errors}
          />
          <Input
            type="text"
            value={values.company_role}
            onChange={handleChange}
            name="company_role"
            label={t('Company role')}
            errors={errors}
          />
        </form>
      </Grid>
    </Grid>

  );
};

export default ({ onSubmit, user }) => {
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    initialValues: {
      name: user.name || '',
      surname: user.surname || '',
      nationality: user.nationality || 'BR',
      cpf: user.cpf || '',
      id_type: user.id_type || 'rg',
      id_number: user.id_number || '',
      birthdate: user.birthdate || null,
      company: user.company || '',
      company_role: user.company_role || '',
    },
    enableReinitialize: true,
    validationSchema: BasicInformationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const data = _.pick(values, [
          'name', 'surname', 'cpf', 'id_type', 'id_number', 'birthdate',
          'company', 'company_role', 'nationality',
        ]);

        if (data.birthdate) {
          data.birthdate = moment(data.birthdate).format('YYYY-MM-DD');
        }

        await onSubmit(data);
        setSubmitting(false);
      } catch (err) {
        if (err.response && err.response.data.errors) {
          setErrors(err.response.data.errors);
        }

        setSubmitting(false);
      }
    }
  });

  const lang = i18n.language.substr(0, 2);

  let countries = countriesEN;
  if (lang === 'pt') {
    countries = countriesPT;
  } else if (lang === 'es') {
    countries = countriesES;
  }

  return (
    <Paper square style={{ padding: '20px' }}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <RenderForm {...formik} {...{ t, countries }} />
        <hr/>
        <div style={{ textAlign: 'right' }}>
          <Button
            color="primary"
            variant="contained"
            type="button"
            size="small"
            onClick={() => formik.submitForm()}
            disabled={!formik.isValid}
          >
            {t('Continue')}
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    </Paper>
  );
}