import * as React from 'react';
import CompanyView from '../../components/Companies/view';
import * as CompaniesApi from '../../api/companies';

export default class CompanyViewContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companyId: this.props.match.params.companyId,
      company: null,
      isLoading: true,
    };
  }

  render() {
    return (
      <CompanyView
        company={this.state.company}
        isLoading={this.state.isLoading}
      />
    );
  }

  async componentDidMount() {
    const { data } = await CompaniesApi.get(this.state.companyId);

    this.setState({
      company: data,
      isLoading: false,
    });
  }
}
