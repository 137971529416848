import * as React from 'react';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import * as moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import ContainerCard from '../Shared/container-card';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import NoRecords from '../Shared/NoRecords';
import { Input, Select } from '../Shared/BsInput';
import { WhoVisitedWhoSchema } from '../../validation-schemas';
import Tabs from './tabs';
import Loading from '../../components/Shared/Loading';
import BuildingsSelect from './buildings-select';
import WhoVisitedWhoTable from './who-visited-who-table';
import { Link } from 'react-router-dom';
import * as FileSaver from 'file-saver';
import { Workbook } from 'exceljs/dist/exceljs';


const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
  isValid,
  setFieldValue,
  buildings,
  data,
}) => {
    String.prototype.capitalize = function() {
      return this.charAt(0).toUpperCase() + this.substr(1);
    }

    const [loading, setLoading] = React.useState(false);
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const { i18n } = useTranslation();

    const COLUMNS_VISITS_MAPPING = [
      { header: t('Host name').capitalize() , key: 'hostName' , width: 28 },
      { header: t('Host surname').capitalize() , key: 'hostSurname' , width: 28 },
      { header: t('Host company').capitalize() , key: 'hostCompany' , width: 15 },            
      { header: t('Date from').capitalize() , key: 'dateFrom' , width: 18 },
      { header: t('Date to').capitalize() , key: 'dateTo' , width: 18 },
      { header: t('Name').capitalize() , key: 'guestName' , width: 20 },
      { header: t('Surname').capitalize() , key: 'guestSurname' , width: 20 },
      { header: t('Badge number').capitalize() , key: 'guestBadgeNumber' , width: 12 },
      { header: t('Company').capitalize() , key: 'guestCompany' , width: 28 },
      { header: t('Document type').capitalize() , key: 'documentType' , width: 14 },
      { header: t('Document number').capitalize() , key: 'documentNumber' , width: 16 },
      { header: t('CPF number').capitalize() , key: 'cpfNumber' , width: 17 },
      { header: t('Cellphone').capitalize() , key: 'cellphone' , width: 18 },
      { header: t('Email').capitalize() , key: 'guestEmail' , width: 22 },
    ];

    const exportToXlsx = async (dataSheet) => {
      setLoading(true);

      const getFullTime = (timestamp) => {
        const date = new Date(timestamp);
        if (i18n.language === 'en') {
          const ampm = date.getUTCHours() > 12 ? 'PM' : 'AM';
          const hour = date.getUTCHours() > 12 ? date.getUTCHours() - 12 : date.getUTCHours();
          return `${hour}:${date.getUTCMinutes()}${ampm}`;
        }
        return `${date.getUTCHours()}:${date.getUTCMinutes()}`;
      }

      const getFullDate = (timestamp) => {
        const date = new Date(timestamp);
        if (i18n.language === 'en') {
          return `${date.getUTCMonth()}-${date.getDate()}-${date.getUTCFullYear()}`;
        }
        return `${date.getDate()}-${date.getUTCMonth()}-${date.getUTCFullYear()}`;
      }

      const newData = dataSheet.map(field => {
        return {
          hostName: field.Host_name,
          hostSurname: field.Host_surname,
          hostEmail: field.Host_email,
          hostCompany: field.Company_host,          
          guestName: field.Guest_name,
          guestSurname: field.Guest_surname,          
          guestBadgeNumber: field.Guest_access_code,
          guestCompany: field.Company_guest,
          dateFrom: moment(field.Date_from).format(t('Date and time format')),
          dateTo: moment(field.Date_to).format(t('Date and time format')),
          documentType: field.Guest_document_type,
          documentNumber: field.Guest_document,
          cpfNumber: field.Guest_cpf,
          cellphone: field.Guest_phone,
          guestEmail: field.Guest_email,
        };
      })

      const headerNames = [{
        hostName: `${t('Host name').capitalize()} ${t('Order:')}`,
        hostSurname: `${t('Host surname').capitalize()} ${t('Order:')}`,
        hostEmail: `${t('Host email').capitalize()}`,
        hostCompany: t('Host company').capitalize(), 
        dateFrom: t('Date from').capitalize(),
        dateTo: t('Date to').capitalize(),      
        guestName: t('Name').capitalize(),
        guestSurname: t('Surname').capitalize(),
        guestBadgeNumber: t('Badge number').capitalize(),
        guestCompany: t('Company').capitalize(),        
        documentType: t('Document type').capitalize(),
        documentNumber: t('Document number').capitalize(),        
        guestEmail: t('Email').capitalize(),
        cpfNumber: t('CPF number').capitalize(),
        cellphone: t('Cellphone').capitalize(),        
      }];

      const wb = new Workbook();
      const ws = wb.addWorksheet(t('Visit report'));
      ws.columns = COLUMNS_VISITS_MAPPING;

      const title = ws.getRow(1).getCell(1);
      const inviteHeader = ws.getRow(2).getCell(1);
      const guestHeader = ws.getRow(2).getCell(6);

      const blackColor = { argb: 'FF000000'};
      const bgHeaderColor = { argb: 'FF' + '73BC73'};
      const defaultBorderStyle = { color: blackColor, style: 'thin' };

      const defaultHeaderFill = { 
        type: 'pattern', 
        pattern: 'solid', 
        fgColor: bgHeaderColor, 
        bgColor: bgHeaderColor, 
      };
      const defaultBorder = { 
        top: defaultBorderStyle, 
        left: defaultBorderStyle, 
        right: defaultBorderStyle, 
      };
      const defaultFont = { name: 'Calibri', bold: true, size: 11 };
      const defaultAlignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

      title.value = t('Visit report');
      title.alignment = defaultAlignment;
      title.font = { ...defaultFont, size: 14 };
      title.border = defaultBorder;
      title.style.fill = defaultHeaderFill;

      inviteHeader.value = t('Invitation data');
      inviteHeader.alignment = defaultAlignment;
      inviteHeader.font = { ...defaultFont, size: 13, italic: true };
      inviteHeader.style.fill = defaultHeaderFill;
      inviteHeader.border = defaultBorder;

      guestHeader.value = t('Visitor data');
      guestHeader.alignment = defaultAlignment;
      guestHeader.font = { ...defaultFont, size: 13, italic: true };
      guestHeader.style.fill = defaultHeaderFill;
      guestHeader.border = defaultBorder;

      ws.mergeCells('A1', 'N1');
      ws.mergeCells('A2', 'E2');
      ws.mergeCells('F2', 'N2');

      ws.addRows(headerNames);
      const headerName = ws.getRow(3);
      headerName.height = 40;
      COLUMNS_VISITS_MAPPING.forEach((item, index) => {
        headerName.getCell(index + 1).style = {
          alignment: defaultAlignment,
          font: defaultFont,
          border: {
            bottom: defaultBorderStyle,
          },
          fill: defaultHeaderFill,
        };
        if (COLUMNS_VISITS_MAPPING.length === index + 1) {
          headerName.getCell(index + 1).border = {
            right: defaultBorderStyle,
            bottom: defaultBorderStyle,
          }
        } 
      });
      headerName.getCell(guestHeader.col).border = {
        left: defaultBorderStyle,
        bottom: defaultBorderStyle,
      };

      ws.addRows(newData);

      const buffer = await wb.xlsx.writeBuffer().finally(() => setLoading(false));
      const file = new Blob([buffer], {type: fileType});
      FileSaver.saveAs(file, String(t('Visit report')).toLowerCase().replace(/ /g, '-') + fileExtension);
    }

    const {  } = new Date('2021-11-08T16:10:00.000Z')

  return (
    <>
    <Loading visible={loading} />
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid spacing={3} container>
        <BuildingsSelect label={t('Local')} enableAll
        {...{
            errors,
            values,
            handleChange,
            setFieldValue,
            t,
            buildings,
        }}
        />
        <Grid item xs={12} sm={6} md={4} xl={3}>
            <DateTimePicker
                format={t('Date and time format')}
                value={values.from}
                style={{marginTop: "5px"}}
                disableFuture
                views={["year", "month", "date"]}
                onChange={(value) => {
                setFieldValue('from', value);
                }}
                fullWidth
                autoOk
                margin="normal"
                label={t('Date from')}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
            <DateTimePicker
                format={t('Date and time format')}
                style={{marginTop: "5px"}}
                value={values.to}
                minDate={moment(values.from)}
                maxDate={moment(values.from).add('month', 3)}
                maxDateMessage={t('Max date Error')}
                minDateMessage={t('Min date Error')}
                // disableFuture
                views={["year", "month", "date"]}
                onChange={(value) => {
                setFieldValue('to', value);
                }}
                fullWidth
                autoOk
                margin="normal"
                label={t('Date to')}
                disabled={!values.from}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
            <Input
                type="text"
                value={values.host_name}
                onChange={handleChange}
                name="host_name"
                label={t('Host name')}
                errors={errors}
                id="host-name-input"
            />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
            <Input
                type="text"
                value={values.host_surname}
                onChange={handleChange}
                name="host_surname"
                label={t('Host surname')}
                errors={errors}
                id="host-surname-input"
            />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
            <Input
                type="text"
                value={values.host_email}
                onChange={handleChange}
                name="host_email"
                label={t('Host email')}
                errors={errors}
                id="host-email-input"
            />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
            <Input
                type="text"
                value={values.guest_name}
                onChange={handleChange}
                name="guest_name"
                label={t('Guest name')}
                errors={errors}
                id="guest-name-input"
            />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
            <Input
                type="text"
                value={values.guest_surname}
                onChange={handleChange}
                name="guest_surname"
                label={t('Guest surname')}
                errors={errors}
                id="guest-surname-input"
            />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
            <Input
                type="text"
                value={values.guest_email}
                onChange={handleChange}
                name="guest_email"
                label={t('Guest email')}
                errors={errors}
                id="guest-email-input"
            />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            className="mt-4"
            variant="contained"
            color="default"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            {t('Show')}
          </Button>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            className="mt-4"
            variant="contained"
            color="default"
            disabled={!data}
            fullWidth
            onClick={() => exportToXlsx(data)}
          >
            {t('Exportar')}
          </Button>
        </Grid>
      </Grid>
    </form>
    </>
  );
};

export default ({ onSubmit, buildings, form, data }) => {
  const { t } = useTranslation();

  if (!buildings.length) {
    return (
      <div className="alert alert-info text-center">
        {t('You need to be building manager to see reports')}
      </div>
    );
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ContainerCard
        xs={12}
        md={12}
        lg={12}
        header={
          <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between', alignItems: 'center'}}>
            <div />
            <Typography variant="h6" align="center">
              {t('who.visited.who')}
            </Typography>
            <div className="pull-right">
              <Link to="/app/stats">
                <i className="fa fa-share fa-flip-horizontal"></i>
              </Link>
            </div>
          </div>
        }
        content={
          <>
            <Formik
              initialValues={form}
              validationSchema={WhoVisitedWhoSchema}
              onSubmit={
                async (values, { setSubmitting, setErrors }) => {
                  try {
                    await onSubmit(values);
                    setSubmitting(false);
                  } catch (err) {
                    if (err.response && err.response.data.errors) {
                      setErrors(err.response.data.errors);
                    }

                    setSubmitting(false);
                  }
                }
              }
              render={p => <RenderForm data={data} {...p} {...{ t, buildings }}/>}
            />
            <hr />
            {
              data && !data.length && <NoRecords />
            }
            {
              data && data.length && (
                <WhoVisitedWhoTable data={data} />
              )
            }
          </>
        }
        style={{ marginBottom: '15px' }}
      />
    </MuiPickersUtilsProvider>
  );
}
