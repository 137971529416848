import * as React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Analytics from 'react-router-ga';
import { loggedIn, logout } from '../../actions/auth';
import { fetchBuildings } from '../../actions/buildings';
import * as AuthApi from '../../api/auth';
import * as UsersApi from '../../api/users';

import 'font-awesome/css/font-awesome.css';
import '../../assets/css/bs.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../assets/css/styles.css';

import * as Firebase from  '../../firebase';

import Navbar from './navbar';
import SimpleNavbar from './simple-navbar';
import Routes from './routes';
import RegistrationRoutes from './registration-routes';
import UnderReviewRoutes from './under-review-routes';
import Loading from '../../components/Shared/Loading';
import SnackbarNotifications from '../../components/Shared/SnackbarNotifications';
import TosModal from '../Profile/tos';
import theme from '../../theme';
import { updateUserLanguage } from '../../actions/users';
import packageJson from '../../../package.json';

const getParameterByName = (name) => {
  name = name.replace(/[\[\]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(window.location.href);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const styles = createStyles(theme => ({
  container: {
    marginTop: '70px',
    marginBottom: '4%',

    [theme.breakpoints.down('xs')]: {
      marginTop: '56px',
    },
  }
}));

const Wrapper = ({loggedIn, logout, user, updateLanguage, children}) => {
  if (loggedIn) {
    return (
      <Navbar
        logout={logout}
        user={user}
        updateLanguage={updateLanguage}
      >
        {children}
      </Navbar>
    );
  }

  return (
    <SimpleNavbar updateLanguage={updateLanguage}>{children}</SimpleNavbar>
  );
};

class AppContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: false,
      loading: true,
    };
    
    if (localStorage.getItem('localVersion') !== packageJson.version) {
      localStorage.setItem('localVersion', packageJson.version);
      window.location.reload(true);
    }
  }

  fcm() {
    UsersApi.testFcm();
  }

  render() {
    const {isLoggedIn, user, classes} = this.props;
    const loggedIn = isLoggedIn && !!user;
    const {loading} = this.state;
    const shouldShowTosModal = user && (!user.tos || !user.privacy);

    const isRegistrationIncomplete = user && user.registration_step;
    const isUserUnderReview = user && !user.registration_step &&
      !user.profile_approved_at && !!user.profile_completed_at;

    if (loading) {
      return null;
    }

    if (isRegistrationIncomplete) {
      return (
        <ThemeProvider theme={theme}>
          <SimpleNavbar loggedIn={loggedIn} logout={this.logout}
                        updateLanguage={this.props.updateLanguage}>
            <div className={classes.container}>
              <RegistrationRoutes
                loggedIn={loggedIn}
              />
            </div>
          </SimpleNavbar>

          <Loading visible={this.props.loading} />
          <ToastContainer position="bottom-left"/>
        </ThemeProvider>
      );
    }

    if (isUserUnderReview) {
      return (
        <ThemeProvider theme={theme}>
          <SimpleNavbar loggedIn={loggedIn} logout={this.logout}
                        updateLanguage={this.props.updateLanguage}>
            <div className={classes.container}>
              <UnderReviewRoutes
                loggedIn={loggedIn}
              />
            </div>
          </SimpleNavbar>

          <Loading visible={this.props.loading} />
          <ToastContainer position="bottom-left"/>
        </ThemeProvider>
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <Analytics id="UA-179334999-1" debug>
          <Wrapper loggedIn={loggedIn} logout={this.logout} updateLanguage={this.props.updateLanguage} user={user}>
            <div className={classes.container}>
              {
                shouldShowTosModal &&
                <TosModal/>
              }
              <Routes
                loggedIn={loggedIn}
              />
            </div>
          </Wrapper>
        </Analytics>
        <Loading visible={this.props.loading} />
        <ToastContainer position="bottom-left"/>
        <SnackbarNotifications />
      </ThemeProvider>
    );
  }

  async componentDidMount() {
    const token = getParameterByName('token');
    if (token) {
      localStorage.setItem('token', token);

      this.props.history.push(this.props.history.location.pathname);
    }

    if (localStorage.getItem('token')) {
      const { data } = await AuthApi.me();

      this.props.loggedIn({
        token: localStorage.getItem('token'),
        user: data,
      });

      if (data.profile_approved_at) {
        this.props.fetchBuildings();
      }

      this.setState({loading: false});

      if (data.should_change_password) {
        this.props.history.push('/app/change-password?redirected=1');
      }

      if (data.registration_step && !this.props.history.location.pathname.includes('register/verify-email')) {
        this.props.history.push('/app/complete-registration');
      }

      try {
        const fcmToken = await Firebase.getToken();
        const device_id = await AuthApi.getDeviceId();

        if (fcmToken) {
          UsersApi.fcm({ token: fcmToken, device_id });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      this.setState({ loading: false });
    }
  }

  logout = async (force = false) => {
    try {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister();
        });
      }

      await AuthApi.logout();
    } catch (err) {

    }

    this.props.logout(force);
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.auth.loggedIn,
  user: state.auth.user,
  loading: state.ui.loading > 0,
});

const mapDispatchToProps = dispatch => ({
  loggedIn: (data) => dispatch(loggedIn(data)),
  logout: (f) => dispatch(logout(f)),
  fetchBuildings: () => dispatch(fetchBuildings()),
  updateLanguage: (language) => dispatch(updateUserLanguage(language))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(AppContainer))));
