import * as React from 'react';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as moment from 'moment';
import MeetingsCreate from '../../components/Meetings/create';
import * as MeetingsApi from '../../api/meetings';

class MeetingsCreateContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      meetingId: this.props.match?.params?.meetingId || '',
      meeting: {
        guest_contact: [],
        subject: '',
        notes: '',
        date_from: {
          date: moment().add(1, 'hour'),
          time: moment().add(1, 'hour'),
        },
        date_to: moment().add(2, 'hour'),
        building_id: '',
        duration: 1,
        announce_arrival: false,
      },
      isLoading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async fetchMeeting() {
    if(!!this.state.meetingId){
      const { data } = await MeetingsApi.get(this.state.meetingId);

      let dateFrom = moment(data.date_from);
      let dateTo = moment(data.date_to);
      if(moment().isAfter(moment(data.date_from), 'minutes')){
        dateFrom.add(1, 'day');
        dateTo.add(1, 'day');
      }


      this.setState({
        meeting: {
          guest_contact: data.guest_contact,
          notes: data.notes,
          subject: data.subject,
          announce_arrival: data.announce_arrival,
          building_id: data.building_id,
          company_id: data.company_id,
          duration: dateTo.diff(moment(dateFrom), 'hours'),
          date_to: dateTo.toDate(),
          date_from: {
            date: dateFrom.toDate(),
            time: dateFrom.toDate(),
          },
        },
        isLoading: false,
      });
    }
  }

  async onSubmit(body) {
    body.represented_user_id = body.represented_user_id === '__none__'
      ? null
      : body.represented_user_id;

    await MeetingsApi.create(_.omit(body, ['duration']));

    toast.success(this.props.t('The meeting has been sent'));

    this.props.history.push('/app/meetings');
  }

  static getDerivedStateFromProps(props, state) {
    if (props.buildings && props.buildings.length && !state.meeting.building_id) {
      return {
        meeting: {
          ...state.meeting,
          building_id: props.buildings[0].id,
          company_id: props.buildings[0].Companies.length ? props.buildings[0].Companies[0].id : null,
        },
      };
    }

    return null;
  }

  render() {
    return (
      <MeetingsCreate
        buildings={this.props.buildings}
        meeting={this.state.meeting}
        isLoading={this.state.isLoading}
        onSubmit={this.onSubmit}
      />
    );
  }

  async componentDidMount() {
    this.fetchMeeting();
  }
}

const mapStateToProps = (state) => ({
  buildings: state.buildings.records.filter((b) => b.permissions.invite),
});

export default withTranslation()(connect(mapStateToProps)(MeetingsCreateContainer));
