import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ChangeCellphoneDialog from './change-cellphone-dialog';
import { Input } from '../../Shared/BsInput';
import { VerifyCellphoneSchema } from '../../../validation-schemas';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    boxShadow: 'none',
    textAlign: 'center',
  },
  btnContainer: {
    textAlign: 'right',
  },
  actions: {
    textAlign: 'center',
  },
  margin: {
    marginTop: theme.spacing(2),
  },
}));

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  isValid,
  t,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        type="code"
        value={values.code}
        onChange={handleChange}
        name="code"
        label={t('Verification code')}
        errors={errors}
      />
      <Button
        className="mt-2"
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting || !isValid}
        fullWidth
      >
        {t('Verify cellphone')}
      </Button>
    </form>
  );
};

export default (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [canRequestCode, setCanRequestCode] = React.useState(true);
  const { user, resend, verify, change } = props;

  const handleClose = () => setDialogOpen(false);
  const handleOpen = () => setDialogOpen(true);

  const requestCode = () => {
    setCanRequestCode(false);
    setTimeout(() => {
      setCanRequestCode(true);
    }, 10000);

    resend();
  };

  return (
    <>
      <ChangeCellphoneDialog
        opened={dialogOpen}
        handleClose={handleClose}
        user={user}
        onSubmit={(values) => {
          handleClose();
          change(values);
        }}
      />
      <Paper className={classes.paper} square>
        <Typography align="center">
          {t('We will now verify your cellphone number')}
        </Typography>
        <Typography align="center" variant="h6">
          {user.cellphone}
        </Typography>
        <Typography align="center">
          {t('We will send you a 4 digit verification code via SMS')}
        </Typography>

        <Button
          variant="outlined"
          color="primary"
          onClick={requestCode}
          className={classes.margin}
          disabled={!canRequestCode}
        >
          {t('I am ready, send me the verification code')}
        </Button>
        <hr />
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={8} md={6}>
            <Formik
              initialValues={{
                code: '',
              }}
              validationSchema={VerifyCellphoneSchema}
              isInitialValid={false}
              enableReinitialize={true}
              onSubmit={
                async (values, { setSubmitting, setErrors }) => {
                  try {
                    await verify(values);
                  } catch (err) {
                    if (err.response && err.response.data.errors) {
                      setErrors(err.response.data.errors);
                    }

                    setSubmitting(false);
                  }
                }
              }
              render={(p) => <RenderForm {...p} {...{ t }} />}
            />
          </Grid>
        </Grid>

        <Typography align="center" className={classes.margin}>
          {t('If you made a mistake or want to change your phone number')},&nbsp;
          <a
            href="javascript:void(0)"
            onClick={handleOpen}
          >
            {t('click here')}
          </a>
        </Typography>
      </Paper>
    </>
  );
}
