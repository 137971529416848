import * as React from 'react';
import { connect } from 'react-redux';
import { loggedIn } from '../../actions/auth';
import { fetchBuildings } from '../../actions/buildings';
import LoginForm from '../../components/Login';
import * as AuthApi from '../../api/auth';
import * as UsersApi from '../../api/users';
import * as Firebase from  '../../firebase';

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      step: 1,
      form: {
        device_id: null,
        username: '',
        password: '',
      }
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.getDeviceId = this.getDeviceId.bind(this);
  }

  async onSubmit(body) {
    const { data } = await AuthApi.login(body);

    if (!data.token && !data.user && data.two_step) {
      this.setState({
        step: 2,
        form: body,
      });

      return;
    }

    this.props.loggedIn(data);
    this.props.fetchBuildings();

    try {
      const fcmToken = await Firebase.getToken();
      const device_id = await AuthApi.getDeviceId();

      if (fcmToken) {
        UsersApi.fcm({ token: fcmToken, device_id });
      }
    } catch (err) {
      console.log(err);
    }

    if (data.user.should_change_password) {
      this.props.history.push('/app/change-password?redirected=1');
    }

    if (data.user.profile_rejected_at) {
      this.props.history.push('/app/profile-configuration');
    }
  }

  async getDeviceId() {
    const id = await AuthApi.getDeviceId();

    this.setState({
      form: {
        ...this.state.form,
        device_id: id,
      },
      loading: false,
    });
  }

  restart = () => {
    this.setState({
      form: {
        ...this.state.form,
        username: '',
        password: '',
      },
      step: 1,
    });
  }

  componentDidMount() {
    this.getDeviceId();
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    return (
      <LoginForm
        onSubmit={this.onSubmit}
        forcedLogout={this.props.forcedLogout}
        step={this.state.step}
        restart={this.restart}
        form={this.state.form}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  forcedLogout: state.auth.forcedLogout,
});

const mapDispatchToProps = dispatch => ({
  loggedIn: (data) => dispatch(loggedIn(data)),
  fetchBuildings: () => dispatch(fetchBuildings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
