import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from 'react-i18next';
import CompanyUsers from '../../components/Companies/users';
import * as CompaniesApi from '../../api/companies';
import * as BuildingsApi from '../../api/buildings';

class CompanyUsersContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      buildingId: this.props.match.params.buildingId,
      companyId: this.props.match.params.companyId,
      company: null,
      users: [],
      pages: 0,
      page: 1,
      isLoading: true,
      where: {
        email: '',
        name: '',
        surname: '',
        role: '',
      },
    };

    this.onPageChange = this.onPageChange.bind(this);
  }

  async onPageChange({ page, ...rest }) {
    const { data, headers } = await CompaniesApi
      .listUsers(this.state.companyId, {
        limit: 10,
        offset: (page - 1) * 10,
        ...(!isEmpty(rest) ? rest : this.state.where),
      });

    const pages = parseInt(headers['x-total-count'], 10);

    this.setState({
      users: data,
      pages,
      page,
      where: (!isEmpty(rest) ? rest : this.state.where),
    });
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }

    return (
      <CompanyUsers
        onPageChange={this.onPageChange}
        users={this.state.users}
        pages={this.state.pages}
        page={this.state.page}
        isLoading={this.state.isLoading}
        buildingId={this.state.buildingId}
        company={this.state.company}
      />
    );
  }

  async componentDidMount() {
    const { data } = await BuildingsApi.getCompany(this.state.buildingId, this.state.companyId);
    this.setState({
      company: data,
      isLoading: false,
    });

    this.onPageChange({ page: 1 });
  }
}

export default withTranslation()(CompanyUsersContainer);
