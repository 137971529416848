import * as React from 'react';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import ContainerCard from '../Shared/container-card';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import NoRecords from '../Shared/NoRecords';
import { Input, Select } from '../Shared/BsInput';
import { EmployessPerCompany } from '../../validation-schemas';
import Loading from '../Shared/Loading';
import BuildingsSelect from './buildings-select';
import EmployeesPerCompanyTable from './employees-per-company-table';
import { Link } from 'react-router-dom';
import * as FileSaver from 'file-saver';
import { Workbook } from 'exceljs/dist/exceljs';
import {
  BUILDING_MANAGER,
  EMPLOYEE,
  HOST_EMPLOYEE,
  SECURITY,
  COMPANY_MANAGER,
  COMPANY_SECRETARY,
} from '../../constants';


const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
  isValid,
  setFieldValue,
  buildings,
  data,
}) => {
    String.prototype.capitalize = function() {
      return this.charAt(0).toUpperCase() + this.substr(1);
    }

    const [loading, setLoading] = React.useState(false);
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const COLUMNS_VISITS_MAPPING = [
      { header: t('name').capitalize() , key: 'name' , width: 28 },
      { header: t('surname').capitalize() , key: 'surname' , width: 28 },
      { header: t('company').capitalize() , key: 'company_name' , width: 28 },            
      { header: t('email').capitalize() , key: 'email' , width: 22 },
      { header: t('Access code').capitalize() , key: 'key' , width: 22 },
      { header: t('Role').capitalize() , key: 'role' , width: 28 },
    ];

    const exportToXlsx = async (dataSheet) => {
      setLoading(true);
      const wb = new Workbook();
      const ws = wb.addWorksheet(t('Visit report'));
      ws.columns = COLUMNS_VISITS_MAPPING;

      const blackColor = { argb: 'FF000000'};
      const bgHeaderColor = { argb: 'FF' + '73BC73'};
      const defaultBorderStyle = { color: blackColor, style: 'thin' };

      const defaultHeaderFill = { 
        type: 'pattern', 
        pattern: 'solid', 
        fgColor: bgHeaderColor, 
        bgColor: bgHeaderColor, 
      };

      const defaultFont = { name: 'Calibri', bold: true, size: 11 };
      const defaultAlignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

      const headerName = ws.getRow(1);
      headerName.height = 40;
      COLUMNS_VISITS_MAPPING.forEach((item, index) => {
        headerName.getCell(index + 1).style = {
          alignment: defaultAlignment,
          font: defaultFont,
          border: {
            bottom: defaultBorderStyle,
          },
          fill: defaultHeaderFill,
        };
        if (COLUMNS_VISITS_MAPPING.length === index + 1) {
          headerName.getCell(index + 1).border = {
            right: defaultBorderStyle,
            bottom: defaultBorderStyle,
          }
        } 
      });

      ws.addRows(dataSheet);

      const buffer = await wb.xlsx.writeBuffer().finally(() => setLoading(false));
      const file = new Blob([buffer], {type: fileType});
      FileSaver.saveAs(file, String(t('Visit report')).toLowerCase().replace(/ /g, '-') + fileExtension);
    }

  return (
    <>
    <Loading visible={loading} />
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid spacing={3} container>
        <BuildingsSelect label={t('Local')} enableAll
        {...{
            errors,
            values,
            handleChange,
            setFieldValue,
            t,
            buildings,
        }}
        />
        <Grid item xs={12} sm={6} md={4} xl={3}>
        <Select
            style={{ paddingTop: '8px' }}
            value={values.role}
            onChange={handleChange}
            name="role"
            label={t('Role')}
            errors={errors}
            options={[
              { value: 'all', label: t('All') },
              { value: BUILDING_MANAGER, label: t(BUILDING_MANAGER) },
              { value: COMPANY_MANAGER, label: t(COMPANY_MANAGER) },
              { value: COMPANY_SECRETARY, label: t(COMPANY_SECRETARY) },
              { value: HOST_EMPLOYEE, label: t(HOST_EMPLOYEE) },
              { value: EMPLOYEE, label: t(EMPLOYEE) },
              { value: SECURITY, label: t(SECURITY) }
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
            <Input
                type="text"
                value={values.name}
                onChange={handleChange}
                name="name"
                label={t('name')}
                errors={errors}
                id="name-input"
            />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
            <Input
                type="text"
                value={values.surname}
                onChange={handleChange}
                name="surname"
                label={t('surname')}
                errors={errors}
                id="surname-input"
            />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
            <Input
                type="text"
                value={values.email}
                onChange={handleChange}
                name="email"
                label={t('email')}
                errors={errors}
                id="email-input"
            />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
            <Input
                type="text"
                value={values.key}
                onChange={handleChange}
                name="key"
                label={t('Access code')}
                errors={errors}
                id="access-code-input"
            />
        </Grid>
      </Grid>
      <Grid spacing={3} container justify='flex-end'>
        <Grid item xs={12} sm={6} md={2} xl={2}>
          <Button
            className="mt-4"
            variant="contained"
            color="default"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            {t('Show')}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2} xl={2}>
          <Button
            className="mt-4"
            variant="contained"
            color="default"
            disabled={!data}
            fullWidth
            onClick={() => exportToXlsx(data)}
          >
            {t('Export')}
          </Button>
        </Grid>
      </Grid>
    </form>
    </>
  );
};

export default ({ onSubmit, buildings, form, data }) => {
  const { t } = useTranslation();

  if (!buildings.length) {
    return (
      <div className="alert alert-info text-center">
        {t('You need to be building manager to see reports')}
      </div>
    );
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ContainerCard
        xs={12}
        md={12}
        lg={12}
        header={
          <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between', alignItems: 'center'}}>
            <div />
            <Typography variant="h6" align="center">
              {t('Employees per company')}
            </Typography>
            <div className="pull-right">
              <Link to="/app/stats">
                <i className="fa fa-share fa-flip-horizontal"></i>
              </Link>
            </div>
          </div>
        }
        content={
          <>
            <Formik
              initialValues={form}
              validationSchema={EmployessPerCompany}
              onSubmit={
                async (values, { setSubmitting, setErrors }) => {
                  try {
                    await onSubmit(values);
                    setSubmitting(false);
                  } catch (err) {
                    if (err.response && err.response.data.errors) {
                      setErrors(err.response.data.errors);
                    }

                    setSubmitting(false);
                  }
                }
              }
              render={p => <RenderForm data={data} {...p} {...{ t, buildings }}/>}
            />
            <hr />
            {
              data?.length ? (
                <EmployeesPerCompanyTable data={data} />
              ) : (<NoRecords />)
            }
          </>
        }
        style={{ marginBottom: '15px' }}
      />
    </MuiPickersUtilsProvider>
  );
}
