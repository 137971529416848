import * as React from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
 Button,
 Typography,
} from '@material-ui/core';
import { Select, Input } from '../Shared/BsInput';
import { AddSecretarySchema } from '../../validation-schemas';
import ContainerCard from '../Shared/container-card';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  t,
  buildings,
  isValid,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Select
        value={values.building_id}
        onChange={handleChange}
        name="building_id"
        label={t('Building')}
        errors={errors}
        options={[
          { value: '', label: 'Select building' },
        ].concat(buildings.map(b => ({ value: b.id, label: b.name })))}
      />
      <Input
        type="text"
        value={values.username}
        onChange={handleChange}
        name="username"
        label={t('Email or cellphone number')}
        errors={errors}
      />
      <hr />
      <Button
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting || !isValid}
        fullWidth
      >
        {t('Save')}
      </Button>
    </form>
  );
};

export default ({ onSubmit, secretary, isLoading, buildings }) => {
  const { t } = useTranslation();

  return (
    <ContainerCard
      xs={12}
      md={4}
      lg={4}
      header={
        <Typography variant="h6" align="center">
          {t('New secretary')}
          <div className="pull-right">
            <Link to="/app/secretaries">
              <i className="fa fa-share fa-flip-horizontal"></i>
            </Link>
          </div>
        </Typography>
      }
      content={
        !isLoading && <Formik
          initialValues={secretary}
          enableReinitialize={true}
          validationSchema={AddSecretarySchema}
          onSubmit={
            async (values, { setSubmitting, setErrors }) => {
              try {
                await onSubmit(values);
              } catch (err) {
                if (err.response && err.response.data.errors) {
                  setErrors(err.response.data.errors);
                }

                setSubmitting(false);
              }
            }
          }
          render={p => <RenderForm {...p} {...{ t, buildings }}/>}
        />
      }
    />
  );
}