import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Select } from '../Shared/BsInput';
import Badge from './badge';
import CompanyLogo from './company-logo';

export default ({
  buildings,
  user,
  building,
  company,
  onBuildingChange,
  onCompanyChange,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} sm={9} md={6} lg={4}>
          {
            buildings && buildings.length > 1 && (
              <Select
                name="building"
                value={building ? building.id : null}
                errors={{}}
                options={
                  buildings.map((b) => ({
                    value: b.id,
                    label: b.name,
                  }))
                }
                onChange={(e) => {
                  const { value } = e.target;

                  return onBuildingChange(value);
                }}
              />
            )
          }
          {
            building && !!building.Companies.length && (
              <Select
                name="company"
                value={company ? company.id : null}
                errors={{}}
                options={
                  building.Companies.map((b) => ({
                    value: b.id,
                    label: b.name,
                  }))
                }
                onChange={(e) => {
                  const { value } = e.target;

                  return onCompanyChange(value);
                }}
              />
            )
          }
          <Badge
            user={user}
            accessCode={building ? building.AccessCode : null}
            header={<CompanyLogo company={company} />}
            backgroundImage={company && company.background ? company.background : null}
            params={{ building }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
