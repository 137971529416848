import * as React from 'react';
import { connect } from 'react-redux';
import BuildingIndex from '../../components/Buildings/index';

class BuildingIndexContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pages: 0,
      page: 1,
      isLoading: false,
    };

    this.onPageChange = this.onPageChange.bind(this);
  }

  async onPageChange({ page }) {
    this.setState({
      page,
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.buildings && props.buildings.length && !state.pages) {
      return {
        pages: props.buildings.length,
      };
    }

    return null;
  }

  render() {
    const { page } = this.state;
    const start = (page - 1) * 10;
    const buildings = this.props.buildings.slice(start, start + 10);

    return (
      <BuildingIndex
        buildings={buildings}
        isLoading={this.state.isLoading}
        pages={this.state.pages}
        page={this.state.page}
        onPageChange={this.onPageChange}
      />
    );
  }
}

const mapStateToprops = (state) => ({
  buildings: state.buildings.records,
});

export default connect(mapStateToprops)(BuildingIndexContainer);
