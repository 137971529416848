import * as React from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Button, Typography, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Phone, Input } from '../Shared/BsInput';
import { ChangePhoneSchema, VerifyCellphoneSchema } from '../../validation-schemas';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  t,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Phone
        onChange={(v) => {
          setFieldValue('cellphone', v);
        }}
        label={t('New cellphone number')}
        errors={errors}
        name="cellphone"
      />
      <hr />
      <Button
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting}
        fullWidth
      >
        {t('Change phone')}
      </Button>
    </form>
  );
};

const RenderVerificationForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
  isValid,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        type="text"
        value={values.code}
        onChange={handleChange}
        name="code"
        label={t('Verification code')}
        errors={errors}
      />
      <Button
        className="mt-2"
        variant="contained"
        color="secondary"
        type="submit"
        disabled={isSubmitting || !isValid}
        fullWidth
      >
        {t('Verify')}
      </Button>
    </form>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '10%',

    [theme.breakpoints.up('sm')]: {
      marginTop: '2%',
    },
  },
  paper: {
    padding: '10px',
    paddingBottom: '20px',
    marginBottom: '10px',
  },
  title: {
    marginBottom: '20px',
  },
}));

export default ({ onSubmit, user, requestCode, verifyCellphone }) => {
  const [verify, setVerify] = React.useState(!!user.change_cellphone);
  const { t } = useTranslation();
  const classes = useStyles();

  React.useEffect(() => {
    setVerify(!!user.change_cellphone);
  }, [user]);

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={10} lg={6} className={classes.root}>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" align="center" className={classes.title}>
            {t('Change phone')}
            <div className="pull-right">
              <Link to="/app/account-configuration">
                <i className="fa fa-share fa-flip-horizontal"></i>
              </Link>
            </div>
          </Typography>
          {
            verify && (
              <>
                <Typography align="center">
                  {t('We will now verify your cellphone number')}
                </Typography>
                <Typography align="center" variant="h6">
                  {user.change_cellphone}
                </Typography>
                <Formik
                  initialValues={{
                    code: '',
                  }}
                  enableReinitialize={true}
                  validationSchema={VerifyCellphoneSchema}
                  onSubmit={
                    async (values, { setSubmitting, setErrors }) => {
                      try {
                        await verifyCellphone(values);
                      } catch (err) {
                        if (err.response && err.response.data.errors) {
                          setErrors(err.response.data.errors);
                        }

                        setSubmitting(false);
                      }
                    }
                  }
                  render={p => <RenderVerificationForm {...p} {...{ t }}/>}
                />

                <hr />
                <div className="text-center">
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      setVerify(false);
                    }}
                  >
                    {t('Change phone')}
                  </a>
                </div>
              </>
            )
          }
          {
            !verify && (
              <Formik
                initialValues={{
                  cellphone: '',
                }}
                validationSchema={ChangePhoneSchema}
                enableReinitialize={true}
                onSubmit={
                  async (values, { setSubmitting, setErrors }) => {
                    try {
                      const clean = {
                        cellphone: values.cellphone.replace('+', ''),
                      };

                      await onSubmit(clean);
                    } catch (err) {
                      if (err.response && err.response.data.errors) {
                        setErrors(err.response.data.errors);
                      }

                      setSubmitting(false);
                    }
                  }
                }
                render={p => <RenderForm {...p} {...{ t }}/>}
              />
            )
          }
        </Paper>
      </Grid>
    </Grid>
  );
}