export default {
  'Email': 'email',
  'Password': 'password',
  'Login': 'enter',
  'You don\'t have an account?': 'you don\'t have an account?',
  'Register here': 'sign up',
  'Role': 'role',
  'Name': 'name',
  'Save': 'save',
  'Surname': 'last name',
  'Confirm password': 'confirm password',
  'Register': 'subscribe',
  'Already have an account?': 'I already have an account',
  'sign in': 'sign in',
  'My profile': 'My profile',
  'Logout': 'log out',
  'Change password': 'Change password',
  'Your profile picture was updated': 'Your profile picture was updated',
  'Your profile information was updated': 'Your profile information was updated',
  'New password': 'New password',
  'Confirm new password': 'Confirm new password',
  'Your password has been changed': 'Your password has been changed',
  'Cancel': 'Cancel',
  'Update information': 'Update information',
  'Upload new picture': 'Upload new picture',
  'Pending invitations for today': 'Pending invitations for today',
  'There are no pending invitations for today': 'There are no pending invitations for today',
  'My buildings': 'my buildings',
  'My invitations': 'my invitations',
  'No buildings were found': 'No buildings were found',
  'Home': 'Home',
  'Building detail': 'Building detail',
  'Address': 'Address',
  'Manager': 'Manager',
  'My user roles in this building': 'My user roles in this building',
  'COMPANY_SECRETARY': 'Company secretary',
  'BUILDING_MANAGER': 'Building manager',
  'BUILDING_MANAGER.description': 'Permission to manage the building\'s information and users, companies, access the building and create access invitations',
  'COMPANY_MANAGER': 'Company manager',
  'COMPANY_MANAGER.description': 'Permission to manage the company\'s information and users, access the building and create access invitations',
  'EMPLOYEE': 'Building staff',
  'EMPLOYEE.description': 'Permission to access the building',
  'HOST_EMPLOYEE': 'Building staff with host permissions',
  'HOST_EMPLOYEE.description': 'Permission to access the building and create access invitations',
  'SECURITY': 'Building security staff',
  'SECURITY.description': 'Permission to access the building, create invitations and allow access to other users',
  'Edit': 'Edit',
  'Manage companies': 'Manage companies',
  'No records found': 'No records found',
  'My roles': 'My roles',
  'Manage users': 'Manage users',
  'Detail': 'Detail',
  'The building information has been updated': 'The building information has been updated',
  'Building\'s users': 'Building\'s users',
  'Go to detail': 'Go to detail',
  'Add user to building': 'Add user to building',
  'Add user': 'Add user',
  'Remove user': 'Remove user',
  'Remove': 'Remove',
  'User email': 'User email',
  'The user has been added to the building': 'The user has been added to the building',
  'The user has been removed from the building': 'The user has been removed from the building',
  'Remove user from building?': 'Remove user from building?',
  'Remove user from company?': 'Remove user from company?',
  'Yes': 'Yes',
  'No': 'No',
  'My companies': 'my companies',
  'Companies': 'Companies',
  'Edit company': 'Edit company',
  'Create company': 'Create company',
  'Manager email': 'Manager email',
  'The company has been added to the building': 'The company has been added to the building',
  'The company has been updated': 'The company has been updated',
  'The user has been added to the company': 'The user has been added to the company',
  'The user has been removed from the company': 'The user has been removed from the company',
  'Add user to company': 'Add user to company',
  'Company\'s users': 'Company\'s users',
  'Back to the building\'s companies': 'Back to the building\'s companies',
  'My user roles in this company': 'My user roles in this company',
  'Company detail': 'Company detail',
  'New invitation': 'New invitation',
  'The invitation has been sent': 'The invitation has been sent',
  'Building': 'building',
  'Host': 'Host',
  'You do not have invite permissions in any building': 'You do not have invite permissions in any building',
  'Guest\'s email': 'Guest\'s email',
  'Guest': 'Guest',
  'Invitation date': 'Time',
  'As myself': 'Me',
  'Invitation subject/reason': 'Invitation subject/reason',
  'Invitation duration': 'Invitation duration',
  'Modify meeting': 'Update',
  '1 hour': '1 hour',
  '2 hours': '2 hours',
  '3 hours': '3 hours',
  '5 hours': '5 hours',
  '12 hours': '12 hours',
  'Notes': 'Comments',
  'Allow access to the building for': 'Duration',
  'Create invitation': 'Create invitation',
  'Invitation detail': 'Invitation detail',
  'Date': 'Date',
  'hours': 'hours',
  'Access allowed for': 'Access allowed for',
  'You are hosting': 'you invited',
  'Confirmed': 'Confirmed',
  'Waiting confirmation': 'Waiting confirmation',
  'Rejected': 'Rejected',
  'Cancelled': 'Cancelled',
  'Status': 'Status',
  'Subject/Reason': 'Subject/Reason',
  'Sent to': 'Sent to',
  'Sent date': 'Sent date',
  'I forgot my password': 'I forgot my password',
  'Password recovery': 'Password recovery',
  'Input your email and we will send you a link to restore your password': 'Input your email and we will send you a link to restore your password',
  'Back': 'Back',
  'Information was sent on how to restore your password': 'Information was sent on how to restore your password',
  'Request password recovery': 'Request password recovery',
  'Your password as been restored. You can now access with your new password': 'Your password as been restored. You can now login with your new password',
  'Your email has been verified': 'Your email has been verified',
  'The email could not be verified': 'The email could not be verified',
  'Verify email': 'Verify email',
  'PAST_DUE': 'Date past',
  'CANCELLED': 'Cancelled',
  'REJECTED': 'Declined',
  'CONFIRMED': 'Accepted',
  'PENDING': 'Pending',
  'Confirm': 'Confirm',
  'Reject': 'Reject',
  'The assistance to the invitation has been confirmed': 'The assistance to the invitation has been confirmed',
  'Confirm assistance?': 'Confirm assistance?',
  'The invitation has been cancelled': 'The invitation has been cancelled',
  'Cancel invitation?': 'The host will be notified that you are declining this invitation. Confirm that you are declining?',
  'The invitation has been rejected': 'The invitation has been rejected',
  'Reject invitation?': 'The host will be notified that you are declining this invitation. Confirm that you are declining?',
  'Required': 'Required',
  'Invalid email': 'Invalid email',
  'Password confirm does not match': 'Password confirm does not match',
  'Select a building': 'Select a building',
  'Cellphone': 'Mobile number',
  'Birthdate': 'Birthdate',
  'Habilitaçao': 'CNH',
  'RNE number': 'RNE number',
  'ID Type': 'government ID',
  'RG number': 'RG',
  'RG': 'RG',
  'RNE': 'RNE',
  'Passport': 'Passport',
  'CPF number': 'CPF',
  'Invalid ID number': 'Invalid ID number',
  'Invalid CPF number': 'Invalid CPF number',
  'Secretary': 'Secretary',
  'My secretaries': 'set delegates',
  'New secretary': 'new person',
  'The user has been added as a secretary': 'The user has been added as a secretary',
  'No results': 'No results',
  'Search and select an user': 'Search and select an user',
  'The user has been removed from your secretaries': 'The user has been removed from your secretaries',
  'Remove user from your secretaries?': 'Remove user from your secretaries?',
  '1. Select the building': '1. Select the building',
  '2. Search a user and select from the list': '2. Search a user and select from the list',
  'Send invitation as yourself or acting as a secretary for another user': 'Send invitation as yourself or acting as a secretary for another user',
  'Send invitation as': 'Send invitation on behalf',
  'Building not found': 'Building not found',
  'Invalid token': 'Invalid token',
  'Forbidden': 'Forbidden',
  'There\'s no user registered with that email': 'There\'s no user registered with that email',
  'Email or password incorrect': 'email or password incorrect',
  'The building manager can not be removed': 'The building manager can not be removed',
  'Company not found': 'Company not found',
  'The company manager can not be removed': 'The company manager can not be removed',
  'You cannot invite in this building': 'You cannot invite in this building',
  'The user cannot invite in this building': 'The user cannot invite in this building',
  'Missing file': 'Missing file',
  'You can not cancel a past invitation': 'You can not cancel a past invitation',
  'The invitation can not be cancelled': 'The invitation can not be cancelled',
  'You can not reject your own invitation': 'You can not reject your own invitation',
  'You can not reject a past invitation': 'You can not reject a past invitation',
  'The invitation can not be rejected': 'The invitation can not be rejected',
  'You can not confirm your own invitation': 'You can not confirm your own invitation',
  'You can not confirm a past invitation': 'You can not confirm a past invitation',
  'The invitation can not be confirmed': 'The invitation can not be confirmed',
  'Invitation not found': 'Invitation not found',
  'Can\'t create invitations on behalf user': 'Can\'t create invitations on behalf user',
  'The user can\'t create invitations in this building': 'The user can\'t create invitations in this building',
  'You are not allowed to perform this action': 'You are not allowed to perform this action',
  'Multiple emails separated by comma': 'Multiple emails separated by comma',
  'Sent invitations': 'Sent invitations',
  'Received invitations': 'Invites',
  'Invitation data': 'Invitation data',
  'Account configuration': 'Settings',
  'My information': 'My information',
  'Account': 'Account settings',
  'My vehicle': 'My vehicle',
  'Your vehicle information was updated': 'Your vehicle information was updated',
  'Make': 'Brand',
  'Model': 'Model',
  'Color': 'Color',
  'License plate': 'License plate',
  'The documents were uploaded': 'The documents were uploaded',
  'My documents': 'My documents',
  'ID Front': 'update ID front picture',
  'ID Back': 'update ID back picture',
  'Email or cellphone number': 'email or cellphone number',
  'If using a cellphone number, it must be valid and include both country and area code. Ex (5511943210091)': 'If using a cellphone number, it must be valid and include both country and area code. Ex (5511943210091)',
  'Verify cellphone': 'Verify cellphone',
  'Your cellphone has been verified': 'Your cellphone has been verified',
  'The cellphone could not be verified': 'The cellphone could not be verified',
  'A message was sent to your number with a verification code': 'A message was sent to your number with a verification code',
  'Skip': 'Skip',
  'Cellphone number': 'Mobile number',
  'Only numbers. Including country and area code': 'Only numbers. Including country and area code',
  'Verify cellphone number': 'Verify cellphone number',
  'Your cellphone is already verified': 'Your cellphone is already verified',
  'Request verification code': 'Request verification code',
  'Verification code': 'Verification code',
  'Verify': 'Verify',
  'Invalid number': 'Invalid number',
  'Invalid email or cellphone': 'Invalid email or cellphone',
  'Change email': 'Change email',
  'Change phone': 'Change phone number',
  'We have sent an email to your new email address. The change will be efective once you verify this new email': 'We have sent an email to your new email address. The change will be efective once you verify this new email',
  'Your phone number has been changed': 'Your phone number has been changed',
  'After you submit we will send a verification link to this new email address. The change will only be effective after you have verified this new email address.': 'you will receive a verification link on the email below. The email change will only take effect after you follow that link.',
  'Min 8 characters, one uppercase, one lowercase and one number': 'Min 8 characters, at least one high cap letter and one numeral',
  'The token you are trying to use is not valid': 'The token you are trying to use is not valid',
  'Access': 'Access',
  'There are not access codes available for the selected building': 'There are not access codes available for the selected building',
  'Company role': 'Company role',
  'Company': 'Company',
  'Accept': 'Accept',
  'Terms and Conditions': 'I have read and agree to the terms and conditions',
  'Privacy policy': 'I have read and agree to the privacy policy',
  'There was changes in our Terms of service and Privacy Policy. Please review them and make sure you accept them before you continue using our application.': 'the terms and conditions and privacy policy were updated.',
  'Changes in Terms of service and Privacy Policy': 'Changes in Terms of service and Privacy Policy',
  'Delete my account': 'Delete my account',
  'Your account has been deleted.': 'Your account has been deleted.',
  'Deleting your account is irreversible. All your data will be lost, including your personal information, invitations you sent and received, associations to buildings and secretaries.': 'Deleting your account is irreversible. All your data will be lost, including your personal information, invitations you sent and received, associations to buildings and secretaries.',
  'Input your password to delete the account': 'Input your password to delete the account',
  'I understand. Delete my account': 'I understand. Delete my account',
  'Invitations': 'Invitations',
  'Invitation': 'Invitation',
  'The access code is being generated and will be visible here when the invitation date is due The access code is being generated and will be visible here when the invitation date is due': 'The access code is being generated and will be visible here when the invitation date is due The access code is being generated and will be visible here when the invitation date is due',
  'Host\'s email': 'Host\'s email',
  'Host\'s cellphone': 'Host\'s cellphone',
  'View invitation full detail': 'View invitation full',
  'The access code will be visible here once the invitation is confirmed': 'The access code will be visible here once the invitation is confirmed',
  'Download the Podesubir app here': 'Download the Podesubir app here',
  'My Badge': 'my badge',
  'Visitor': 'visitor',
  'Meetings': 'meetings',
  'New meeting': 'new meeting',
  'Other guests': 'other guests',
  'ACTIVE': 'waiting confirmation',
  'Invited': 'invited',
  'Multiple emails/cellphones separated by comma': 'multiple emails/cellphones separated by comma',
  'Guest\'s contact': 'guest\'s contact',
  'Create meeting': 'create meeting',
  'Guests': 'guests',
  'The meeting has been modified': 'The meeting has been modified',
  'Remove guest from the meeting?': 'Remove guest from the meeting?',
  'Remove guest from the meeting': 'Remove guest from the meeting',
  'Cancel meeting?': 'The guest(s) will be notified that you are cancelling this invitation. Confirm that you are canceling?',
  'The meeting has been cancelled': 'The meeting has been cancelled',
  'Add guests to the meeting': 'Add guests to the meeting',
  'Add': 'Add',
  'For security reasons we encourage you to change your password.': 'For security reasons we encourage you to change your password.',
  'None': 'None',
  'Floor': 'Floor',
  'Room': 'Room/Office',
  'Main company': 'Main company',
  'Allow access before meeting starts (minutes)': 'Allow access before meeting starts (minutes)',
  'Meetings & Invitations': 'meetings & invitations',
  'There are no more records': 'There are no more records',
  'You were invited': 'you are invited',
  'Invited by': 'Invited by',
  'your best welcome': 'your best welcome',
  'Sign up': 'Sign up',
  'Meeting date': 'Time',
  'terms': 'terms and conditions',
  'privacypolicy': 'privacy policy',
  'New email': 'New email',
  'New cellphone number': 'New cellphone number',
  'Attention': 'Attention',
  'Date from': 'Date from',
  'Date to': 'Date to',
  'Group data by': 'Group data by',
  'Month': 'Month',
  'Week': 'Week',
  'Day': 'Day',
  'Hour': 'Hour',
  'Show': 'Show',
  'Show Filters': 'Show Filters',
  'Visitors by building': 'Visitors by building',
  'My stats': 'My reports',
  'Visitors': 'Visitors',
  'Visitors stay': 'Visitor\'s stay',
  'week': 'week',
  'of': 'of',
  'Stats': 'Reports',
  'Average duration (minutes)': 'Average duration (minutes)',
  'Average visits duration': 'Average visits duration',
  'Stay': 'Stay',
  'You need to be building manager to see reports': 'You need to be building manager to see reports',
  'Sent': 'Sent',
  'Received': 'Received',
  'All': 'All',
  'Pending': 'Pending',
  'Your profile has been rejected for the following reasons': 'Your profile has been rejected for the following reasons',
  'Please complete all of your profile information in order to start using Podesubir': 'Please complete all of your profile information in order to start using Podesubir',
  'Missing fields': 'Missing / Invalid fields',
  'Your profile is under review. You will receive a response within 24hs': 'Your profile is under review. You will receive a response within 24hs',
  'Profile picture': 'Profile picture',
  'ID number': 'ID number',
  'Documets ID Front picture': 'Documets ID Front picture',
  'Documents ID Back picture': 'Documents ID Back picture',
  'Click here to correct the relevant fields': 'Click here to correct the relevant fields',

  'Email verification': 'Email verification',
  'Cellphone verification': 'Cellphone verification',
  'Basic information': 'Personal information',
  'ID documents': 'ID documents',
  'Finalize': 'Finalize',
  'We have sent you an email to your address': 'We have sent you an email to your address',
  'Please follow the instructions and click on continue': 'Please follow the instructions and click on continue',
  'If you did not received the email you can': 'If you did not received the email you can',
  'request a new verification email': 'request a new verification email',
  'Alternatively you can': 'Alternatively you can',
  'change your email address': 'change your email address',
  'Continue': 'Continue',
  'Change email': 'Change email',
  'A new email was sent to your address': 'A new email was sent to your address',
  'Change email address': 'Change email address',
  'Your email address was changed and a new verification email was sent': 'If you made a mistake or want to change your phone number',
  'If you made a mistake or want to change your phone number': 'If you made a mistake or want to change your phone number',
  'Change cellphone': 'Change cellphone',
  'We will now verify your cellphone number': 'We will now verify your cellphone number',
  'We will send you a 4 digit verification code via SMS': 'We will send you a 4 digit verification code via SMS',
  'I am ready, send me the verification code': 'I am ready, send me the verification code',
  'click here': 'click here',
  'Change cellphone number': 'Change cellphone number',
  'An SMS was sent to your cellphone with the verification code': 'A SMS was sent to your cellphone with the verification code',
  'Invalid code': 'The code entered is not valid or has expired. Check your SMS inbox and request a new SMS if neccesary',
  'Your cellphone number was changed and a new verification sms was sent': 'Your cellphone number was changed and a new verification SMS was sent',
  'Country of residence': 'Country of residence',
  'cpf is a required field': 'Required',
  'Your face must be visible and centered in the picture frame': 'Your face must be visible and centered in the picture frame',
  'No other people should be visible in the picture': 'No other people should be visible in the picture',
  'The picture must match the picture in the ID documents from the next step': 'The picture must match the picture in the ID documents from the next step',
  'Take picture': 'Take picture',
  'NO_FACES': 'No faces were detected in the picture. Read the instructions and try again',
  'MULTIPLE_FACES': 'Multiple faces were detected in the picture. Read the instructions and try again',
  'Confirm and save': 'Confirm',
  'Make sure the ID pictures are clear and all the information can be seen': 'Make sure the ID pictures are clear and all the information can be seen',
  'The face in the front of the ID must match your profile picture': 'The face in the front of the ID must match your profile picture',
  'The information in the ID must match the information you provided in the previous step': 'The information in the ID must match the information you provided in the previous step',
  'ID picture front': 'ID picture front',
  'ID picture back': 'ID picture back',
  'You have completed all the registration steps': 'You have completed all the registration steps',
  'If you would like to change any information you can go back to a previous step': 'If you would like to change any information you can go back to a previous step',
  'When you are ready, press finalize to complete the process': 'When you are ready, press finalize to complete the process',
  'MISSING_REVIEW_CORRECTIONS': 'There are profile corrections still pending before you can submit it',
  'FACE_DOESNT_MATCH': 'The image provided for your profile picture does not match the image provided as your ID document front',
  'INFORMATION_DOESNT_MATCH': 'Some of the information provided in "Personal information" does not match the information in your ID document.',
  'There was a validation error with the information provided': 'There was a validation error with the information provided',
  'Go back to the previous steps and correct the information': 'Go back to the previous steps and correct the information',
  'If the information supplied is correct, click the following button to submit everything as is and a customer support representative will review your profile': 'If the information supplied is correct, click the following button to submit everything as is and a customer support representative will review your profile',
  'Submit my profile for review': 'Submit my profile for review',
  'Your profile is under review by a customer service representative. We will notify you via email once the review is complete': 'Your profile is under review by a customer service representative. We will notify you via email once the review is complete',
  'Your profile has been rejected by a customer support representative': 'Your profile has been rejected by a customer support representative',
  'Please correct the following fields and re-submit your profile to be reviewed again': 'Please correct the following fields and re-submit your profile to be reviewed again',
  'name': 'name',
  'surname': 'surname',
  'company': 'company',
  'company_role': 'company role/position',
  'birthdate': 'birthdate',
  'id_type': 'ID type',
  'id_number': 'ID number',
  'cpf': 'CPF number',
  'profile_picture': 'Profile picture',
  'ID_FRONT': 'ID document front',
  'ID_BACK': 'ID document back',
  'You have performed all the requested corrections. Submit your profile for review': 'You have performed all the requested corrections. Submit your profile for review',
  'Your profile is now complete and your account verified. You can start using podesubir': 'Your profile is now complete and your account verified. You can start using podesubir',
  'true': 'Does not match with information in the documents picture',
  'Access code': 'Access code',
  'employees': 'staff',
  'Filter': 'filter',
  'Roles': 'Roles',
  'Roles in the building': 'Roles in the building',
  'users': 'users',
  'Users': 'Users',
  'Date': 'Date',
  'Time': 'Time',
  'next': 'next',
  'your session expired. Please log in again': 'Your session timed out and was ended for your security. To see your credential and meetings login again',
  'You are trying to log in from an unknown device. We have sent you an email with a verification code. Please input the code below to complete your login': 'You are trying to log in from an unknown device. We have sent you an email with a verification code. Please input the code below to complete your login',
  'Go back': 'Go back',
  'INVALID_AUTH': 'Username or password incorrect',
  'EMAIL_ALREADY_VERIFIED': 'The e-email address is already verified',
  'CELLPHONE_ALREADY_VERIFIED': 'The cellphone number is already verified',
  'INVALID_TOKEN': 'The token is invalid',
  'FORBIDDEN': 'You are not allowed to perform this action',
  'USER_NOT_FOUND': 'User not found',
  'NOT_FOUND': 'Not found',
  'MEETING_IS_PAST': 'The meeting is in the past. The action can not be performed',
  'MISSING_INVITATIONS_PERMISSIONS': 'Missing invitation permissions for this building',
  'USER_IS_NOT_GUEST': 'You are not a guest in this invitation. The action can not be performed',
  'PAST_DUE': 'The meeting is in the past. The action can not be performed',
  'CONFIRMATION_FAILED': 'The invitation could not be confirmed',
  'REJECTION_FAILED': 'The invitation could not be rejected',
  'CANCELLATION_FAILED': 'The invitation could not be cancelled',
  'EMAIL_ALREADY_IN_USE': 'The e-mail address you input is already in use',
  'CELLPHONE_ALREADY_IN_USE': 'The cellphone you input is already in use',
  'PROFILE_NOT_APPROVED': 'You are not allowed to perform this action',
  'VALIDATION_ERROR': 'Validation error',
  'The cellphone entered is already in use': 'The cellphone entered is already in use',
  'The email entered is already in use': 'The email entered is already in use',
  'Registration': 'Registration',
  'Domain': 'Domain',
  'Total users': 'Total users',
  'previous': 'previous',
  'Total approved': 'Total approved',
  '% approved': '% approved',
  'Users growth': 'Users growth',
  'Approved growth': 'Approved growth',
  'Compare data to previous': 'Compare data to previous',
  'Trimester': 'Trimester',
  'Month': 'Month',
  'Week': 'Week',
  'Day': 'Day',
  'Hour': 'Hour',
  'Access list': 'Access list',
  'Security staff': 'Security staff',
  'Access date': 'Access date',
  'PS user profile approved': 'PS user profile approved',
  'PS user profile complete': 'PS user profile complete',
  'PS user email': 'PS user email',
  'Has invitation in PS': 'Has invitation in PS',
  'Download CSV': 'Download CSV',
  'Card code': 'Card code',
  'Used QR': 'Used QR',
  'Only with corresponding invitation': 'Only with corresponding invitation',
  'Only with corresponding user in PS': 'Only with corresponding user in PS',
  'Only with profile completed in PS': 'Only with profile completed in PS',
  'Only with profile approved in PS': 'Only with profile approved in PS',
  'Request the guest to announce it\'s arrival to be allowed in': 'Request the guest to announce it\'s arrival to be allowed in',
  'Your guest arrived to the meeting. Click here to see more': 'Your guest arrived to the meeting. Click here to see more',
  'The host delayed your entry to the meeting. Click here to see more': 'The host delayed your entry to the meeting. Click here to see more',
  'The host allowed your entry to the meeting. Click here to see more': 'The host allowed your entry to the meeting. Click here to see more',
  'The waiting time is over. We have reminded the host about your arrival': 'The waiting time is over. We have reminded the host about your arrival',
  'Your host requested for you to wait {{minutes}} minutes': 'Your host requested for you to wait {{minutes}} minutes',
  'When your access is authorized you will be able to access directly with the QR code that will appear here': 'When your access is authorized you will be able to access directly with the QR code that will appear here',
  'Close': 'Close',
  'When your access is authorized you will be able to access directly with the QR code that will appear here': 'When your access is authorized you will be able to access directly with the QR code that will appear here',
  'Your arrival has been notified': 'Your arrival has been notified',
  'Announce arrival': 'Announce arrival',
  'Allow access': 'Allow access',
  'Wait 5 minutes': 'Wait 5 minutes',
  'Wait 10 minutes': 'Wait 10 minutes',
  'Your guest arrived': 'Your guest arrived',
  'The guest has been allowed access': 'The guest has been allowed access',
  'Allow access?': 'Allow access?',
  'The guest has been notified of the delay': 'The guest has been notified of the delay',
  'Delay the access?': 'Delay the access?',
  'agenda belongs to': 'agenda belongs to',
  'You must not be wearing a facemask or anything that partially covers your face': 'You must not be wearing a facemask or anything that partially covers your face',
  'For a better experience use Google Chrome or Microsoft Edge': 'For a better experience use Google Chrome or Microsoft Edge',
  'New picture': 'New picture',
  'Access allowed by guards': 'Access allowed by guards',
  'Search': 'Search',
  'Security guard': 'Security guard',
  'guard.access.ENTRY': 'ENTRY ALLOWED',
  'guard.access.EXIT': 'EXIT ALLOWED',
  'guard.access.DENY_ENTRY': 'ENTRY DENIED',
  'Event': 'event',
  'who.visited.who': 'who visited who',
  'Employees per company': 'Employees per company',
  'Local': 'local',
  'Host name': 'host name',
  'Host surname': 'Host surname',
  'Host email': 'Host email',
  'Guest name': 'Guest name',
  'Guest surname': 'Guest surname',
  'Guest email': 'Guest email',  
  'Guest phone': 'Guest phone',  
  'Guest document': 'Guest document',  
  'Guest CPF': 'Guest CPF',  
  'Host company': 'Host company',
  'Guest company': 'Guest company',
  'Export': 'export',
  'Order:': 'Order:',
  'Badge number:': 'Badge number:',
  'Guest badge number': 'Guest badge number',
  'Date of visit': 'Date of visit',
  'Start time': 'Start time',
  'Finish time': 'Finish time',
  'Document number': 'Document number',
  'Document type': 'Document type',
  'Date and time format': 'MM-DD-YYYY HH:mm',
  'Return': 'Return',
  'Are you sure about that?': 'Are you sure about that?',
  'Access code linked to another user': 'Access code linked to another user',
  'Associate with new user': 'Associate with new user',
  'Unlinked user': 'Unlinked user',
  Copy: 'Copy',
  'Taking photo': 'Taking photo',
  'Send image': 'Send image',
  'Max date Error': 'Maximum period of 3 months',
  'Min date Error': 'Cannot be less than start date'
};
