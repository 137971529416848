import * as React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { updateUserData } from '../../../actions/users';
import CellphoneVerificationComponent from '../../../components/Register/steps/cellphone-verification';
import * as AuthApi from '../../../api/auth';

class CellphoneVerification extends React.Component {
  constructor(props) {
    super(props);
  }

  verify = async (body) => {
    const { data } = await AuthApi.verifyCellphone(body);

    this.props.updateUserData(data);
  }

  resend = async () => {
    await AuthApi.sendSms();

    toast.success(this.props.t('An SMS was sent to your cellphone with the verification code'));
  }

  change = async (body) => {
    const { data } = await AuthApi.correctCellphone(body);

    toast.success(this.props.t('Your cellphone number was changed and a new verification sms was sent'));

    this.props.updateUserData(data);
  }

  render() {
    const { user } = this.props;

    return (
      <CellphoneVerificationComponent
        user={user}
        verify={this.verify}
        resend={this.resend}
        change={this.change}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  updateUserData: (data) => dispatch(updateUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CellphoneVerification));
