import * as React from 'react';
import VerifyEmail from '../../components/Register/verify-email';
import * as AuthApi from '../../api/auth';

export default class VerifyEmailContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      success: false,
      hash: this.props.match.params.hash,
    };
  }

  render() {
    return (
      <VerifyEmail
        success={this.state.success}
        error={this.state.error}
      />
    )
  }

  async componentDidMount() {
    try {
      await AuthApi.verifyEmail(this.state.hash);

      this.setState({ success: true });
    } catch (err) {
      const error = err.response.data.error;

      this.setState({ error });
    }
  }
}
