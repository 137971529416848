import * as React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from '../Shared/infinite-scroll';
import { Typography, Grid } from '@material-ui/core';
import InvitationCard from './invitation-card';

export default ({ onPageChange, total, pages, page, invitations, confirm, reject, cancel }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <InfiniteScroll
        dataLength={total}
        next={() => onPageChange({ page: page + 1 })}
        hasMore={page < pages}
        loader={<h4>Loading...</h4>}
        endMessage={
          <Grid item xs={12}>
            <Typography align="center" color="primary" component="div">
              {t('There are no more records')}
            </Typography>
          </Grid>
        }
        style={{ paddingBottom: '10%' }}
      >
        <Grid item container spacing={2}>
          {
            invitations.map((weekObj, i) => {
              return (
                <React.Fragment key={i}>
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ color: '#575757', fontWeight: 'bold' }}>{weekObj.week}</Typography>
                  </Grid>
                  {
                    weekObj.weekly.map((dayObj, ii) => {
                      return (
                        <React.Fragment key={ii}>
                          <Grid item xs={1}>
                            <Typography>{dayObj.day}</Typography>
                          </Grid>
                          <Grid item xs={11}>
                            {
                              dayObj.records.map((invitation, iii) => {
                                return (
                                  <InvitationCard key={iii} invitation={invitation} {...{ confirm, reject, cancel }} />
                                );
                              })
                            }
                          </Grid>
                        </React.Fragment>
                      );
                    })
                  }
                </React.Fragment>
              );
            })
          }
        </Grid>
      </InfiniteScroll>
    </Grid>
  );
}
