import * as React from 'react';
import { Formik } from 'formik';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '../Shared/BsInput';
import { AddCompanySchema, EditCompanySchema } from '../../validation-schemas';
import ContainerCard from '../Shared/container-card';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
  isValid,
  mainCompanies,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Input
            type="text"
            value={values.name}
            onChange={handleChange}
            name="name"
            label={t('Name')}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Input
            type="text"
            value={values.floor}
            onChange={handleChange}
            name="floor"
            label={t('Floor')}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Input
            type="text"
            value={values.room}
            onChange={handleChange}
            name="room"
            label={t('Room')}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Input
            type="text"
            value={values.phone}
            onChange={handleChange}
            name="phone"
            label={t('Phone')}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Input
            type="number"
            value={String(values.access_before_minutes)}
            onChange={handleChange}
            name="access_before_minutes"
            label={t('Allow access before meeting starts (minutes)')}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            className="mt-3"
            variant="outlined"
            color="default"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            {t('Save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({ onSubmit, company, isLoading, mainCompanies }) => {
  const { t } = useTranslation();

  return (
    <ContainerCard
      xs={12}
      md={12}
      lg={12}
      header={
        <Typography variant="h6" align="center">
          {
            company.id ? t('Edit company') : t('Create company')
          }
        </Typography>
      }
      content={
        !isLoading && <Formik
          initialValues={company}
          validationSchema={company.id ? EditCompanySchema : AddCompanySchema}
          enableReinitialize={true}
          onSubmit={
            async (values, { setSubmitting, setErrors }) => {
              try {
                await onSubmit(values);
              } catch (err) {
                if (err.response && err.response.data.errors) {
                  setErrors(err.response.data.errors);
                }

                setSubmitting(false);
              }
            }
          }
          render={p => <RenderForm {...p} {...{ t, mainCompanies }}/>}
        />
      }
    />
  );
}