import * as React from 'react';
import { connect } from 'react-redux';
import { loggedIn } from '../../actions/auth';
import RegisterForm from '../../components/Register';
import * as AuthApi from '../../api/auth';
import { withTranslation } from 'react-i18next';

class RegisterContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mail: this.props.match.params.mail,
      loading: true,
      device_id: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  getDeviceId = async () => {
    const id = await AuthApi.getDeviceId();

    this.setState({
      device_id: id,
      loading: false,
    });
  }

  async onSubmit(body) {
    const { data } = await AuthApi.register({
      ...body,
      device_id: this.state.device_id,
      language: this.props.i18n.language.substr(0, 2),
    });

    this.props.loggedIn(data);
    this.props.history.push('/app/complete-registration');
  }

  componentDidMount() {
    this.getDeviceId();
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    return (
      <RegisterForm
        onSubmit={this.onSubmit}
        mail={this.state.mail}
      />
    )
  }
}

const mapDispatchToProps = dispatch => ({
  loggedIn: (data) => dispatch(loggedIn(data)),
});


export default withTranslation()(connect(null, mapDispatchToProps)(RegisterContainer));
