import * as React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { updateUserData } from '../../../actions/users';
import ProfilePicComponent from '../../../components/Register/steps/profile-picture';
import * as AuthApi from '../../../api/auth';

class ProfilePicture extends React.Component {
  constructor(props) {
    super(props);
  }

  onSubmit = async (file) => {
    const { data } = await AuthApi.profilePicture(file);

    this.props.updateUserData(data);
  }

  render() {
    const { user, handleNext, handleBack } = this.props;

    return (
      <ProfilePicComponent
        user={user}
        onSubmit={this.onSubmit}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  updateUserData: (data) => dispatch(updateUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProfilePicture));
