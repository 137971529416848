import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { updateUserData } from '../../../actions/users';
import DocumentsComponent from '../../../components/Register/steps/documents';
import * as AuthApi from '../../../api/auth';

class Documents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
    }
  }

  onSubmit = async (files) => {
    try {
      const { data } = await AuthApi.documents(files);

      this.props.updateUserData(data);
    } catch (err) {
      if (!err.response) {
        console.log(err);

        return;
      }

      const { response } = err;
      const { data } = response;

      if (data.errors) {
        this.setState({ errors: data.errors });
      }
    }
  }

  render() {
    const { user, handleNext, handleBack } = this.props;

    return (
      <DocumentsComponent
        user={user}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        handleNext={handleNext}
        handleBack={handleBack}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  updateUserData: (data) => dispatch(updateUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Documents));
