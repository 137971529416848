import * as React from 'react';
import {
  Grid,
  Typography,
  Button,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import ContainerCard from '../Shared/container-card';
import ListTable from '../Shared/list-table';
import NoRecords from '../Shared/NoRecords';
import { Input, Select } from '../Shared/BsInput';
import {
  BUILDING_MANAGER,
  EMPLOYEE,
  HOST_EMPLOYEE,
  SECURITY,
  COMPANY_MANAGER,
} from '../../constants';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
  isValid,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid spacing={2} container>
        <Grid item xs={12} md={4}>
          <Input
            type="text"
            value={values.email}
            onChange={handleChange}
            name="email"
            label={t('Email')}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            type="text"
            value={values.name}
            onChange={handleChange}
            name="name"
            label={t('Name')}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            type="text"
            value={values.surname}
            onChange={handleChange}
            name="surname"
            label={t('Surname')}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            style={{ paddingTop: '8px' }}
            value={values.role}
            onChange={handleChange}
            name="role"
            label={t('Role')}
            errors={errors}
            options={[
              { value: '', label: '-' },
              { value: BUILDING_MANAGER, label: t(BUILDING_MANAGER) },
              { value: COMPANY_MANAGER, label: t(COMPANY_MANAGER) },
              { value: HOST_EMPLOYEE, label: t(HOST_EMPLOYEE) },
              { value: EMPLOYEE, label: t(EMPLOYEE) },
              { value: SECURITY, label: t(SECURITY) },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            className="mt-3"
            variant="contained"
            color="default"
            type="submit"
            disabled={isSubmitting}
          >
            {t('Filter')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const RenderTable = ({ onPageChange, pages, page, users, t, building }) => {
  return (
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell>{t('Name')}</TableCell>
          <TableCell>{t('Email')}</TableCell>
          <TableCell>{t('Roles')}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      }
      body={
        <>
          {
            users.map((user, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{user.name} {user.surname}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {
                      user.Roles.map((ub, i) => (
                        <div key={i}>{t(ub.role)}</div>
                      ))
                    }
                  </TableCell>
                  <TableCell>
                    <Button
                      type="button"
                      variant="outlined"
                      color="secondary"
                      size="small"
                      component={Link}
                      to={`/app/buildings/${building.id}/users/edit/${user.id}`}
                    >
                      {t('Edit')}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })
          }
        </>
      }
    />
  );
};

export default ({ onPageChange, pages, page, users, building }) => {
  const { t } = useTranslation();

  return (
    <>
      <ContainerCard
        xs={12}
        md={12}
        lg={12}
        header={
          <Typography variant="h6" align="center">
            "{building.name}" {t('employees')}
          </Typography>
        }
        content={
          <>
            <div style={{ textAlign: 'right' }}>
              <Button
                component={Link}
                variant="contained"
                size="small"
                color="primary"
                to={`/app/buildings/${building.id}/users/add`}
              >
                {t('Add user')}
              </Button>
            </div>
            <hr />
            <Formik
              initialValues={{
                email: '',
                name: '',
                surname: '',
                role: '',
              }}
              enableReinitialize={true}
              onSubmit={
                async (values, { setSubmitting, setErrors, resetForm }) => {
                  try {
                    await onPageChange({ page: 1, ...values });
                    setSubmitting(false);
                  } catch (err) {console.log(err)
                    if (err.response && err.response.data.errors) {
                      setErrors(err.response.data.errors);
                    }

                    setSubmitting(false);
                  }
                }
              }
              render={p => <RenderForm {...p} {...{ t }}/>}
            />
            <hr />
            {
              !users.length && <NoRecords />
            }
            {
              !!users.length && <RenderTable {...{ onPageChange, pages, page, users, t, building }} />
            }
          </>
        }
      />
    </>
  );
}
