import axios from 'axios';
import request, { catchError } from './index';
import { req, res } from '../actions/ui';
import store from '../store';

const BASEURL = process.env.REACT_APP_API_URL;

export function me() {
  return request({
    method: 'GET',
    path: '/api/users',
  });
}

export function fcm(body) {
  return request({
    body,
    method: 'POST',
    path: '/api/users/fcm',
  });
}

export function testFcm() {
  return request({
    method: 'POST',
    path: '/api/users/test-fcm',
  });
}

export function getCar() {
  return request({
    method: 'GET',
    path: '/api/users/cars',
  });
}

export function updateCar(body) {
  return request({
    body,
    method: 'PUT',
    path: '/api/users/cars',
  });
}

export function updateProfile(body) {
  return request({
    body,
    method: 'PUT',
    path: '/api/users',
  });
}

export function changePassword(body) {
  return request({
    body,
    method: 'PUT',
    path: '/api/users/change-password',
  });
}

export function profilePicture(file) {
  const fd = new FormData();
  fd.append('profile_picture', file);

  store.dispatch(req());

  return axios.put(`${BASEURL}/api/users/profile-picture`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  }).then((response) => {
    store.dispatch(res());

    return response;
  }).catch(catchError);
}

export function requestCellphoneVerify() {
  return request({
    method: 'POST',
    path: '/api/users/request-cellphone-verification',
  });
}

export function cellphoneVerify(body) {
  return request({
    body,
    method: 'POST',
    path: '/api/users/verify-cellphone',
  });
}

export function changeEmail(body) {
  return request({
    body,
    method: 'PUT',
    path: '/api/users/change-email',
  });
}

export function changePhone(body) {
  return request({
    body,
    method: 'PUT',
    path: '/api/users/change-cellphone',
  });
}

export function acceptTos(body) {
  return request({
    body,
    method: 'POST',
    path: '/api/users/accept-tos',
  });
}