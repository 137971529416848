import * as React from 'react';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import InvitationView from '../../components/Invitations/view';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';
import * as InvitationsApi from '../../api/invitations';

class InvitationViewContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invitationId: this.props.match.params.invitationId,
      invitation: null,
      isLoading: true,
    };

    this.cancel = this.cancel.bind(this);
    this.confirm = this.confirm.bind(this);
    this.reject = this.reject.bind(this);
    this.fetchInvitation = this.fetchInvitation.bind(this);
  }

  async confirm(invidationId) {
    const onConfirmClick = async () => {
      await InvitationsApi.confirm(invidationId);

      toast.success(this.props.t('The assistance to the invitation has been confirmed'));
      this.fetchInvitation();
    };

    confirmAlert({
      message: this.props.t('Confirm assistance?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  async cancel(invidationId) {
    const onConfirmClick = async () => {
      await InvitationsApi.cancel(invidationId);

      toast.success(this.props.t('The invitation has been cancelled'));
      this.fetchInvitation();
    };

    confirmAlert({
      message: this.props.t('Cancel invitation?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  async reject(invidationId) {
    const onConfirmClick = async () => {
      await InvitationsApi.reject(invidationId);

      toast.success(this.props.t('The invitation has been rejected'));
      this.fetchInvitation();
    };

    confirmAlert({
      message: this.props.t('Reject invitation?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  announceArrival = async (invitationId) => {
    const onConfirmClick = async () => {
      await InvitationsApi.announceArrival(invitationId);

      toast.success(this.props.t('Your arrival has been announced'));
      this.fetchInvitation();
    };

    confirmAlert({
      message: this.props.t('Annouce arrival to the meeting?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  async fetchInvitation() {
    const { data } = await InvitationsApi.get(this.state.invitationId);

    this.setState({
      invitation: data,
      isLoading: false,
    });
  }

  render() {
    return (
      <InvitationView
        invitation={this.state.invitation}
        isLoading={this.state.isLoading}
        confirm={this.confirm}
        cancel={this.cancel}
        reject={this.reject}
        announceArrival={this.announceArrival}
      />
    );
  }

  async componentDidMount() {
    this.fetchInvitation();
  }
}

export default withTranslation()(InvitationViewContainer);