import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '10px',
    width: '100%',
    marginBottom: '15px',
  },
  accepted: {
    backgroundColor: 'rgba(223, 255, 223, 0.5)',
  },
  cancelled: {
    backgroundColor: 'rgba(255, 228, 228, 0.5)',
  },
  thumbContainer: {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid #d0cbcb',
  },
  thumbSingle: {
    width: '100%',
  },
  thumbMulti: {
    width: '50%',
  },
  plus: {
    width: '50%',
    display: 'inline-block',
    color: '#fff',
  },
  actions: {
    display: 'flex',
    lineHeight: '1rem',

    [theme.breakpoints.up('sm')]: {
      lineHeight: '1.5rem',
    },
  },
  webActions: {
    width: '99%',
    marginTop: '10px',
  },
  buttonsContainer: {
    flexGrow: 1,
    textAlign: 'right',
  },
  iconBtn: {
    padding: '0px 4px',
    color: 'rgb(255,0,0)',
    fontSize: '1.3rem',
    minWidth: 'inherit',
  },
  iconBtnSuccess: {
    color: theme.palette.secondary.main,
  },
  textBtnCancel: {
    backgroundColor: 'rgba(255, 0, 0, 0.05)',
    borderColor: 'rgba(255, 0, 0, 0.3)',
    color: 'rgba(255, 0, 0, 0.9)',
    borderWidth: '1px',
  },
  textBtnCopy: {
    backgroundColor: 'rgba(0, 102, 204, 0.05)',
    borderColor: 'rgba(0, 102, 204, 0.3)',
    color: 'rgba(0, 102, 204, 0.9)',
    borderWidth: '1px',
  },
  chipContainer: {
    flexGrow: 1,
  },
  chip: {
    fontSize: '0.6rem',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: '1px 5px',
    borderRadius: '16px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '0.8rem',
      padding: '3px 10px',
    },
  },
  subject: {
    fontSize: '0.85rem',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.4rem',
    },
  },
  location: {
    fontSize: '0.75rem',
    lineHeight: '10px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.2rem',
      lineHeight: '1.5rem',
    },
  },
  secretaryContainer: {
    backgroundColor: 'white',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  secretary: {
    '& img': {
      borderRadius: '20px',
      marginLeft: '20px',
      marginRight: '20px',
      width: '30px',
    },
  },
}));

const ImageGrid = ({ invitations }) => {
  const classes = useStyles();

  if (invitations.length < 2) {
    const url = invitations[0]
      ? invitations[0].Guest.profile_picture || '/profile-placeholder.png'
      : '/profile-placeholder.png';

    return (
      <div className={classes.thumbContainer}>
        <img src={`${url}?w=300&h=300`} alt="guest-0" className={classes.thumbSingle} />
      </div>
    )
  }

  return (
    <div className={classes.thumbContainer}>
      {
        invitations.slice(0, 3).map((invitation, i) => {
          const url = invitation.Guest.profile_picture || '/profile-placeholder.png';

          return (
            <img key={i} src={`${url}?w=300&h=300`} alt={`guest-${i}`} className={classes.thumbMulti} />
          );
        })
      }
      {
        invitations.length === 4 &&
        <img src={invitations[3].Guest.profile_picture || '/profile-placeholder.png'} alt="guest-3" className={classes.thumbMulti} />
      }
      {
        invitations.length > 4 &&
        <Typography component="div" variant="body1" align="center" className={classes.plus}>+ {invitations.length - 3}</Typography>
      }
    </div>
  );
};

export default ({ meeting, cancel }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const bigScreen = useMediaQuery(theme => theme.breakpoints.up('md'), { noSsr: true });

  return (
    <>
      {
        meeting.role_in_meeting === 'Secretary' && (
          <Box px={2} py={1} textAlign="center" className={classes.secretaryContainer} boxShadow={2}>
            <Box flex={1} className={classes.secretary}>
              <Typography component="span">
                {t('agenda belongs to')}
              </Typography>
              <img src={`${meeting.Host.profile_picture || '/profile-placeholder.png'}?w=50&h=50`} />
              <Typography component="span">
                {meeting.Host.name} {meeting.Host.surname}
              </Typography>
            </Box>
          </Box>
        )
      }

      <Paper
        className={clsx(classes.paper, {
          [classes.accepted]: meeting.Invitations.every(i => i.status === 'CONFIRMED'),
          [classes.cancelled]: meeting.Invitations.every(i => i.status === 'CANCELLED'),
        })}
        onClick={(e) => {
          e.stopPropagation();

          // history.push(`/app/meetings/${meeting.id}`);
        }}
        elevation={3}
      >
        <Grid item container spacing={1}>
          <Grid item xs={3} md={2} lg={1}>
            <ImageGrid invitations={meeting.Invitations} />
          </Grid>
          <Grid item xs={9} md={10} lg={11} style={{ position: 'relative' }}>
            <div className={classes.actions}>
              <div className={classes.chipContainer}>
                <span className={classes.chip}>
                  <i className="fa fa-upload"></i> {t('You are hosting')}
                </span>
              </div>
              <div className={classes.buttonsContainer}>
                {
                  meeting.can_cancel && cancel && !bigScreen &&
                  <Button
                    className={classes.iconBtn}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();

                      cancel(meeting.id);
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </Button>
                }
              </div>
            </div>
            <Typography className={classes.subject} component="div">
              <b>{meeting.subject}</b>
              &nbsp;
              {moment(meeting.date_from).format('HH:mm')} - {moment(meeting.date_to).format('HH:mm')}
            </Typography>
            <Typography className={classes.location} component="div">
              {meeting.Building.name} - {meeting.Building.address}
            </Typography>
            {
              meeting.Company &&
              <Typography className={classes.location} component="div">
                {meeting.Company.name} - {meeting.Company.floor} - {meeting.Company.room}
              </Typography>
            }
            <Hidden smDown>
              <div className={[classes.actions, classes.webActions].join(' ')}>
                <div className={classes.buttonsContainer}>
                  <Button
                    component={Link}
                    to={`/app/meetings/${meeting.id}`}
                    color="primary"
                    variant="outlined"
                    size="small"
                    startIcon={<i className="fa fa-info-circle"></i>}
                  >
                    {t('Detail')}
                  </Button>
                  &nbsp;
                  <Button
                    className={classes.textBtnCopy}
                    component={Link}
                    to={`/app/meetings/${meeting.id}/copy`}
                    color="primary"
                    variant="outlined"
                    size="small"
                    startIcon={<i className="fa fa-info-circle"></i>}
                  >
                    {t('Copy')}
                  </Button>
                  &nbsp;
                  {
                    meeting.can_cancel && cancel &&
                    <Button
                      className={classes.textBtnCancel}
                      size="small"
                      variant="outlined"
                      startIcon={<i className="fa fa-times"></i>}
                      onClick={(e) => {
                        e.stopPropagation();

                        cancel(meeting.id);
                      }}
                    >
                      {t('Cancel')}
                    </Button>
                  }
                </div>
              </div>
            </Hidden>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
