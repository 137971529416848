import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import { Phone } from '../../Shared/BsInput';
import { ChangePhoneSchema } from '../../../validation-schemas';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    boxShadow: 'none',
  },
}));

const RenderForm = ({
  values,
  errors,
  handleChange,
  setFieldValue,
  handleSubmit,
  isSubmitting,
  isValid,
  t,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Phone
        onChange={(v) => {
          setFieldValue('cellphone', v);
        }}
        label={t('New cellphone number')}
        errors={errors}
        name="cellphone"
      />
      <Button
        className="mt-2"
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting || !isValid}
        fullWidth
      >
        {t('Change cellphone')}
      </Button>
    </form>
  );
};

export default (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { opened, handleClose, user, onSubmit } = props;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="change-cellphone"
      open={opened}
      maxWidth="md"
      fullWidth
    >
      <div className={classes.paper}>
        <Typography>
          {t('Change cellphone number')}
        </Typography>
        <Formik
          initialValues={{
            cellphone: user.cellphone
          }}
          validationSchema={ChangePhoneSchema}
          enableReinitialize={true}
          onSubmit={
            async (values, { setSubmitting, setErrors }) => {
              try {
                await onSubmit({
                  cellphone: values.cellphone.replace('+', ''),
                });
              } catch (err) {
                if (err.response && err.response.data.errors) {
                  setErrors(err.response.data.errors);
                }

                setSubmitting(false);
              }
            }
          }
          render={(p) => <RenderForm {...p} {...{ t }} />}
        />
      </div>
    </Dialog>
  );
};