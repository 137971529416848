import * as React from 'react';
import { Button } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import UploadPictureComponent from '../../components/Shared/upload-image-dialog';

class UploadPicture extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
    };
  }

  setOpen = () => this.setState({ opened: true });
  setClosed = () => this.setState({ opened: false });

  onSubmit = (base64) => {
    this.props.onSubmit(base64);
  }

  render() {
    let src = '/profile-placeholder.png';

    const { profile_picture } = this.props;

    if (profile_picture) {
      src = profile_picture;
    }

    return (
      <div>
        <div className="text-center">
          <img
            alt="" src={src}
            className="img-thumbnail"
            style={{ maxWidth: '40%' }}
            onClick={this.setOpen}
          />
          <div>
            <a href="javascript:void(0)" onClick={this.setOpen}>
              <i className="fa fa-pencil"></i>
            </a>
          </div>
        </div>
        <UploadPictureComponent
          onConfirm={this.onSubmit}
          handleClose={this.setClosed}
          opened={this.state.opened}
        />
      </div>
    );
  }
}

export default withTranslation()(UploadPicture);