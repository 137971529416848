import * as React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TablePagination,
} from '@material-ui/core';

export default (props) => {
  const {
    head, body,
    onPageChange, pages, page,
  } = props;

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={pages}
        rowsPerPage={10}
        page={page - 1}
        onChangePage={(e, page) => onPageChange({ page: page + 1 })}
      />
      <Table size="small">
        <TableHead>
          {head}
        </TableHead>
        <TableBody>
          {body}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={pages}
        rowsPerPage={10}
        page={page - 1}
        onChangePage={(e, page) => onPageChange({ page: page + 1 })}
      />
    </div>
  );
}
