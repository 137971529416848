import * as React from 'react';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import ChangePwdForm from '../../components/Profile/change-password';
import * as UsersApi from '../../api/users';

class ChangePwdContainer extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    await UsersApi.changePassword(body);

    toast.success(this.props.t('Your password has been changed'));
    this.props.history.push('/app/account-configuration');
  }

  render() {
    const query = queryString.parse(this.props.location.search);

    return (
      <ChangePwdForm
        onSubmit={this.onSubmit}
        redirected={!!query.redirected}
      />
    );
  }
}

export default withTranslation()(ChangePwdContainer);
