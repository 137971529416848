import * as React from 'react';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Popover from '@material-ui/core/Popover';
import Modal from 'react-bootstrap4-modal';
import {
  Grid,
  Button,
  Typography,
  Paper,
  LinearProgress,
  Box,
} from '@material-ui/core';
import { AutoComplete, Input, Select } from '../Shared/BsInput';
import {
  BUILDING_MANAGER,
  EMPLOYEE,
  HOST_EMPLOYEE,
  SECURITY,
  COMPANY_MANAGER,
  COMPANY_SECRETARY,
} from '../../constants';
import { RequestPwdRecoverySchema, AddUserSchema } from '../../validation-schemas';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  }
}));

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
  isValid,
  company,
  disableInputs,
  facilityCodes,
  use_list_keys,
  keysToList,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid spacing={2} container justify="center">
        <Grid item xs={12} md={9}>
          <Input
            type="text"
            value={values.email}
            onChange={handleChange}
            name="email"
            label={t('User email')}
            errors={errors}
            disabled={!!disableInputs}
          />
          <Input
            type="text"
            value={values.name}
            onChange={handleChange}
            name="name"
            label={t('Name')}
            errors={errors}
            disabled={!!disableInputs}
          />
          <Input
            type="text"
            value={values.surname}
            onChange={handleChange}
            name="surname"
            label={t('Surname')}
            errors={errors}
            disabled={!!disableInputs}
          />
          <Input
            type="text"
            value={values.company}
            onChange={handleChange}
            name="company"
            label={t('Company')}
            errors={errors}
            disabled={!!disableInputs}
          />
          <Select
            style={{ paddingTop: '8px' }}
            value={values.role}
            onChange={handleChange}
            name="role"
            label={t('Role')}
            errors={errors}
            options={[
              ...(!company ? [{ value: BUILDING_MANAGER, label: t(BUILDING_MANAGER) }]: []),
              ...(company ? [{ value: COMPANY_MANAGER, label: t(COMPANY_MANAGER) }] : []),
              ...(company ? [{ value: COMPANY_SECRETARY, label: t(COMPANY_SECRETARY) }] : []),
            ].concat([
              { value: HOST_EMPLOYEE, label: t(HOST_EMPLOYEE) },
              { value: EMPLOYEE, label: t(EMPLOYEE) },
              ...(!company ? [{ value: SECURITY, label: t(SECURITY) }] : []),
            ])}
          />
          <Grid spacing={2} item container justify="center">
            {
              !!facilityCodes && !!facilityCodes.length && (
                <Grid item xs={3}>
                  <Select
                    style={{ paddingTop: '8px' }}
                    value={values.AccessCode.facility_code}
                    onChange={handleChange}
                    name="AccessCode.facility_code"
                    label={t('Facility code')}
                    errors={errors}
                    options={
                      facilityCodes.map((fc) => ({ label: fc, value: fc }))
                    }
                    disabled={facilityCodes.length === 1}
                  />
                </Grid>
              )
            }
            <Grid item xs={facilityCodes && facilityCodes.length ? 9 : 12}>
            {use_list_keys ? (
              <AutoComplete
                defaultValue={values.AccessCode.key}
                onChange={(e, value) => {
                  const event = e
                  event.target.name = "AccessCode.key"
                  event.target.value = value.value
                  handleChange(event);
                }}
                type="text"
                name="AccessCode.key"
                label={t('Access code')}
                errors={errors}
                options={
                  keysToList.map((key) => ({ label: key, value: key }))
                }
                disabled={!keysToList?.length}
              />
            ) : (
              <Input
                type="text"
                value={values.AccessCode.key}
                onChange={handleChange}
                name="AccessCode.key"
                label={t('Access code')}
                errors={errors}
              />
            )}  
            </Grid>
          </Grid>
          <Button
            className="mt-3"
            variant="contained"
            color="default"
            type="submit"
            disabled={isSubmitting || !isValid}
            fullWidth
          >
            {t('Add user')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

const RenderSearchForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
  isValid,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid spacing={2} container justify="center">
        <Grid item xs={9}>
          <Input
            type="text"
            value={values.email}
            onChange={handleChange}
            name="email"
            label={t('User email')}
            errors={errors}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            className="mt-3"
            variant="contained"
            color="default"
            type="submit"
            disabled={isSubmitting || !isValid}
            fullWidth
          >
            {t('next')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const RenderModalUnlink = ({
  unlinkKeyModal,
  setUnlinkKeyModal,
  setLoading,
  loading,
  setAnchorEl,
  anchorEl,
  onUnlinkKeys,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const onUnlickButtonClick = async () => {
    try {
      setAnchorEl(null);
      setLoading(true);
      await onUnlinkKeys(unlinkKeyModal.info.id);
      const {error, info} = await onSubmit(unlinkKeyModal.values);
      if(error){
        setUnlinkKeyModal({...unlinkKeyModal, show: true, info});
        setLoading(false);
      }
    } catch (err) {
      if (err.response && err.response.data.errors) {
        toast.error(err.response.data.errors);
      }
    }
    setLoading(false)
  }

  return (
    <Modal visible={unlinkKeyModal.show} onClose={() => setUnlinkKeyModal({ ...unlinkKeyModal, show: false })}>
      <div className="modal-header">
        <h5 className="modal-title">{t('Access code linked to another user')}</h5>
      </div>
      <div className="modal-body">
        {t('This access code is associated with:')}
        <div style={{marginTop: 20}}><b>{t('Name')}</b> {unlinkKeyModal?.info?.name}</div>
        <div><b>{t('Surname')}</b> {unlinkKeyModal?.info?.surname}</div>
        <div><b>{t('Companies')}</b> {unlinkKeyModal?.info?.companies?.join(', ')}</div>
        <div><b>{t('Email')}</b> {unlinkKeyModal?.info?.email}</div>
        {loading && (<LinearProgress style={{ marginTop: 15 }} />)} 
      </div>
      <div className="modal-footer">
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          variant="text"
          color="secondary"
          disabled={loading}
        >
          {t('Associate with new user')}
        </Button>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box p={2}>
            <b>{t('Are you sure about that?')}</b>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
              <Button
                style={{ height: 25 }}
                onClick={(e) => {
                  setAnchorEl(null);
                  setUnlinkKeyModal({ ...unlinkKeyModal, show: false })
                }}
                variant="text"
                color="primary"
                disabled={loading}
              >
                {t('No')}
              </Button>
              <Button
                style={{ height: 25 }}
                onClick={onUnlickButtonClick}
                variant="contained"
                color="secondary"
                disabled={loading}
              >
                {t('Yes')}
              </Button>
            </div>
          </Box>
        </Popover>
        <Button
          onClick={() => setUnlinkKeyModal({ ...unlinkKeyModal, show: false })}
          variant="text"
          color="primary"
          disabled={loading}
        >
          {t('Return')}
        </Button>
      </div>
    </Modal>
  );
};

export default ({ building, company, user, email, searched, searchUser, onSubmit, isLoading, onUnlinkKeys, keysToList }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [unlinkKeyModal, setUnlinkKeyModal] = React.useState({show: false, info: {}, values: {}});
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  if (isLoading) {
    return null;
  }

  const title = company
    ? `${t('Add user to company')}: "${company.name}"`
    : `${t('Add user to building')}: "${building.name}"`;


  const defaultFacilityCode = building.facility_codes && building.facility_codes.length
    ? building.facility_codes[0]
    : '';

  let initialValues = {};

  if (!user && !searched) {
    initialValues = { email: '' };
  }

  if (!user && searched) {
    initialValues = {
      id: 0,
      email: email || '',
      name: '',
      surname: '',
      company: company ? company.name : '',
      company_role: '',
      role: EMPLOYEE,
      AccessCode: {
        key: keysToList ? keysToList[Math.floor(Math.random() * keysToList.length)] : '',
        facility_code: defaultFacilityCode
      },
    };
  }

  if (!!user) {
    initialValues = {
      id: user.id || 0,
      email: user.email || '',
      name: user.name || '',
      surname: user.surname || '',
      company: user.company || '',
      company_role: user.company_role || '',
      role: EMPLOYEE,
      // bien merecida
      AccessCode: {
        key: user.AccessCode ? user.AccessCode.key : (keysToList ? keysToList[Math.floor(Math.random() * keysToList.length)] : ''),
        facility_code: user.AccessCode
          ? (user.AccessCode.facility_code || defaultFacilityCode)
          : defaultFacilityCode
      },
    };
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h6" align="center">
          {title}
        </Typography>
        {
          !user && !searched && (
            <Formik
              initialValues={initialValues}
              validationSchema={RequestPwdRecoverySchema}
              enableReinitialize={true}
              onSubmit={
                async (values, { setSubmitting, setErrors, resetForm }) => {
                  try {
                    await searchUser(values);
                  } catch (err) {
                    if (err.response && err.response.data.errors) {
                      setErrors(err.response.data.errors);
                    }

                    setSubmitting(false);
                  }
                }
              }
              render={p => <RenderSearchForm {...p} {...{ t, company }}/>}
            />
          )
        }

        {
          !user && searched && (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={AddUserSchema}
                enableReinitialize={true}
                onSubmit={
                  async (values, { setSubmitting, setErrors, resetForm }) => {
                    try {
                      const {error, info} = await onSubmit(values);

                      if(error){
                        setUnlinkKeyModal({show: true, info, values});
                        setSubmitting(false);
                      }
                    } catch (err) {
                      if (err.response && err.response.data.errors) {
                        setErrors(err.response.data.errors);
                      }

                      setSubmitting(false);
                    }
                  }
                }
                render={p => <RenderForm {...p} {...{ t, company, facilityCodes: building.facility_codes, use_list_keys: !!building.config.use_list_keys, keysToList  }}/>}
              />
            </>
          )
        }
        {
          !!user && (
            <Formik
              initialValues={initialValues}
              validationSchema={AddUserSchema}
              enableReinitialize={true}
              isInitialValid={AddUserSchema.isValidSync(initialValues)}
              onSubmit={
                async (values, { setSubmitting, setErrors, resetForm }) => {
                  try {
                    const {error, info} = await onSubmit(values);

                    if(error){
                      setUnlinkKeyModal({show: true, info, values});
                      setSubmitting(false);
                    }
                  } catch (err) {
                    if (err.response && err.response.data.errors) {
                      setErrors(err.response.data.errors);
                    }

                    setSubmitting(false);
                  }
                }
              }
              render={p => <RenderForm {...p} {...{ t, company, disableInputs: true, facilityCodes: building.facility_codes, use_list_keys: !!building.config.use_list_keys, keysToList }}/>}
            />
          )
        }
      </Paper>
      <RenderModalUnlink 
        loading={loading}
        setLoading={setLoading}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        onSubmit={onSubmit}
        onUnlinkKeys={onUnlinkKeys}
        setUnlinkKeyModal={setUnlinkKeyModal}
        unlinkKeyModal={unlinkKeyModal}
      />
    </>
  );
}
