import * as React from 'react';
import * as _ from 'lodash';
import * as moment from 'moment';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { withTranslation } from 'react-i18next';
import MeetingEdit from '../../components/Meetings/edit';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';
import * as MeetingsApi from '../../api/meetings';

class MeetingEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      meetingId: this.props.match.params.meetingId,
      meeting: null,
      isLoading: true,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.addInvitation = this.addInvitation.bind(this);
    this.removeInvitation = this.removeInvitation.bind(this);
  }

  async onSubmit(body) {
    await MeetingsApi.update(this.state.meetingId, _.pick(body, ['subject', 'notes', 'date_from', 'date_to', 'announce_arrival']));

    toast.success(this.props.t('The meeting has been modified'));
    this.fetchMeeting();
  }

  async fetchMeeting() {
    const { data } = await MeetingsApi.get(this.state.meetingId);

    this.setState({
      meeting: {
        ...data,
        duration: moment(data.date_to).diff(moment(data.date_from), 'hours'),
        date_from: {
          date: moment(data.date_from).toDate(),
          time: moment(data.date_from).toDate(),
        },
      },
      isLoading: false,
    });
  }

  async addInvitation(body) {
    const { data } = await MeetingsApi.addInvitation(this.state.meetingId, body);

    toast.success(this.props.t('The meeting has been modified'));

    this.setState({
      meeting: data,
    });
  }

  async removeInvitation(id) {
    const onConfirmClick = async () => {
      const { data } = await MeetingsApi.removeInvitation(this.state.meetingId, id);

      toast.success(this.props.t('The meeting has been modified'));

      this.setState({
        meeting: data,
      });
    };

    confirmAlert({
      message: this.props.t('Remove guest from the meeting?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  render() {
    return (
      <MeetingEdit
        meeting={this.state.meeting}
        isLoading={this.state.isLoading}
        onSubmit={this.onSubmit}
        addInvitation={this.addInvitation}
        removeInvitation={this.removeInvitation}
      />
    );
  }

  async componentDidMount() {
    this.fetchMeeting();
  }
}

export default withTranslation()(MeetingEditContainer);