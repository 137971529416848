import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Grid,
  Button,
  Paper,
  MuiThemeProvider,
} from '@material-ui/core';
import theme from '../../theme';

export default ({ onClose, message, onConfirmClick }) => {
  const { t } = useTranslation();

  return (
    <MuiThemeProvider theme={theme}>
      <Paper style={{ padding: '20px' }}>
        <Grid container spacing={3} justify="center" direction="row" alignItems="center" style={{ padding: '20px 0' }}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              {message}
            </Typography>
            <hr />
          </Grid>
          <Grid xs={6} item style={{ textAlign: 'center' }}>
            <Button
              type="button"
              style={{ textTransform: 'lowercase' }}
              variant="contained"
              color="primary"
              onClick={() => {
                onConfirmClick();
                onClose();
              }}
            >
              {t('Yes')}
            </Button>
          </Grid>
          <Grid xs={6} item style={{ textAlign: 'center' }}>
            <Button
              type="button"
              variant="outlined"
              style={{ borderColor: 'red', textTransform: 'lowercase', color: 'red' }}
              color="secondary"
              onClick={onClose}
            >
              {t('No')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </MuiThemeProvider>

  );
}
