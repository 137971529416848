import * as React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Button,
  Typography,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Input, Select } from '../Shared/BsInput';
import {
  BUILDING_MANAGER,
  EMPLOYEE,
  HOST_EMPLOYEE,
  SECURITY,
} from '../../constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  }
}));

export default ({ building, company, user, removeUser, isLoading }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (isLoading) {
    return null;
  }

  const backUrl = company
    ? `/app/buildings/${company.building_id}/companies/${company.id}/users`
    : `/app/buildings/${building.id}/users`;
  const addUrl = company
    ? `/app/buildings/${company.building_id}/companies/${company.id}/users/add/${user.email}`
    : `/app/buildings/${building.id}/users/add/${user.email}`;
  const title = company
    ? `${t('Roles in company')}: "${company.name}"`
    : `${t('Roles in the building')}: "${building.name}"`;

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div className="pull-left">
            <Link to={backUrl}>
              <i className="fa fa-share fa-flip-horizontal"></i>
            </Link>
          </div>
          <Typography variant="h6" align="center">
            {title}
          </Typography>
          <div className="text-right">
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={addUrl}
            >
              {t('Add new role')}
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{t('Name')}</TableCell>
                <TableCell>{user.name} {user.surname}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('Email')}</TableCell>
                <TableCell>{user.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('Cellphone')}</TableCell>
                <TableCell>{user.cellphone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('Access code')}</TableCell>
                <TableCell>{user.AccessCode ? user.AccessCode.key : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('Facility code')}</TableCell>
                <TableCell>{user.AccessCode ? (user.AccessCode.facility_code || '-') : '-'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('Role')}</TableCell>
                <TableCell>{t('Company')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                (user.Roles.filter((ub) => !!company ? !!ub.Company : true)).map((ub, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{t(ub.role)}</TableCell>
                      <TableCell>{ub.Company ? ub.Company.name : '-'}</TableCell>
                      <TableCell>
                        <Button
                          type="button"
                          size="small"
                          variant="outlined"
                          color="default"
                          onClick={() => removeUser(ub.id)}
                        >
                          {t('Remove')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Paper>
  );
}
