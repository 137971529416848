import * as React from 'react';
import { useEffect, useState } from 'react';
import QrCode from 'qrcode.react';
import { sseListen } from '../../../api';

export default ({accessCode, ssePath}) => {
  const [code, setCode] = useState(null);

  useEffect(() => {
    const {close} = sseListen({
      path: ssePath,
      onData: code => {
        setCode(code);
      },
      onError: () => {
        close();
      }
    });

    return () => close();
  }, []);

  if (!accessCode || !code) {
    return <div/>;
  }

  return <QrCode value={code}/>;
}
