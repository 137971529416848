import request from './index';

export function listReceivedInvitations(params) {
  return request({
    params,
    method: 'GET',
    path: '/api/invitations/received',
  });
}

export function listTodayInvitations(params) {
  return request({
    params,
    method: 'GET',
    path: '/api/invitations/today',
  });
}

export function get(id) {
  return request({
    method: 'GET',
    path: `/api/invitations/${id}`,
  });
}

export function confirm(id) {
  return request({
    method: 'PUT',
    path: `/api/invitations/${id}/confirm`,
  });
}

export function reject(id) {
  return request({
    method: 'PUT',
    path: `/api/invitations/${id}/reject`,
  });
}

export function cancel(id) {
  return request({
    method: 'PUT',
    path: `/api/invitations/${id}/cancel`,
  });
}

export function announceArrival(id) {
  return request({
    method: 'PUT',
    path: `/api/invitations/${id}/announce-arrival`,
  });
}
