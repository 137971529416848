import * as React from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { withTranslation } from 'react-i18next';
import SecretariesCreate from '../../components/Secretaries/form';
import * as SecretariesApi from '../../api/secretaries';

class SecretariesCreateContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      secretary: {
        building_id: '',
        username: '',
      },
      isLoading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    await SecretariesApi.create(_.pick(body, ['building_id', 'username']));

    toast.success(this.props.t('The user has been added as a secretary'));

    this.props.history.push('/app/secretaries');
  }

  render() {
    return (
      <SecretariesCreate
        buildings={this.props.buildings}
        secretary={this.state.secretary}
        isLoading={this.state.isLoading}
        onSubmit={this.onSubmit}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  buildings: state.buildings.records.filter((b) => b.permissions.invite),
});

export default withTranslation()(connect(mapStateToProps)(SecretariesCreateContainer));
