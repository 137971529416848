import React from 'react';
import Box from '@material-ui/core/Box';

export default ({ company }) => {
  if (!company) {
    return null;
  }

  const { logo, layout } = company;

  if (!logo) {
    return null;
  }

  return (
    <Box textAlign={layout === 'right_logo' ? 'right' : 'left'}>
      <img src={logo} />
    </Box>
  );
}
