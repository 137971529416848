import * as React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from '../Shared/infinite-scroll';
import { Typography, Grid } from '@material-ui/core';
import MeetingCard from './meeting-card';
import InvitationCard from '../Invitations/invitation-card';
import CreateButton from './create-floating-button';

export default ({ user, onPageChange, total, pages, page, records, confirmInvitation, cancelInvitation, cancelMeeting }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      {
        !!user.host &&
        <CreateButton />
      }
      <InfiniteScroll
        dataLength={total}
        next={() => onPageChange({ page: (page.meetings > page.invitations ? page.meetings : page.invitations) + 1 })}
        hasMore={page.invitations < pages.invitations || page.meetings < pages.meetings}
        loader={<h4>Loading...</h4>}
        endMessage={
          <Grid item xs={12}>
            <Typography align="center" color="primary" component="div">
              {t('There are no more records')}
            </Typography>
          </Grid>
        }
        style={{ paddingBottom: '10%' }}
      >
        <Grid item container spacing={2}>
          {
            records.map((weekObj, i) => {
              return (
                <React.Fragment key={i}>
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ color: '#575757', fontWeight: 'bold' }}>{weekObj.week}</Typography>
                  </Grid>
                  {
                    weekObj.weekly.map((dayObj, ii) => {
                      return (
                        <React.Fragment key={ii}>
                          <Grid item xs={1}>
                            <Typography>{dayObj.day}</Typography>
                          </Grid>
                          <Grid item xs={11}>
                            {
                              dayObj.records.map((record, iii) => {
                                if (record.type === 'INVITATION') {
                                  return (
                                    <InvitationCard key={iii} invitation={record} {...{ confirm: confirmInvitation, cancel: cancelInvitation }} />
                                  );
                                }

                                return (
                                  <MeetingCard key={iii} meeting={record} {...{ cancel: cancelMeeting }} />
                                );
                              })
                            }
                          </Grid>
                        </React.Fragment>
                      );
                    })
                  }
                </React.Fragment>
              );
            })
          }
        </Grid>
      </InfiniteScroll>
    </Grid>
  );
}
