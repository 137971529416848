import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import AccessCode from '../Shared/AccessCode/access-code-factory';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundImage: 'url("/badge-background.png")',
    backgroundPosition: 'top left',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    padding: '20px 20px',
    minHeight: '80vh',
    marginBottom: '15%',
  },
  logo: {
    '& img': {
      maxWidth: '100px',
    },
    marginBottom: '15%',

    [theme.breakpoints.down('sm')]: {
      marginBottom: '30%',
    },

    [theme.breakpoints.down('xs')]: {
      marginBottom: '15%',
    },
  },
  profilePictureContainer: {
    textAlign: 'center',
    position: 'relative',
    margin: '0 auto',
  },
  profilePicture: {
    borderRadius: '50%',
    width: '15vw',
    height: '15vw',
    margin: 'auto',
    border: '10px solid #fff',
    backgroundPosition: 'center',
    backgroundSize: 'cover',

    [theme.breakpoints.down('md')]: {
      width: '20vw',
      height: '20vw',
    },

    [theme.breakpoints.down('sm')]: {
      width: '30vw',
      height: '30vw',
    },

    [theme.breakpoints.down('xs')]: {
      width: '45vw',
      height: '45vw',
    },
  },
  information: {
    '& h5': {
      fontWeight: 'bold',
    }

  },
  code: {
    marginTop: '20px',
    textAlign: 'center',
  },
}));

export default ({
                  user, params, accessCode, children, header, backgroundImage
                }) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const url = user.profile_picture || '/profile-placeholder.png';
  const style = {};

  if (backgroundImage) {
    style.backgroundImage = `url(${backgroundImage})`;
  }

  return (
    <Paper className={classes.paper} style={style}>
      <div className={classes.logo}>
        {
          !!header &&
          <>{header}</>
        }
        {
          !header && <img
            src="https://podesubir-public.s3-sa-east-1.amazonaws.com/podesubir-logo-green-gray.png"
            alt="logo"/>
        }

      </div>
      <div className={classes.profilePictureContainer}>
        <div className={classes.profilePicture}
             style={{backgroundImage: `url(${url}?w=300&h=300)`}}>
        </div>
      </div>
      <div className={classes.information}>
        <Typography align="center" variant="h5" gutterBottom={false}>
          {user.name} {user.surname}
        </Typography>
        <Typography align="center" variant="body2">
          {user.company_role ? `${user.company_role}, ${user.company}` : user.company}
        </Typography>
      </div>
      {
        accessCode &&
        <div className={classes.code}>
          <AccessCode
            accessCode={accessCode}
            params={params}
          />
        </div>
      }
      {children}
    </Paper>
  );
}
