import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import TakeImageDialog from '../../Shared/take-image-dialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    boxShadow: 'none',
  },
  center: {
    textAlign: 'center',
  },
  img: {
    maxWidth: '30%',
    border: '1px solid #a7a7a7',
    cursor: 'pointer',
  },
  right: {
    textAlign: 'right',
  },
  margin: {
    marginTop: theme.spacing(2),
  },
}));

export default ({ onSubmit, user, handleNext, handleBack }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [opened, setOpened] = React.useState(false);

  const setOpen = () => setOpened(true);
  const setClosed = () => setOpened(false);

  const src = user.profile_picture ? user.profile_picture : '/profile-placeholder.png';
  const canUserContinue = !!user.profile_picture;

  return (
    <Paper square className={classes.paper}>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} sm={12} md={10}>
          <div className="alert alert-info">
            - {t('Your face must be visible and centered in the picture frame')}
            <br />
            - {t('You must not be wearing a facemask or anything that partially covers your face')}
            <br />
            - {t('No other people should be visible in the picture')}
            <br />
            - {t('The picture must match the picture in the ID documents from the next step')}
          </div>
        </Grid>
      </Grid>
      <div className={classes.center}>
        <img
          src={src}
          alt="profile"
          className={classes.img}
          onClick={setOpen}
        />
        <div>
          <Button
            type="button"
            size="small"
            onClick={setOpen}
            variant="outlined"
            className={classes.margin}
          >
            {t('Take picture')}
          </Button>
        </div>
      </div>

      <TakeImageDialog
        onConfirm={onSubmit}
        handleClose={setClosed}
        opened={opened}
      />
      <hr/>
      <div className={classes.right}>
        <Button
          type="button"
          color="default"
          variant="outlined"
          size="small"
          onClick={handleBack}
        >
          {t('Back')}
        </Button>
        &nbsp;
        <Button
          color="primary"
          variant="contained"
          type="button"
          size="small"
          onClick={handleNext}
          disabled={!canUserContinue}
        >
          {t('Continue')}
        </Button>
      </div>
    </Paper>
  );
}