import * as React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';

const styles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    right: '20px',
    bottom: '10%',
    zIndex: '10000',
  },
  fab: {
    fontSize: '1.3rem',

    '& a': {
      color: '#fff',
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
}));

export default () => {
  const classes = styles();

  return (
    <div className={classes.root}>
      <Link style={{ textDecoration: 'none' }} to="/app/meetings/create" >
        <Fab color="secondary" className={classes.fab} size="large">
          <i className="fa fa-plus"></i>
        </Fab>
      </Link>
    </div>
  )
}