import * as React from 'react';
import { Grid } from '@material-ui/core';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';

export default ({ data }) => {
  const { t } = useTranslation();
  const [filters, setFilters] = React.useState({});

  React.useEffect(() => {
    console.log(data)
    setFilters({
      invitations: false,
      users: false,
      approved: false,
      completed: false,
    });
  }, [data]);

  return (
    <>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <div style={{ maxWidth: '100%' }}>
            <MaterialTable
              style={{width: '100%', marginRight: '-1080px', height: "100%", overflowY: "scroll"}}
              columns={[
                { title: t('Host name'), field: 'Host_name'},
                { title: t('Host surname'), field: 'Host_surname', cellStyle: { whiteSpace: 'nowrap' }},
                { title: t('Host company'), field: 'Company_host', cellStyle: { whiteSpace: 'nowrap' } },
                { title: t('Host email'), field: 'Host_email' },
                { title: t('Guest name'), field: 'Guest_name'},
                { title: t('Guest surname'), field: 'Guest_surname', cellStyle: { whiteSpace: 'nowrap' }},
                { title: t('Badge number'), field: 'Guest_access_code' },
                { title: t('Guest company'), field: 'Company_guest', cellStyle: { whiteSpace: 'nowrap' }},
                { title: t('Date from'), field: 'Date_from', render: (d) => moment(d.Date_from).format(t('Date and time format')),
                  cellStyle: { whiteSpace: 'nowrap' }},
                { title: t('Date to'), field: 'Date_to', render: (d) => moment(d.Date_to).format(t('Date and time format')),
                  cellStyle: { whiteSpace: 'nowrap' }},
                { title: t('Guest document'), field: 'Guest_document'},
                { title: t('Guest CPF'), field: 'Guest_cpf' },
                { title: t('Guest phone'), field: 'Guest_phone'},
                { title: t('Guest email'), field: 'Guest_email'},
              ]}
              data={data}
              options={{
                exportButton: true,
                pageSize: data.length < 100 ? data.length : 100,
                pageSizeOptions: [100, 200, 500],
                headerStyle: { textTransform: 'lowercase' }
              }}
              title=""
            />
          </div>
        </Grid>
      </Grid>
    </>
  )
}