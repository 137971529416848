import * as React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';

export default ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <div style={{ maxWidth: '100%' }}>
            <MaterialTable
              style={{width: '100%', marginRight: '-1080px', height: "100%", overflowY: "scroll"}}
              columns={[
                { title: t('name'), field: 'name'},
                { title: t('surname'), field: 'surname', cellStyle: { whiteSpace: 'nowrap' }},
                { title: t('company'), field: 'company_name', cellStyle: { whiteSpace: 'nowrap' } },
                { title: t('email'), field: 'email' },
                { title: t('Access code'), field: 'key'},
                { title: t('Role'), field: 'role' },
              ]}
              data={data}
              options={{
                exportButton: true,
                pageSize: data.length < 100 ? data.length : 100,
                pageSizeOptions: [100, 200, 500],
                headerStyle: { textTransform: 'lowercase' }
              }}
              title=""
            />
          </div>
        </Grid>
      </Grid>
    </>
  )
}