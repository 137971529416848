import * as React from 'react';
import { connect } from 'react-redux';
import DashboardComponent from '../../components/Dashboard';
import * as BuildingsApi from '../../api/buildings';

class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      building: null,
      company: null,
    };
  }

  onCompanyChange = (companyId) => {
    const company = this.state.building.Companies.find((c) => c.id === parseInt(companyId, 10));

    if (!company) {
      return;
    }

    this.setState({ company });

    localStorage.setItem('dashboard-selected-company', companyId);
  }

  onBuildingChange = (buildingId) => {
    const building = this.props.buildings.find(b => b.id === parseInt(buildingId, 10));

    if (!building) {
      return;
    }

    this.setState({ building });

    localStorage.setItem('dashboard-selected-building', buildingId);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.buildings && props.buildings.length && !state.building) {
      const prevBuilding = localStorage.getItem('dashboard-selected-building');
      const prevCompany = localStorage.getItem('dashboard-selected-company');

      if (prevBuilding) {
        const building = props.buildings.find((b) => b.id === parseInt(prevBuilding, 10));

        if (building) {
          if (prevCompany) {
            const company = building.Companies.find((c) => c.id === parseInt(prevCompany, 10));

            if (company) {
              return {
                building,
                company,
              };
            }
          }

          return {
            building,
            company: building.Companies.length ? building.Companies[0] : null,
          };
        }
      }

      return {
        building: props.buildings[0],
        company: props.buildings[0].Companies.length ? props.buildings[0].Companies[0] : null,
      };
    }

    return null;
  }

  render() {
    return (
      <DashboardComponent
        buildings={this.props.buildings}
        isLoading={this.state.isLoading}
        user={this.props.user}
        building={this.state.building}
        company={this.state.company}
        onCompanyChange={this.onCompanyChange}
        onBuildingChange={this.onBuildingChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  buildings: state.buildings.records.filter((b) => b.permissions.access),
});

export default connect(mapStateToProps)(DashboardContainer);
