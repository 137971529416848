import request from './index';

export function get(id) {
  return request({
    method: 'GET',
    path: `/api/companies/${id}`,
  });
}

export function list(params) {
  return request({
    params,
    method: 'GET',
    path: '/api/companies',
  });
}

export function listUsers(companyId, params) {
  return request({
    params,
    method: 'GET',
    path: `/api/companies/${companyId}/users`,
  });
}

export function listMainCompanies() {
  return request({
    method: 'GET',
    path: '/api/companies/main-companies',
  });
}
