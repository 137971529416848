import * as React from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Button, Typography, Grid, Paper, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Input } from '../Shared/BsInput';
import { RequestPwdRecoverySchema } from '../../validation-schemas';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '10%',

    [theme.breakpoints.up('sm')]: {
      marginTop: '2%',
    },
  },
  paper: {
    padding: '10px',
    paddingBottom: '20px',
    marginBottom: '10px',
  },
  title: {
    marginBottom: '20px',
  },
}));

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className="alert alert-info text-center">
        {t('After you submit we will send a verification link to this new email address. The change will only be effective after you have verified this new email address.')}
      </div>

      <Input
        type="email"
        value={values.email}
        onChange={handleChange}
        name="email"
        label={t('New email')}
        errors={errors}
      />
      <hr />
      <Button
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting}
        fullWidth
      >
        {t('Change email')}
      </Button>
    </form>
  );
};

export default ({ onSubmit }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={10} lg={6} className={classes.root}>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" align="center" className={classes.title}>
            {t('Change email')}
            <div className="pull-right">
              <Link to="/app/account-configuration">
                <i className="fa fa-share fa-flip-horizontal"></i>
              </Link>
            </div>
          </Typography>

          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={RequestPwdRecoverySchema}
            enableReinitialize={true}
            onSubmit={
              async (values, { setSubmitting, setErrors }) => {
                try {
                  await onSubmit(values);
                } catch (err) {
                  if (err.response && err.response.data.errors) {
                    setErrors(err.response.data.errors);
                  }

                  setSubmitting(false);
                }
              }
            }
            render={p => <RenderForm {...p} {...{ t }}/>}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}