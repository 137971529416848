import * as React from 'react';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import BuildingsAddUser from '../../components/Buildings/add-user';
import * as BuildingsApi from '../../api/buildings';

class AddUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      buildingId: this.props.match.params.buildingId,
      companyId: this.props.match.params.companyId,
      email: this.props.match.params.email,
      company: null,
      building: null,
      isLoading: true,
      user: null,
      searched: false,
      keys_to_list: [],
    };
  }

  onSubmit = async (body) => {
    const searchUsers = await BuildingsApi.searchUserByKey(this.state.buildingId, { excludedId: body.id, key: body.AccessCode.key });
    if(searchUsers?.data?.exist)  return {error: true, info: searchUsers.data};

    const { data } = await BuildingsApi.addUser(this.state.buildingId, {
      ...body,
      AccessCode: { ...body.AccessCode, key: body.AccessCode.key.toString() },
      company_id: this.state.companyId,
    });

    toast.success(this.props.t('The user has been added to the building'));
    this.props.history.push(`/app/buildings/${this.state.buildingId}/companies/${this.state.companyId}/users/edit/${data.id}`);
  }

  onUnlinkKeys = async (userToUnlink) => {
    const request = await BuildingsApi.removeRoles(this.state.buildingId, userToUnlink);

    toast.success(
      this.props.t('Unlinked user')
    );
  };

  searchUser = async (body) => {
    const { data } = await BuildingsApi.searchUser(this.state.buildingId, body);

    this.setState({
      user: data[0] || null,
      searched: true,
      email: body.email,
    });
  }

  render() {
    return (
      <BuildingsAddUser
        company={this.state.company}
        building={this.state.building}
        isLoading={this.state.isLoading}
        onSubmit={this.onSubmit}
        searchUser={this.searchUser}
        email={this.state.email}
        user={this.state.user}
        searched={this.state.searched}
        onUnlinkKeys={this.onUnlinkKeys}
        keysToList={this.state.keys_to_list}
      />
    );
  }

  async componentDidMount() {
    const res = await Promise.all([
      BuildingsApi.getCompany(this.state.buildingId, this.state.companyId),
      BuildingsApi.get(this.state.buildingId),
    ]);

    if (this.state.email) {
      await this.searchUser({ email: this.state.email });
    }

    let keys_to_list = [];
    if(res[1].data?.config?.use_list_keys){ 
      ({ data: keys_to_list } = await BuildingsApi.listKeys(this.state.buildingId));
    }

    this.setState({
      company: res[0].data,
      building: res[1].data,
      isLoading: false,
      keys_to_list,
    });
  }
}

export default withTranslation()(AddUser);
