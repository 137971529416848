import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';
import MeetingList from '../../components/Meetings/sent';
import * as MeetingsApi from '../../api/meetings';
import { groupByDate } from '../../logic';

class InvitationsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      meetings: [],
      pages: 0,
      page: 1,
      filter: {
        name: '',
        surname: '',
        email: ''
      }
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this)
  }

  async onPageChange({ page }) {
    const { data, headers } = await MeetingsApi.listSent({
      ...this.state.filter,
      limit: 10,
      offset: (page - 1) * 10,
    });

    const pages = Math.ceil(parseInt(headers['x-total-count'], 10) / 10);
    
    this.setState({
      meetings: page === 1 ? data : this.state.meetings.concat(data),
      pages,
      page: data?.length < 10 ? pages : page,
    });
  }

  async cancel(invidationId) {
    const onConfirmClick = async () => {
      await MeetingsApi.cancel(invidationId);

      toast.success(this.props.t('The meeting has been cancelled'));
      this.setState({ meetings: [] });
      this.onPageChange({ page: 1 });
    };

    confirmAlert({
      message: this.props.t('Cancel meeting?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  async onChangeFilter(filter){
    await this.setState({ filter })
  }

  async componentDidMount() {
    this.onPageChange({ page: 1 });
  }

  render() {
    const { i18n } = this.props;
    const language = i18n.language.substr(0, 2);

    const dataset = groupByDate(this.state.meetings, language);

    return (
      <MeetingList
        meetings={dataset}
        total={this.state.meetings.length}
        pages={this.state.pages}
        page={this.state.page}
        onPageChange={this.onPageChange}
        onChangeFilter={this.onChangeFilter}
        cancel={this.cancel}
      />
    );
  }
}

export default withTranslation()(InvitationsContainer);