import * as React from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Button, Typography, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Password } from '../Shared/BsInput';
import { ChangePwdSchema } from '../../validation-schemas';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
  redirected,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      {
        redirected &&
        <div className="alert alert-info text-center">
          {t('For security reasons we encourage you to change your password.')}
        </div>
      }

      <Password
        value={values.password}
        onChange={handleChange}
        name="password"
        label={t('New password')}
        errors={errors}
      />
      <Password
        value={values.password_confirm}
        onChange={handleChange}
        name="password_confirm"
        label={t('Confirm new password')}
        errors={errors}
      />
      <hr />
      <Button
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting}
        fullWidth
      >
        {t('Change password')}
      </Button>
    </form>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '10%',

    [theme.breakpoints.up('sm')]: {
      marginTop: '2%',
    },
  },
  paper: {
    padding: '10px',
    paddingBottom: '20px',
    marginBottom: '10px',
  },
  title: {
    marginBottom: '20px',
  },
}));

export default ({ onSubmit, redirected }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={10} lg={6} className={classes.root}>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" align="center">
            {t('Change password')}
            <div className="pull-right">
              <Link to="/app/account-configuration">
                <i className="fa fa-share fa-flip-horizontal"></i>
              </Link>
            </div>
          </Typography>
          <Formik
            initialValues={{
              password: '',
              password_confirm: '',
            }}
            validationSchema={ChangePwdSchema}
            enableReinitialize={true}
            onSubmit={
              async (values, { setSubmitting, setErrors }) => {
                try {
                  await onSubmit(values);
                } catch (err) {
                  if (err.response && err.response.data.errors) {
                    setErrors(err.response.data.errors);
                  }

                  setSubmitting(false);
                }
              }
            }
            render={p => <RenderForm {...p} {...{ t, redirected }}/>}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}