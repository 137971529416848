import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import { Input } from '../../Shared/BsInput';
import { RequestPwdRecoverySchema } from '../../../validation-schemas';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    boxShadow: 'none',
  },
}));

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  isValid,
  t,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        type="email"
        value={values.email}
        onChange={handleChange}
        name="email"
        label={t('Email')}
        errors={errors}
      />
      <Button
        className="mt-2"
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting || !isValid}
        fullWidth
      >
        {t('Change email')}
      </Button>
    </form>
  );
};

export default (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { opened, handleClose, user, onSubmit } = props;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="change-email"
      open={opened}
      maxWidth="md"
      fullWidth
    >
      <div className={classes.paper}>
        <Typography>
          {t('Change email address')}
        </Typography>
        <Formik
          initialValues={{
            email: user.email
          }}
          validationSchema={RequestPwdRecoverySchema}
          enableReinitialize={true}
          onSubmit={
            async (values, { setSubmitting, setErrors }) => {
              try {
                await onSubmit(values);
              } catch (err) {
                if (err.response && err.response.data.errors) {
                  setErrors(err.response.data.errors);
                }

                setSubmitting(false);
              }
            }
          }
          render={(p) => <RenderForm {...p} {...{ t }} />}
        />
      </div>
    </Dialog>
  );
};