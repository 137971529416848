import request from './index';

export function visitors(body) {
  return request({
    body,
    method: 'POST',
    path: `/api/stats/visitors`,
  });
}

export function whoVisitedWho(body) {
  return request({
    body,
    method: 'POST',
    path: `/api/stats/who-visited-who`,
  });
}
export function employeesPerCompany(body) {
  return request({
    body,
    method: 'POST',
    path: `/api/stats/employees-per-company`,
  });
}

export function visitorsList(buildingId, body) {
  return request({
    body,
    method: 'POST',
    path: `/api/stats/visitors-list/${buildingId}`,
  });
}


export function stay(body) {
  return request({
    body,
    method: 'POST',
    path: `/api/stats/stay`,
  });
}

export function registration(body) {
  return request({
    body,
    method: 'POST',
    path: '/api/stats/registration',
  });
}

export function guardsAllowedAccess(id, body) {
  return request({
    body,
    method: 'POST',
    path: `/api/stats/guards-buildings-access/${id}`,
  });
}