import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const styles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  input: {
    display: 'none',
  },
  textRight: {
    textAlign: 'right',
  }
}));

const getCroppedImg = (image, crop, filename, type) => {
  const cropWidth = image.naturalWidth * (crop.width / 100)
  const cropHeight = image.naturalHeight * (crop.height / 100)
  const startX = image.naturalWidth * (crop.x / 100)
  const startY = image.naturalHeight * (crop.y / 100)

  const canvas = document.createElement('canvas');
  canvas.width = cropWidth
  canvas.height = cropHeight
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    startX,
    startY,
    cropWidth,
    cropHeight,
    0,
    0,
    cropWidth,
    cropHeight
  )

  // const base64Image = canvas.toDataURL();
  // return base64Image;
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      blob.name = filename;
      return resolve(blob);
    }, type, 0.85);
  });
};

const UploadImageDialog = (props) => {
  const { onConfirm, opened, handleClose, capture, btnLabel } = props;
  const { t } = useTranslation();
  const imgRef = React.useRef(null);
  const btnRef = React.useRef(null);
  const [file, setFile] = React.useState(null);
  const [preview, setPreview] = React.useState(null);
  const [crop, setCrop] = React.useState({ unit: "%", width: 100, height: 100 });
  const [completedCrop, setCompletedCrop] = React.useState(null);
  const classes = styles();

  const onClose = () => {
    setFile(null);
    setPreview(null);
    setCrop({ unit: "%", width: 100, height: 100 });
    setCompletedCrop(null);

    handleClose();
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      setFile(file);
      setPreview(reader.result);
    }

    reader.readAsDataURL(file);
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (opened && btnRef && btnRef.current) {
        btnRef.current.click();
      }
    }, 200);
  }, [opened]);

  return (
    <Dialog open={opened} onClose={onClose} fullWidth maxWidth={'sm'} scroll="body">
      <Paper className={classes.paper}>
        <div className={classes.textRight}>
          <IconButton size="small" onClick={onClose}>
            <i className="fa fa-times"></i>
          </IconButton>
        </div>
        <Button
          type="button"
          component="label"
          variant="outlined"
          ref={btnRef}
        >
          <input
            id="file-input"
            type="file"
            onChange={handleImageChange}
            capture={capture || 'user'}
            className={classes.input}
            accept="image/*"
          />
          {t('Upload new picture')}
        </Button>
        <Box textAlign="center" mt={2}>
          <img src={preview} ref={imgRef} style={{ display: 'none' }} alt="hidden" />

          {
            preview &&
            <ReactCrop
              src={preview}
              crop={crop}
              onChange={(pixel, percent) => {
                setCrop(percent)
              }}
              onComplete={(pixel, percent) => {

                setCompletedCrop(percent);
              }}
            />
          }
        </Box>
        {
          preview &&
          <Button
            type="button"
            component="label"
            variant="contained"
            color="primary"
            onClick={async () => {
              const blob = await getCroppedImg(imgRef.current, completedCrop, file.name, file.type);
              /*const previewUrl = window.URL.createObjectURL(blob);

              const anchor = document.createElement("a");
              anchor.download = "cropPreview.jpeg";
              anchor.href = URL.createObjectURL(blob);
              anchor.click();

              window.URL.revokeObjectURL(previewUrl);
              return;*/

              onConfirm(blob);
              onClose();
            }}
          >
            {
              !!btnLabel && <>
                {btnLabel}
              </>
            }
            {
              !btnLabel && (
                <>{t('Confirm and save')}</>
              )
            }
          </Button>
        }
      </Paper>
    </Dialog>
  );

};

export default UploadImageDialog;
