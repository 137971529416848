import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { updateUserData } from '../../../actions/users';
import ConfirmInformationComponent from '../../../components/Register/steps/confirm-information';
import * as AuthApi from '../../../api/auth';

class Documents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      fields: null,
    };
  }

  onSubmit = async (body = { force: false }) => {
    try {
      const { data } = await AuthApi.confirmInformation(body);

      toast.success(this.props.t('Your profile is now complete and your account verified. You can start using podesubir'));
      this.props.updateUserData(data);
    } catch (err) {
      if (!err.response) {
        console.log(err);

        return;
      }

      this.setState({
        error: err.response.data.message || err.response.data.error,
        fields: err.response.data.errors ? Object.keys(err.response.data.errors) : null,
      });
    }
  }

  render() {
    const { user, handleNext, handleBack } = this.props;

    return (
      <ConfirmInformationComponent
        user={user}
        error={this.state.error}
        fields={this.state.fields}
        onSubmit={this.onSubmit}
        handleNext={handleNext}
        handleBack={handleBack}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  updateUserData: (data) => dispatch(updateUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Documents));
