import * as React from 'react';
import { connect } from 'react-redux';
import Config from '../../components/Profile/config';

class ProfileConfig extends React.Component {

  render() {
    return <Config user={this.props.user} />
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ProfileConfig);
