import * as React from 'react';
import {
  Button,
  Typography,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NoRecords from '../Shared/NoRecords';
import ManageUsersBtn from './manager-users-button';
import ContainerCard from '../Shared/container-card';
import ListTable from '../Shared/list-table';

const RenderTable = ({ onPageChange, pages, page, companies, t }) => {
  return (
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell>{t('ID')}</TableCell>
          <TableCell>{t('Name')}</TableCell>
          <TableCell>{t('Roles')}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      }
      body={
        companies.map((company, i) => {
          return (
            <TableRow key={i}>
              <TableCell>{company.id}</TableCell>
              <TableCell>{company.name}</TableCell>
              <TableCell>
                {
                  company.Roles.map((r, i) => <div key={i}>{t(r.role)}</div>)
                }
              </TableCell>
              <TableCell>
                <Button
                  component={Link}
                  variant="contained"
                  color="primary"
                  size="small"
                  fullWidth
                  to={`/app/companies/${company.id}`}
                >
                  {t('Detail')}
                </Button>
                <ManageUsersBtn company={company} />
              </TableCell>
            </TableRow>
          );
        })
      }
    />
  );
};

export default ({ onPageChange, pages, page, companies }) => {
  const { t } = useTranslation();

  return (
    <ContainerCard
      xs={12}
      md={12}
      lg={12}
      header={
        <Typography variant="h6" align="center" style={{ marginBottom: '10px' }}>
          {t('My companies')}
        </Typography>
      }
      content={
        <React.Fragment>
          {
            !companies.length && <NoRecords />
          }
          {
            !!companies.length && <RenderTable {...{ onPageChange, pages, page, companies, t }} />
          }
        </React.Fragment>
      }
    />
  );
}
