import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
 
  return (
    <div className="alert alert-info text-center">
      {t('No records found')}
    </div>
  );
}
