import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';
import BuildingEditUser from '../../components/Buildings/edit-user';
import * as BuildingsApi from '../../api/buildings';

class EditUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buildingId: this.props.match.params.buildingId,
      userId: this.props.match.params.userId,
      building: null,
      isLoading: true,
    };

    this.removeUser = this.removeUser.bind(this);
  }

  async removeUser(id) {
    const onConfirmClick = async () => {
      await BuildingsApi.removeUser(this.state.buildingId, id);

      toast.success(this.props.t('Success'));
      if (this.state.user.Roles.length > 1) {
        this.fetchUser();
      } else {
        this.props.history.push(`/app/buildings/${this.state.buildingId}/users`);
      }

    };

    confirmAlert({
      message: this.props.t('Remove user from building?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  fetchUser = async () => {
    try {
      const { data } = await BuildingsApi.getUser(this.state.buildingId, this.state.userId);

      this.setState({ user: data });
    } catch (err) {
      console.log(err);

      this.props.history.push(`/app/buildings/${this.state.buildingId}/users`);
    }
  }

  render() {
    return (
      <BuildingEditUser
        removeUser={this.removeUser}
        building={this.state.building}
        user={this.state.user}
        isLoading={this.state.isLoading}
      />
    );
  }

  async componentDidMount() {
    const res = await Promise.all([
      this.fetchUser(),
      BuildingsApi.get(this.state.buildingId),
    ]);

    this.setState({
      building: res[1].data,
      isLoading: false,
    });
  }
}

export default withTranslation()(EditUser);
