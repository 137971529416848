import * as Api from '../api/buildings';
import { SET_BUILDINGS, SET_BUILDINGS_ACCESS_LIST } from '../constants';

export function setBuildings(data) {
  return {
    type: SET_BUILDINGS,
    payload: data,
  };
}

export function fetchBuildings() {
  return async (dispatch) => {
    const { data: buildings } = await Api.list();
    const { data: buildingsAccessList } = await Api.list({accessList: true});

    dispatch(setBuildings({ buildings, buildingsAccessList }));
  };
}
