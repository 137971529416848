import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import * as moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Countdown from 'react-countdown';
import Badge from '../Dashboard/badge';
import { createGoogleMapsLink } from '../Shared/getMapsLink';

const styles = makeStyles((theme) => ({
  header: {
    textTransform: 'uppercase',
    color: '#fff',
    width: '70%',
    float: 'left',
  },
  content: {
    marginTop: '20px',
  },
  descriptionField: {
    fontWeight: 'bold',
    textTransform: 'lowercase',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
  descriptionValue: {
    lineHeight: '1rem',

    [theme.breakpoints.up('sm')]: {
      lineHeight: '1.5rem',
      fontSize: '1.2rem',
    },
  },
}));

const StatusAnnouncedDialog = ({ invitation }) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!invitation.can_announce_arrival && invitation.announce_arrival_status === 'GUEST_NOTIFIED_ARRIVAL') {
      setOpen(true);
    }
  }, [invitation]);

  return (
    <Dialog open={open} size="md" fullWidth onClose={() => setOpen(false)}>
      <Box p={2}>
        <Typography align="center">
          <b>{t('Your arrival has been notified')}</b>
        </Typography>
        <Typography align="center">
          {t('When your access is authorized you will be able to access directly with the QR code that will appear here')}
        </Typography>
      </Box>
      <Box p={2} textAlign="center">
        <Button
          type="button"
          onClick={() => setOpen(false)}
          variant="contained"
          color="primary"
        >
          {t('Close')}
        </Button>
      </Box>
    </Dialog>
  );
};

const StatusHostDelayedDialog = ({ invitation }) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!invitation.can_announce_arrival && invitation.announce_arrival_status === 'HOST_DELAYED') {
      setOpen(true);
    }
  }, [invitation]);

  let minutes = 0;
  try {
    const last = invitation.events[invitation.events.length - 1];

    minutes = moment(last.due_date).diff(moment(last.date), 'minutes');
  } catch (e) {
    console.log(e)
  }

  return (
    <Dialog open={open} size="md" fullWidth onClose={() => setOpen(false)}>
      <Box p={2}>
        <Typography align="center">
          {t('Your host requested for you to wait {{minutes}} minutes', { minutes })}
        </Typography>
      </Box>
      <Box p={2} textAlign="center">
        <Button
          type="button"
          onClick={() => setOpen(false)}
          variant="contained"
          color="primary"
        >
          {t('Close')}
        </Button>
      </Box>
    </Dialog>
  );
};

const Timer = ({ invitation }) => {
  if (invitation.announce_arrival_status !== 'HOST_DELAYED') {
    return null;
  }

  const last = invitation.events[invitation.events.length - 1];

  return (
    <Box style={{ float: 'right' }}>
      <Typography variant="h4" style={{ color: 'white'}}>
        <Countdown
          date={last.due_date}
          renderer={({ formatted }) => {
            return `${formatted.minutes}:${formatted.seconds}`;
          }}
        />
      </Typography>
    </Box>
  );
};

export default ({ invitation, isLoading, cancel, confirm, reject, announceArrival }) => {
  if (isLoading) {
    return (<div></div>);
  };

  const { t, i18n } = useTranslation();
  const mapsLink = createGoogleMapsLink(`${invitation.Building.address}`);

  const classes = styles();
  moment.locale(i18n.language.substr(0, 2));

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={9} md={8} lg={6}>
        <Badge
          user={invitation.Guest}
          accessCode={invitation.AccessCode}
          params={{ invitation }}
          header={
            <div>
              <Typography variant="h5" className={classes.header}>
                {t('Visitor')}
              </Typography>
              <Timer invitation={invitation} />
            </div>
          }
        >
          <div className={classes.content}>
            <Grid container spacing={2} justify="center">
              <Grid item xs={3} md={2}>
                <Typography variant="body2" className={classes.descriptionField}>{t('Invited by')}</Typography>
              </Grid>
              <Grid item xs={7} md={4}>
                <Typography align="right" className={classes.descriptionValue}>
                  {invitation.Host.name} {invitation.Host.surname}
                  {
                    invitation.Host.company &&
                    <>
                      <br/>
                      {invitation.Host.company}
                    </>
                  }
                  {
                    invitation.Host.company_role &&
                    <>
                      <br />
                      {invitation.Host.company_role}
                    </>
                  }
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} justify="center">
              <Grid item xs={3} md={2}>
                <Typography variant="body2" className={classes.descriptionField}>{t('Cellphone')}</Typography>
              </Grid>
              <Grid item xs={7} md={4}>
                <Typography align="right" className={classes.descriptionValue}>
                  {invitation.Host.cellphone}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} justify="center">
              <Grid item xs={3} md={2}>
                <Typography variant="body2" className={classes.descriptionField}>{t('Meeting date')}</Typography>
              </Grid>
              <Grid item xs={7} md={4}>
                <Typography align="right" className={classes.descriptionValue}>
                  {moment(invitation.date_from).format('ddd DD MMM  HH:mm')} - {moment(invitation.date_to).format('HH:mm')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} justify="center">
              <Grid item xs={3} md={2}>
                <Typography variant="body2" className={classes.descriptionField}>{t('Building')}</Typography>
              </Grid>
              <Grid item xs={7} md={4}>
                <a href={mapsLink} target="_blank">
                  <Typography align="right" className={classes.descriptionValue}>
                    {invitation.Building.name}
                    <br />
                    {invitation.Building.address} {invitation.Company && <>{invitation.Company.floor} - {invitation.Company.room}</>}
                  </Typography>
                </a>
              </Grid>
            </Grid>
            <StatusAnnouncedDialog invitation={invitation} />
            <StatusHostDelayedDialog invitation={invitation} />
            <Grid container spacing={2} justify="center">
              <Grid item xs={12} className="text-center">
                <hr />
                {
                  invitation.can_announce_arrival && (
                    <Button
                      type="button"
                      color="primary"
                      variant="outlined"
                      size="small"
                      onClick={() => announceArrival(invitation.id)}
                    >
                      {t('Announce arrival')}
                    </Button>
                  )
                }
                &nbsp;
                {
                  invitation.can_cancel && cancel &&
                  <Button
                    type="button"
                    variant="outlined"
                    size="small"
                    onClick={() => cancel(invitation.id)}
                  >
                    {t('Cancel')}
                  </Button>
                }
                &nbsp;
                {
                  invitation.can_confirm && confirm &&
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={() => confirm(invitation.id)}
                  >
                    {t('Confirm')}
                  </Button>
                }
                &nbsp;
                {
                  invitation.can_reject && reject &&
                  <Button
                    type="button"
                    variant="outlined"
                    size="small"
                    onClick={() => reject(invitation.id)}
                  >
                    {t('Reject')}
                  </Button>
                }
              </Grid>
            </Grid>
          </div>

        </Badge>
      </Grid>
    </Grid>
  );
}
