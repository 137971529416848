import * as React from 'react';
import StaticAccessCode from './static-access-code';
import DynamicAccessCode from './dynamic-access-code';

export default ({accessCode, params}) => {
  if (!accessCode) {
    return null;
  }

  if (accessCode.type === 'STATIC') {
    return <StaticAccessCode accessCode={accessCode}/>;
  }

  if (accessCode.type === 'DYNAMIC_TOTP_ENCRYPTED') {
    const ssePath = (() => {
      if (params.building) {
        return `/api/buildings/${params.building.id}/access-code/stream`;
      } else if (params.invitation) {
        return `/api/invitations/${params.invitation.id}/access-code/stream`;
      }
    })();

    return (
      <DynamicAccessCode accessCode={accessCode} ssePath={ssePath}/>
    );
  }
}
