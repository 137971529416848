import * as React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import {
  Button,
  Typography,
  Paper,
  Grid,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChipInput from 'material-ui-chip-input';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Input, Select, Textarea, Checkbox } from '../Shared/BsInput';
import 'react-widgets/dist/css/react-widgets.css';
import { CreateInvitationSchema, isValidEmailOrCellphone } from '../../validation-schemas';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  buildings,
  t,
  isValid,
}) => {
  const building = values.building_id ? buildings.find((b) => b.id === parseInt(values.building_id, 10)) : null;
  const company = values.company_id ? building.Companies.find((c) => c.id === parseInt(values.company_id, 10)) : null;
  let showSelectBuilding = !!building && !!building.Companies.length;

  if (building && building.Companies.length && !values.company_id) {
    setFieldValue('company_id', building.Companies[0].id);
  } 

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Select
        label={t('Building')}
        name="building_id"
        value={values.building_id}
        errors={errors}
        onChange={(e) => {
          setFieldValue('represented_user_id', '__none__');
          setFieldValue('company_id', null);

          handleChange(e);
        }}
        options={
          [{
            value: '',
            label: t('Select a building'),
          }].concat(
            buildings.map((b) => {
              return { value: b.id, label: b.name };
            })
          )
        }
      />
      {
        (showSelectBuilding) && values.company_id !== null &&
        <Select
          label={t('Company')}
          name="company_id"
          value={values.company_id}
          defaultValue={values.company_id}
          errors={errors}
          onChange={handleChange}
          options={
            [{
              value: '',
              label: t('None'),
            }].concat(
              building
                .Companies
                .map((u) => {
                  return { value: u.id, label: `${u.name}` };
                })
            )
          }
        />
      }
      {
        !!(building && building.RepresentedUsers.length) &&
        <Select
          label={t('Send invitation as')}
          name="represented_user_id"
          value={values.represented_user_id}
          errors={errors}
          onChange={handleChange}
          options={
            [{
              value: '__none__',
              label: t('As myself'),
            }].concat(
              building
                .RepresentedUsers
                .map((u) => {
                  return { value: u.id, label: `${u.name} ${u.surname}` };
                })
            )
          }
        />
      }

      <Input
        type="text"
        value={values.subject}
        onChange={handleChange}
        name="subject"
        label={t('Invitation subject/reason')}
        errors={errors}
        id="meeting-subject-input"
      />
      <ChipInput
        value={values.guest_contact}
        onAdd={(chip) => {
          const inputValue = ((chip.replaceAll(' ', ',')).replaceAll(';', ',')).split(',');
          const newValues = values.guest_contact;
          for (const emailOrCell of inputValue){
            if (isValidEmailOrCellphone(emailOrCell)) newValues.push(emailOrCell);
          }
          setFieldValue('guest_contact', newValues);
        }}
        onDelete={(chip, index) => {
          setFieldValue('guest_contact', values.guest_contact.filter((e, i)  => i !== index));
        }}
        id="meeting-contact-input"
        fullWidth
        label={t('Guest\'s contact')}
        helperText={t('Multiple emails/cellphones separated by comma')}
        newChipKeyCodes={[9, 13, 188, 32]}
        margin="normal"
        blurBehavior="add"
        style={{ marginBottom: '2.5rem' }}
        error={errors && !!errors.guest_contact}
      />
      <KeyboardDatePicker
        format={'DD-MM-YYYY'}
        value={values.date_from.date}
        disablePast
        onChange={(value) => {
          console.log(value);
          setFieldValue('date_from.date', value);

          const date = moment(
            `${moment(value).format('YYYY-MM-DD')} ${moment(values.date_from.time).format('HH:mm')}`, 'YYYY-MM-DD HH:mm'
          ).add(values.duration, 'hours');

          setFieldValue('date_to', date.toDate());
        }}
        fullWidth
        margin="normal"
        style={{ marginBottom: '2.5rem' }}
        label={t('Date')}
      />
      <KeyboardTimePicker
        format={'HH:mm'}
        value={values.date_from.time}
        disablePast
        onChange={(value) => {
          if (value && value.isValid) {
            setFieldValue('date_from.time', value);

            const date = moment(
              `${moment(values.date_from.date).format('YYYY-MM-DD')} ${moment(value).format('HH:mm')}`, 'YYYY-MM-DD HH:mm'
            ).add(values.duration, 'hours');

            setFieldValue('date_to', date.toDate());
          }
        }}
        ampm={true}
        fullWidth
        margin="normal"
        style={{ marginBottom: '2.5rem' }}
        label={t('Time')}
      />
      <Select
        label={t('Allow access to the building for')}
        name="duration"
        value={values.duration}
        errors={errors}
        onChange={(e) => {
          const { value } = e.target;
          const date = moment(
            `${moment(values.date_from.date).format('YYYY-MM-DD')} ${moment(values.date_from.time).format('HH:mm')}`, 'YYYY-MM-DD HH:mm'
          ).add(value, 'hours');

          setFieldValue('date_to', date.toDate());
          handleChange(e);
        }}
        options={[
          { value: 1, label: t('1 hour') },
          { value: 2, label: t('2 hours') },
          { value: 3, label: t('3 hours') },
          { value: 5, label: t('5 hours') },
          { value: 12, label: t('12 hours') },
        ]}
      />
      {
        !!company && company.allow_arrival_announcement && (
          <Box pt={2}>
            <Checkbox
              name="announce_arrival"
              value={values.announce_arrival}
              onChange={handleChange}
              style={{ paddingLeft: 0 }}
            />&nbsp;
            <Typography component="span">
              {t('Request the guest to announce it\'s arrival to be allowed in')}
            </Typography>
          </Box>
        )
      }
      <Textarea
        value={values.notes}
        onChange={handleChange}
        name="notes"
        label={t('Notes')}
        errors={errors}
      />
      <hr />
      <Button
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting || !isValid}
        fullWidth
      >
        {t('Create meeting')}
      </Button>
    </form>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '10%',

    [theme.breakpoints.up('sm')]: {
      marginTop: '2%',
    },
  },
  paper: {
    padding: '10px',
    paddingBottom: '20px',
    marginBottom: '10px',
  },
}));

export default (props) => {
  const { onSubmit, isLoading, buildings, meeting } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} md={10} lg={6} className={classes.root}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" align="center">
              {t('New meeting')}
            </Typography>
            {
              !isLoading && !buildings.length &&
              <div className="alert alert-info text-center">
                {t('You do not have invite permissions in any building')}
              </div>
            }
            {
              !isLoading && !!buildings.length && <Formik
                initialValues={meeting}
                validationSchema={CreateInvitationSchema}
                enableReinitialize={true}
                onSubmit={
                  async (values, { setSubmitting, setErrors }) => {
                    try {
                      await onSubmit({
                        ...values,
                        date_from: moment(
                          `${moment(values.date_from.date).format('YYYY-MM-DD')} ${moment(values.date_from.time).format('HH:mm')}`, 'YYYY-MM-DD HH:mm'
                        ).toDate(),
                      });
                    } catch (err) {
                      if (err.response && err.response.data.errors) {
                        setErrors(err.response.data.errors);
                      }

                      setSubmitting(false);
                    }
                  }
                }
                render={fprops => <RenderForm {...fprops} {...{ buildings, t }}/>}
              />
            }
          </Paper>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}