import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import ChangePhoneForm from '../../components/Profile/change-phone';
import * as UsersApi from '../../api/users';
import { updateUserData } from '../../actions/users';

class ChangePhoneContainer extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    const { data } = await UsersApi.changePhone(body);

    this.props.updateUserData(data);
    toast.success(this.props.t('An SMS was sent to your cellphone with the verification code'));
  }

  requestCode = async () => {
    await UsersApi.requestCellphoneVerify();

    toast.success(this.props.t('An SMS was sent to your cellphone with the verification code'));
  }

  verifyCellphone = async (body) => {
    const { data } = await UsersApi.cellphoneVerify(body);

    this.props.updateUserData(data);
    toast.success(this.props.t('Your cellphone has been verified'));
    this.props.history.push('/app/account-configuration');
  }

  render() {
    return (
      <ChangePhoneForm
        onSubmit={this.onSubmit}
        user={this.props.user}
        requestCode={this.requestCode}
        verifyCellphone={this.verifyCellphone}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  updateUserData: (data) => dispatch(updateUserData(data)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChangePhoneContainer));
