import { LOGIN_SUCCESS, LOGOUT } from '../constants';

export function loggedIn(data) {
  return {
    data,
    type: LOGIN_SUCCESS,
  };
}

export function logout(force) {
  return {
    type: LOGOUT,
    force,
  };
}
