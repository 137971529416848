import * as React from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  Button,
} from '@material-ui/core';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import { Checkbox } from '../Shared/BsInput';

export default ({ data }) => {
  const { t } = useTranslation();
  const [filters, setFilters] = React.useState({});

  React.useEffect(() => {
    setFilters({
      invitations: false,
      users: false,
      approved: false,
      completed: false,
    });
  }, [data]);

  const applyFilters = () => {
    let filtered = data.slice(0);

    if (filters.invitations) {
      filtered = filtered.filter((r) => !!r.matching_invitation);
    }

    if (filters.users) {
      filtered = filtered.filter((r) => !!r.matching_user);
    }

    if (filters.approved) {
      filtered = filtered.filter((r) => !!r.matching_user && !!r.matching_user.profile_approved_at);
    }

    if (filters.completed) {
      filtered = filtered.filter((r) => !!r.matching_user && !!r.matching_user.profile_completed_at);
    }

    return filtered;
  };
  const handleChange = (name) => (e) => {
    const { checked } = e.target;

    setFilters({
      ...filters,
      [name]: checked,
    });
  };
  const filtered = applyFilters();
  const csvData =  [
    [
      t('Name'), t('Card code'), t('Used QR'), t('Has invitation in PS'),
      t('PS user email'), t('PS user profile complete'),
      t('PS user profile approved'), t('Access date'),
    ],
    ...filtered.map((row) => {
      return [
        row.name, row.card_id, row.qr ? t('Yes') : t('No'),
        row.matching_invitation ? t('Yes') : t('No'),
        row.matching_user ? row.matching_user.email : '-',
        row.matching_user ? row.matching_user.cellphone : '-',
        row.matching_user ? moment(row.matching_user.profile_completed_at).format('DD-MM-YYYY HH:mm') : '-',
        row.matching_user ? moment(row.matching_user.profile_approved_at).format('DD-MM-YYYY HH:mm') : '-',
        moment(row.created_at).format('DD-MM-YYYY HH:mm'),
      ];
    }),
  ];

  return (
    <>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <Grid item container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Checkbox
                value={filters.invitations}
                checked={!!filters.invitations}
                onChange={handleChange('invitations')}
              /><Typography component="span">{t('Only with corresponding invitation')}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Checkbox
                value={filters.users}
                checked={!!filters.users}
                onChange={handleChange('users')}
              /><Typography component="span">{t('Only with corresponding user in PS')}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Checkbox
                value={filters.completed}
                checked={!!filters.completed}
                onChange={handleChange('completed')}
              /><Typography component="span">{t('Only with profile completed in PS')}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Checkbox
                value={filters.approved}
                checked={!!filters.approved}
                onChange={handleChange('approved')}
              /><Typography component="span">{t('Only with profile approved in PS')}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="outlined"
            size="small"
          >
            <CSVLink data={csvData} filename="podesubir-access-list.csv">{t('Download CSV')}</CSVLink>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('Name')}</TableCell>
                <TableCell>{t('Card code')}</TableCell>
                <TableCell>{t('Used QR')}</TableCell>
                <TableCell>{t('Has invitation in PS')}</TableCell>
                <TableCell>{t('PS user email')}</TableCell>
                <TableCell>{t('PS user phone')}</TableCell>
                <TableCell>{t('PS user profile complete')}</TableCell>
                <TableCell>{t('PS user profile approved')}</TableCell>
                <TableCell>{t('Access date')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                filtered.map((d, i) => (
                  <TableRow key={i}>
                    <TableCell>{d.name}</TableCell>
                    <TableCell>{d.card_id}</TableCell>
                    <TableCell>
                      {
                        d.qr && (t('Yes'))
                      }
                      {
                        !d.qr && (t('No'))
                      }
                    </TableCell>
                    <TableCell>
                      {
                        d.matching_invitation && (t('Yes'))
                      }
                      {
                        !d.matching_invitation && (t('No'))
                      }
                    </TableCell>
                    <TableCell>
                      {
                        d.matching_user ? d.matching_user.email : '-'
                      }
                    </TableCell>
                    <TableCell>
                      {
                        d.matching_user ? d.matching_user.cellphone : '-'
                      }
                    </TableCell>
                    <TableCell>
                      {
                        d.matching_user ? moment(d.matching_user.profile_completed_at).format('DD-MM-YYYY HH:mm') : '-'
                      }
                    </TableCell>
                    <TableCell>
                      {
                        d.matching_user ? moment(d.matching_user.profile_approved_at).format('DD-MM-YYYY HH:mm') : '-'
                      }
                    </TableCell>
                    <TableCell>{moment(d.created_at).format('DD-MM-YYYY HH:mm')}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  )
}