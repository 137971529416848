import * as React from 'react';
import Barcode from 'react-barcode';
import QrCode from 'qrcode.react';

export default ({accessCode}) => {
  if (!accessCode) {
    return null;
  }

  if (accessCode.access_types.includes('qr')) {
    return (
      <QrCode value={accessCode.key}/>
    );
  }

  if (!accessCode.access_types.includes('qr') && accessCode.access_types.includes('barcode')) {
    return (
      <Barcode value={accessCode.key}/>
    );
  }
}
