import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import VerifyEmail from '../Register/verify-email';
import RegistrationSteps from '../Register/steps';
import { PrivateRoute } from './routes';

export default ({ loggedIn }) => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/app/complete-registration"
        component={RegistrationSteps}
        loggedIn={loggedIn}
      />
      <Route
        path="/register/verify-email/:hash"
        component={VerifyEmail}
      />
      <Redirect to={loggedIn ? '/app/complete-registration' : '/login'} />
    </Switch>
  );
}