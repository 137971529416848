import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import ContainerCard from '../Shared/container-card';

export default ({ building, isLoading }) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (<div></div>);
  };

  return (
    <ContainerCard
      xs={12}
      md={12}
      lg={9}
      header={
        <Typography variant="h6" align="center">
          {t('Building detail')}
        </Typography>
      }
      content={
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div>
              <small className="font-weight-bold text-lowercase">{t('Name')}</small>
              <div className="text-uppercase">{building.name}</div>
            </div>
            <div>
              <small className="font-weight-bold text-lowercase">{t('Address')}</small>
              <div className="text-uppercase">{building.address}</div>
            </div>
            <hr />
            <div>
              <small className="font-weight-bold text-lowercase">{t('My user roles in this building')}</small>
              {
                building.Roles.map((ur, i) => {
                  return (
                    <div key={i}>
                      <div>
                        - <span className="text-uppercase">{t(ur.role)}</span>: &nbsp;
                        {t(`${ur.role}.description`)}.
                      </div>
                    </div>
                  );
                })
              }
            </div>
            <hr />
          </Grid>
        </Grid>
      }
    />
  );
}
