import * as React from 'react';
import * as _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CompaniesForm from '../../components/Companies/form';
import * as BuildingsApi from '../../api/buildings';
import { listMainCompanies } from '../../api/companies';

class CompaniesCreateContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      buildingId: this.props.match.params.buildingId,
      companyId: this.props.match.params.companyId,
      company: null,
      isLoading: true,
      mainCompanies: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    const clean = _.pick(body, [
      'name', 'floor', 'room', 'access_before_minutes', 'parent_id',
      'layout', 'logo', 'background', 'phone',
    ]);

    await BuildingsApi
      .updateCompany(this.state.buildingId, this.state.companyId, clean);

    toast.success(this.props.t('The company has been updated'));
    this.props.history.push(`/app/buildings/${this.state.buildingId}/companies`);
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }

    return (
      <CompaniesForm
        company={this.state.company}
        onSubmit={this.onSubmit}
        isLoading={this.state.isLoading}
        mainCompanies={this.state.mainCompanies}
      />
    );
  }

  async componentDidMount() {
    const { data } = await BuildingsApi
      .getCompany(this.state.buildingId, this.state.companyId);

    const res = await listMainCompanies();

    this.setState({
      mainCompanies: res.data,
      company: {
        ...data,
      },
      isLoading: false,
    });
  }
}

export default withTranslation()(CompaniesCreateContainer);