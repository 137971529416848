import * as React from 'react';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import ChangeEmailForm from '../../components/Profile/change-email';
import * as UsersApi from '../../api/users';

class ChangeEmailContainer extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    await UsersApi.changeEmail(body);

    toast.success(this.props.t('We have sent an email to your new email address. The change will be efective once you verify this new email'));
    this.props.history.push('/app/account-configuration');
  }

  render() {
    return (
      <ChangeEmailForm
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default withTranslation()(ChangeEmailContainer);
