import * as React from 'react';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { Select } from '../Shared/BsInput';

export default (props) => {
  const { handleChange, values, t, errors, buildings, setFieldValue, label, enableAll } = props;
  const selectedBuilding = buildings.find((b) => b.id === parseInt(values.building_id));
  let companies = [];
  
  if(enableAll) companies = [{label: t("All"), value: 0}]
  
  companies = companies.concat(selectedBuilding?.all ? [{ value: '__none__', label: 'All' }] : []).concat(
    selectedBuilding.Companies.map((c) => ({ label: c.name, value: c.id })),
  );
  

  return (
    <>
      <Grid item xs={12} sm={6} md={4} xl={3}>
        <Select
          style={{ paddingTop: '8px' }}
          label={label || t('Building')}
          name="building_id"
          value={values.building_id}
          errors={errors}
          onChange={(e) => {
            const selected = buildings.find((b) => b.id === parseInt(e.target.value));

            if (selected.Companies.length && !selected.all) {
              setFieldValue('company_id', enableAll ? 0 : selected.Companies[0].id);
            } else {
              setFieldValue('company_id', '__none__');
            }

            handleChange(e);
          }}
          options={
            buildings.map((b) => {
              return { value: b.id, label: b.name };
            })
          }
        />
      </Grid>
      {
        selectedBuilding.Companies && !!selectedBuilding.Companies.length && (
          <Grid item xs={12} sm={6} md={4}>
            <Select
              style={{ paddingTop: '8px' }}
              label={t('Company')}
              name="company_id"
              value={values.company_id}
              errors={errors}
              onChange={handleChange}
              options={companies}
            />
          </Grid>
        )
      }
    </>
  );
}
