import * as React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import StatsRegistration from '../../components/Stats/registration';
import * as StatsApi from '../../api/stats';

class StayContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      form: {
        building_id: null,
        company_id: null,
        group: 'month',
      },
      data: null,
      empty: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    this.setState({ form: body, empty: false });
    const { data } = await StatsApi.registration(body);

    this.setState({ data, empty: !data.length });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.buildings && props.buildings.length && !state.form.building_id) {
      return {
        form: {
          ...state.form,
          building_id: props.buildings[0].id,
          company_id: props.buildings[0].Companies && props.buildings[0].Companies.length
            ? props.buildings[0].Companies[0].id
            : '__none__',
        },
      };
    }

    return null;
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }

    return (
      <StatsRegistration
        buildings={this.props.buildings}
        onSubmit={this.onSubmit}
        form={this.state.form}
        empty={this.state.empty}
        data={this.state.data}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  buildings: state.buildings.records.filter((b) => b.permissions.reports),
});

export default withTranslation()(connect(mapStateToProps)(StayContainer));
