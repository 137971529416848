export const BUILDING_MANAGER = 'BUILDING_MANAGER';
export const COMPANY_MANAGER = 'COMPANY_MANAGER';
export const HOST_EMPLOYEE = 'HOST_EMPLOYEE';
export const EMPLOYEE = 'EMPLOYEE';
export const GUEST = 'GUEST';
export const SECURITY = 'SECURITY';
export const COMPANY_SECRETARY = 'COMPANY_SECRETARY';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const API_REQUEST = 'API_REQUEST';
export const TOS_ACCEPTED = 'TOS_ACCEPTED';
export const SET_BUILDINGS = 'SET_BUILDINGS';
export const SET_BUILDINGS_ACCESS_LIST = 'SET_BUILDINGS_ACCESS_LIST';

export const COUNTRIES_CODES = [{
  value: '54',
  label: 'Argentina (+54)',
}, {
  value: '1242',
  label: 'Bahamas (+1242)',
}, {
  value: '591',
  label: 'Bolivia (+591)',
}, {
  value: '55',
  label: 'Brazil (+55)',
}, {
  value: '1',
  label: 'Canada (+1)',
}, {
  value: '56',
  label: 'Chile (+56)',
}, {
  value: '57',
  label: 'Colombia (+57)',
}, {
  value: '506',
  label: 'Costa Rica (+506)',
}, {
  value: '53',
  label: 'Cuba (+53)',
}, {
  value: '1809',
  label: 'Dominican Republic (+1809)',
}, {
  value: '593',
  label: 'Ecuador (+593)',
}, {
  value: '503',
  label: 'El Salvador (+503)',
}, {
  value: '502',
  label: 'Guatemala (+502)',
}, {
  value: '509',
  label: 'Haiti (+509)',
}, {
  value: '504',
  label: 'Honduras (+504)',
}, {
  value: '52',
  label: 'Mexico (+52)',
}, {
  value: '505',
  label: 'Nicaragua (+505)',
}, {
  value: '507',
  label: 'Panama (+507)',
}, {
  value: '595',
  label: 'Paraguay (+595)',
}, {
  value: '51',
  label: 'Peru (+51)',
}, {
  value: '1787',
  label: 'Puerto Rico (+1787)',
}, {
  value: '598',
  label: 'Uruguay (+598)',
}, {
  value: '1',
  label: 'USA (+1)',
}, {
  value: '58',
  label: 'Venezuela (+58)',
}];

export const REGISTRATION_STEPS_ORDER = {
  EMAIL_VERIFICATION: 0,
  CELLPHONE_VERIFICATION: 1,
  BASIC_INFORMATION: 2,
  PROFILE_PICTURE: 3,
  DOCUMENTS: 4,
  CONFIRM_INFORMATION: 5,
};