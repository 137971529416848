export default {
  'Email': 'email',
  'Password': 'contraseña',
  'Login': 'acceder',
  'You don\'t have an account?': '¿no tienes cuenta?',
  'Register here': 'abrir nueva cuenta',
  'Role': 'Rol',
  'Name': 'nombre',
  'Save': 'guardar',
  'Surname': 'apellido',
  'Confirm password': 'confirmar contraseña',
  'Register': 'registro',
  'Already have an account?': 'ya tengo una cuenta',
  'sign in': 'haz login',
  'My profile': 'mi perfíl',
  'Logout': 'salir',
  'Change password': 'cambiar contraseña',
  'Your profile picture was updated': 'Tú foto de perfíl fue actualizada',
  'Your profile information was updated': 'Tú información de perfil fue actualizada',
  'New password': 'Nueva contraseña',
  'Confirm new password': 'Confirmar nueva contraseña',
  'Your password has been changed': 'Tú contraseña fue actualizada',
  'Cancel': 'Cancelar',
  'Update information': 'Actualizar información',
  'Upload new picture': 'Cargar nueva imágen',
  'Pending invitations for today': 'Invitaciones pendientes para el día de hoy',
  'There are no pending invitations for today': 'No hay invitaciones pendientes',
  'My buildings': 'mis edificios',
  'My invitations': 'mis invitaciones',
  'No buildings were found': 'No se encontraron edificios',
  'Home': 'Inicio',
  'Building detail': 'Detalle de edificio',
  'Address': 'Domicilio',
  'Manager': 'Encargado',
  'My user roles in this building': 'Mis roles de usuario en este edificio',
  'BUILDING_MANAGER': 'Encargado de edificio',
  'BUILDING_MANAGER.description': 'Permisos para cambiar la informacion, compañias y usuarios de un edificio, acceder al edificio y crear invitaciones',
  'COMPANY_MANAGER': 'Encargado de empresa',
  'COMPANY_MANAGER.description': 'Permisos para cambiar la informacion y usuarios de la empresa, acceder al edificio y crear invitaciones',
  'EMPLOYEE': 'Staff de edificio',
  'EMPLOYEE.description': 'Permisos para acceder al edificio',
  'HOST_EMPLOYEE': 'Staff de edificio con permisos para invitar',
  'HOST_EMPLOYEE.description': 'Permisos para acceder al edificio y crear invitaciones',
  'SECURITY': 'Personal de seguridad del edificio',
  'SECURITY.description': 'Permisos para acceder, crear invitaciones y permitir el acceso de invitados de forma manual',
  'Edit': 'Editar',
  'Manage companies': 'Administrar compañias',
  'No records found': 'No se encontraron registros',
  'My roles': 'Mis roles',
  'Manage users': 'Administrar usuarios',
  'Detail': 'Detalle',
  'The building information has been updated': 'La información del edificio fue actualizada',
  'Building\'s users': 'Usuarios del edificio',
  'Go to detail': 'Ir al detalle',
  'Add user to building': 'Agregar usuario a edificio',
  'Add user': 'Agregar usuario',
  'Remove user': 'Remover usuario',
  'Remove': 'Remover',
  'User email': 'Email del usuario',
  'The user has been added to the building': 'El usuario fue agregado al edificio',
  'The user has been removed from the building': 'El usuario fue removido del edificio',
  'Remove user from building?': '¿Remover usuario del edificio?',
  'Remove user from company?': '¿Remover usuario de la compañia?',
  'Yes': 'Si',
  'No': 'No',
  'My companies': 'mis compañias',
  'Companies': 'Compañias',
  'Edit company': 'Editar compañia',
  'Create company': 'Crear compañia',
  'Manager email': 'Email del manager',
  'The company has been added to the building': 'La compañia fue agregada al edificio',
  'The company has been updated': 'La compañia fue actualizada',
  'The user has been added to the company': 'El usuario fue agregado a la compañia',
  'The user has been removed from the company': 'El usuario fue removido de la compañia',
  'Add user to company': 'Agregar usuario a la compañia',
  'Company\'s users': 'Usuarios de la compañia',
  'Back to the building\'s companies': 'Volver a las compañias del edificio',
  'My user roles in this company': 'Mis roles de usuario en esta compañia',
  'Company detail': 'Detalle de compañia',
  'New invitation': 'Nueva invitacion',
  'The invitation has been sent': 'La invitacion fue enviada',
  'Building': 'edificio',
  'Host': 'Anfitrion',
  'You do not have invite permissions in any building': 'No tienes permisos para invitar en ningún edificio',
  'Guest\'s email': 'Email del invitado',
  'Guest': 'Invitado',
  'Invitation date': 'Horario',
  'As myself': 'Yo',
  'Invitation subject/reason': 'Asunto',
  'Invitation duration': 'Duracion de la invitacion',
  'Modify meeting': 'Actualizar',
  '1 hour': '1 hora',
  '2 hours': '2 horas',
  '3 hours': '3 horas',
  '5 hours': '5 horas',
  '12 hours': '12 horas',
  'Notes': 'Comentarios',
  'Allow access to the building for': 'Duracion',
  'Create invitation': 'Crear invitacion',
  'Invitation detail': 'Detalle de invitacion',
  'Date': 'Fecha',
  'hours': 'horas',
  'Access allowed for': 'Permitir acceso por',
  'You are hosting': 'tu invitaste',
  'Confirmed': 'Confirmada',
  'Waiting confirmation': 'Esperando confirmación',
  'Rejected': 'Rechazada',
  'Cancelled': 'Cancelada',
  'Status': 'Estado',
  'Subject/Reason': 'Asunto/Motivo',
  'Sent to': 'Enviada a',
  'Sent date': 'Fecha de enviada',
  'I forgot my password': 'Se me olvidó mi contraseña',
  'Password recovery': 'Recuperar contraseña',
  'Input your email and we will send you a link to restore your password': 'Ingrese su email y le enviaremos un link para recuperar la contraseña',
  'Back': 'Volver',
  'An email was sent to your address with instructions on how to restore your password': 'Se enviaron instrucciones sobre como recuperar su contraseña',
  'Request password recovery': 'Solicitar recuperar contraseña',
  'Your password as been restored. You can now access with your new password': 'Su contraseña fue restrablecida. Ahora puede iniciar sesión con su nueva contraseña',
  'Your email has been verified': 'Su correo fue verificado',
  'The email could not be verified': 'El correo no pudo ser verificado',
  'Verify email': 'Verificar correo',
  'PAST_DUE': 'Vencida',
  'CANCELLED': 'Cancelada',
  'REJECTED': 'Rechazado',
  'CONFIRMED': 'Aceptado',
  'PENDING': 'No confirmado',
  'Confirm': 'Confirmar',
  'Reject': 'Rechazar',
  'The assistance to the invitation has been confirmed': 'La asistencia a la invitacion fue confirmada',
  'Confirm assistance?': '¿Confirmar asistencia?',
  'The invitation has been cancelled': 'La invitacion fue cancelada',
  'Cancel invitation?': 'Al rechazar esta invitación se le avisará al anfitrión. Estás seguro de que quieres rechazarla?',
  'The invitation has been rejected': 'La invitacion fue rechazada',
  'Reject invitation?': 'Al rechazar esta invitación se le avisará al anfitrión. Estás seguro de que quieres rechazarla?',
  'Required': 'Requerido',
  'Invalid email': 'Correo invalido',
  'Password confirm does not match': 'Las contraseñas no coinciden',
  'Select a building': 'Seleccionar edificio',
  'Cellphone': 'Móvil',
  'Birthdate': 'Fecha de nacimiento',
  'Habilitaçao': 'CNH',
  'RNE number': 'RNE',
  'ID Type': 'documento de identidad',
  'Invalid ID number': 'Numero de identificacion invalido',
  'RG': 'RG',
  'RNE': 'RNE',
  'Passport': 'Pasaporte',
  'RG number': 'RG',
  'CPF number': 'CPF',
  'Invalid RG number': 'RG invalido',
  'Invalid CPF number': 'CPF invalido',
  'Secretary': 'Secretaria',
  'My secretaries': 'definir delegados',
  'New secretary': 'nuevo delegado',
  'The user has been added as a secretary': 'El usuario fue agregado como secretaria',
  'No results': 'Sin resultados',
  'Search and select an user': 'Buscar y seleccionar usuario',
  'The user has been removed from your secretaries': 'El usuario fue removido de sus secretarias',
  'Remove user from your secretaries?': '¿Remover usuario de sus secretarias?',
  '1. Select the building': '1. Seleccionar edificio',
  '2. Search a user and select from the list': '2. Buscar y seleccionar usuario del listado',
  'Send invitation as yourself or acting as a secretary for another user': 'Enviar invitacion a tu nombre o actuando como secretaria de otro usuario',
  'Send invitation as': 'Crear reunion a nombre de',
  'Building not found': 'Edificio no encontrado',
  'Invalid token': 'Token invalido',
  'Forbidden': 'Prohibido',
  'There\'s no user registered with that email': 'No hay usuarios registrados con ese correo',
  'Email or password incorrect': 'Email/contraseña incorrecto',
  'The building manager can not be removed': 'El encargado de edificio no puede ser removido',
  'Company not found': 'Compañia no encontrada',
  'The company manager can not be removed': 'El encargado de la compañia no puede ser removido',
  'You cannot invite in this building': 'No puedes invitar en este edificio',
  'The user cannot invite in this building': 'El usuario no puede invitar en este edificio',
  'Missing file': 'Sin archivo',
  'You can not cancel a past invitation': 'No puedes cancelar una invitacion pasada',
  'The invitation can not be cancelled': 'La invitacion no puede ser cancelada',
  'You can not reject your own invitation': 'No puedes rechazar tu propia invitacion',
  'You can not reject a past invitation': 'No puedes rechazar una invitacion pasada',
  'The invitation can not be rejected': 'La invitacion no pudo ser rechazada',
  'You can not confirm your own invitation': 'No puedes confirmar tu propia invitacion',
  'You can not confirm a past invitation': 'No puedes confirmar una invitacion pasada',
  'The invitation can not be confirmed': 'La invitacion no puede ser confirmada',
  'Invitation not found': 'Invitacion no encontrada',
  'Can\'t create invitations on behalf user': 'No puede crear invitaciones en nombre del usuario',
  'The user can\'t create invitations in this building': 'El usuario no puede crear invitaciones en este edificio',
  'You are not allowed to perform this action': 'No esta autorizado a realizar esta accion',
  'Multiple emails separated by comma': 'Varios emails separados por coma',
  'Sent invitations': 'invitaciones enviadas',
  'Received invitations': 'invitaciones',
  'Invitation data': 'Datos de invitaciones',
  'Account configuration': 'configuraciones',
  'My information': 'mi información',
  'Account': 'Configuraciones de la cuenta',
  'My vehicle': 'mi vehiculo',
  'Your vehicle information was updated': 'la información de vehiculo fue actualizada',
  'Make': 'marca',
  'Model': 'modelo',
  'Color': 'color',
  'License plate': 'place',
  'The documents were uploaded': 'Los documentos fueron actualizados',
  'My documents': 'Mis documentos',
  'ID Front': 'actualizar foto del frente de mi documento de identidad',
  'ID Back': 'actualizar foto del lado de atrás de mi documento de identidad',
  'Email or cellphone number': 'email o número de celular',
  'If using a cellphone number, it must be valid and include both country and area code. Ex (5511943210091)': 'En caso de usar un número de celular, debe ser valido e incluir el codigo de pais y de area. Ej (5511943210091)',
  'Verify cellphone': 'Verificar número de celular',
  'Your cellphone has been verified': 'Su numero de celular fue verificado',
  'The cellphone could not be verified': 'El numero de celular no pudo ser verificado',
  'A message was sent to your number with a verification code': 'Se envió un mensaje a tú número con un codigo para la verificación',
  'Skip': 'Omitir',
  'Cellphone number': 'Número de móvil',
  'Only numbers. Including country and area code': 'Solo números. Incluyendo codigo de país y de área',
  'Verify cellphone number': 'Verificar número de celular',
  'Your cellphone is already verified': 'Sú número de celular ya está verificado',
  'Request verification code': 'Solicitar código de verificación',
  'Verification code': 'Código de verificación',
  'Verify': 'Verificar',
  'Invalid number': 'Número invalido',
  'Invalid email or cellphone': 'email o número de celular invalido',
  'Change email': 'Cambiar email',
  'Change phone': 'Cambiar número de telefono',
  'We have sent an email to your new email address. The change will be efective once you verify this new email': 'Enviamos un correo a su nueva dirección. El cambio se hará efectivo una vez se haya verificado esta nueva dirección de correo.',
  'Your phone number has been changed': 'Su teléfono ha sido modificado',
  'After you submit we will send a verification link to this new email address. The change will only be effective after you have verified this new email address.': 'recibirás un link de confirmación en el email abajo. El cambio solo tendrá efecto después que sigas el link.',
  'Min 8 characters, one uppercase, one lowercase and one number': 'Minimo 8 caracteres, por lo menos una mayuscula y por lo menos un número',
  'The token you are trying to use is not valid': 'El token que esta tratando de usar no es valido',
  'Access': 'Acceso',
  'There are not access codes available for the selected building': 'No hay codigos de acceso disponibles para el edificio seleccionado',
  'Company role': 'Cargo',
  'Company': 'Compañia',
  'Accept': 'Aceptar',
  'Terms and Conditions': 'Leí y acepto los términos y condiciones',
  'Privacy policy': 'Leí y acepto la política de privacidad',
  'There was changes in our Terms of service and Privacy Policy. Please review them and make sure you accept them before you continue using our application.': 'Los terminos y condiciones y la politica de privacidad fueron actualizados',
  'Changes in Terms of service and Privacy Policy': 'Cambios en condiciones de servicio y politica de privacidad',
  'Delete my account': 'Eliminar mi cuenta',
  'Your account has been deleted.': 'Su cuenta fue eliminada',
  'Deleting your account is irreversible. All your data will be lost, including your personal information, invitations you sent and received, associations to buildings and secretaries.': 'Eliminar su cuenta es irreversible. Toda su información se perdera, incluyendo información personal, invitaciones enviadas y recibidas, asociaciones a edificios y secretarias.',
  'Input your password to delete the account': 'Ingrese su contraseña para eliminar la cuenta',
  'I understand. Delete my account': 'Entiendo. Eliminar mi cuenta',
  'Invitations': 'Invitaciones',
  'Invitation': 'Invitacion',
  'The access code is being generated and will be visible here when the invitation date is due': 'El código de acceso está siendo generador y estará visible cuando la fecha de la invitación corresponda',
  'Host\'s email': 'Email del anfitrion',
  'Host\'s cellphone': 'Celular del anfitrion',
  'View invitation full detail': 'Ver detalle de la invitación completo',
  'The access code will be visible here once the invitation is confirmed': 'El código de acceso estará visible una vez la invitación sea aceptada',
  'Download the Podesubir app here': 'Descarga la aplicacion de Podesubir aqui',
  'My Badge': 'mi credencial',
  'Visitor': 'Visitante',
  'Meetings': 'reuniones',
  'New meeting': 'nueva reunion',
  'Other guests': 'Otros invitados',
  'ACTIVE': 'Esperando confirmación',
  'Invited': 'Invitado',
  'Multiple emails/cellphones separated by comma': 'Multiples emails/teléfonos separados por coma',
  'Guest\'s contact': 'Contacto del invitado',
  'Create meeting': 'Nueva reunion',
  'Guests': 'Invitados',
  'The meeting has been modified': 'La reunion fue modificada',
  'Remove guest from the meeting?': '¿Remover invitado de la reunion?',
  'Remove guest from the meeting': 'Remover invitado de la reunion',
  'Cancel meeting?': 'Al cancelar esta invitación, se le avisará al (a los) convidado(s). Estás seguro de que quieres cancelarla?',
  'The meeting has been cancelled': 'La reunion fue cancelada',
  'Add guests to the meeting': 'Agregar invitado a la reunion',
  'Add': 'Agregar',
  'For security reasons we encourage you to change your password.': 'Por motivos de seguridad le recomendamos cambiar su contraseña',
  'None': 'Ninguna',
  'Floor': 'Piso',
  'Room': 'Oficina/Sala',
  'Main company': 'Compañia raiz',
  'Allow access before meeting starts (minutes)': 'Permitir antes de comenzar la reunion (minutos)',
  'Information was sent on how to restore your password': 'Se envio un correo con información para recuperar tu contraseña',
  'Meetings & Invitations': 'reuniones & invitaciones',
  'There are no more records': 'No hay mas registros',
  'You were invited': 'te invitaron',
  'Invited by': 'Invitado por',
  'your best welcome': 'tu mejor recepción',
  'Sign up': 'Registrar',
  'Meeting date': 'Horario',
  'terms': 'términos y condiciones',
  'privacypolicy': 'política de privacidad',
  'New email': 'Nuevo email',
  'New cellphone number': 'Nuevo número de celular',
  'Attention': 'Atención',
  'Date from': 'Fecha desde',
  'Date to': 'Fecha hasta',
  'Group data by': 'Agrupar datos por',
  'Month': 'Mes',
  'Week': 'Semana',
  'Day': 'Dia',
  'Hour': 'Hora',
  'Show': 'Mostrar',
  'Show Filters': 'Mostrar Filtros',
  'Visitors by building': 'Visitantes por edificio',
  'My stats': 'Mis reportes',
  'Visitors': 'Visitantes',
  'Visitors stay': 'Estadia de visitantes',
  'week': 'semana',
  'of': 'de',
  'Stats': 'Reportes',
  'Stats: stay': 'Reportes: estadia',
  'Average duration (minutes)': 'Duración promedio (minutos)',
  'Average visits duration': 'Duración promedio de visita',
  'Stats: visitors': 'Reportes: visitantes',
  'Stay': 'Duración estadia',
  'You need to be building manager to see reports': 'Se necesita rol de encargado de edificio para ver reportes',
  'Sent': 'Enviadas',
  'Received': 'Recibidas',
  'All': 'Todas',
  'Pending': 'Pendientes',
  'Your profile has been rejected for the following reasons': 'Sú perfil de usuario fue rechazado por los siguientes motivos',
  'Please complete all of your profile information in order to start using Podesubir': 'Por favor complete toda la información de su perfil para poder usar Podesubir',
  'Missing fields': 'Campos incompletos/erroneos',
  'Your profile is under review. You will receive a response within 24hs': 'Sú perfil esta bajo revision. Recibirá una respuesta en las próximas 24hs',
  'Profile picture': 'Foto de perfil',
  'ID number': 'Número de identificacion',
  'Documets ID Front picture': 'Foto del frente de su identificación',
  'Documents ID Back picture': 'Foto del dorso de su identificación',
  'Click here to correct the relevant fields': 'Haga click aqui para corregir los campos necesarios',
  'Email verification': 'Verificación de email',
  'Cellphone verification': 'Verificación de celular',
  'Basic information': 'Información personal',
  'ID documents': 'Documento identidad',
  'Finalize': 'Finalizar',
  'We have sent you an email to your address': 'Hemos enviado un correo a tu dirección de email',
  'Please follow the instructions and click on continue': 'Por favor siga las instrucciones y haga click en continuar',
  'If you did not received the email you can': 'Si no recibio el email puede',
  'request a new verification email': 'solicitar un nuevo email de verificación',
  'Alternatively you can': 'Alternativamente puede',
  'change your email address': 'cambiar su dirección de email',
  'Continue': 'Continuar',
  'Change email': 'Cambiar email',
  'A new email was sent to your address': 'Un nuevo correo fue enviado a su dirección',
  'Change email address': 'Cambiar dirección de email',
  'Your email address was changed and a new verification email was sent': 'Su dirección de correo electronico fue modificada y un nuevo correo de verificación enviado',
  'If you made a mistake or want to change your phone number': 'Si cometio un error o desea cambiar su número de telefono',
  'Change cellphone': 'Cambiar número de telefono',
  'We will now verify your cellphone number': 'Verificaremos sú número de teléfono',
  'We will send you a 4 digit verification code via SMS': 'Le enviaremos un código de verificación de 4 digitos por SMS',
  'I am ready, send me the verification code': 'Estoy listo, enviar código de verificación',
  'click here': 'click aquí',
  'Change cellphone number': 'Cambiar número de teléfono',
  'An SMS was sent to your cellphone with the verification code': 'Hemos enviado un SMS a sú número con el código de verificación',
  'Invalid code': 'El código ingresado no es valido o ha expirado. Revise su casilla de SMS o solicite un nuevo SMS de verificación de ser necesario',
  'Your cellphone number was changed and a new verification sms was sent': 'Sú número de teléfono fue modificado y un nuevo SMS de verificación enviado',
  'Country of residence': 'País de residencia',
  'cpf is a required field': 'Requerido',
  'Your face must be visible and centered in the picture frame': 'Su cara debe ser visible y centrada en la imagen',
  'No other people should be visible in the picture': 'No deben aparecer otras personas en la imagen',
  'The picture must match the picture in the ID documents from the next step': 'La imagen debe coincidir con la foto de su documentacion del proximo paso',
  'Take picture': 'Tomar fotografia',
  'NO_FACES': 'No se detectaron rostros en la imágen. Lea las instrucciones e intente nuevamente',
  'MULTIPLE_FACES': 'Se detectaron mas de un rostro en la imágen. Lea las instrucciones e intente nuevamente',
  'Confirm and save': 'Confirmar',
  'Make sure the ID pictures are clear and all the information can be seen': 'Asegurese que las imagenes de su documento sean claras con toda la información visible',
  'The face in the front of the ID must match your profile picture': 'El rostro en el frente del documento debe coincidir con el rostro en la foto de perfil',
  'The information in the ID must match the information you provided in the previous step': 'La información en el documento debe coincidir con la información del paso anterior',
  'ID picture front': 'frente del documento',
  'ID picture back': 'dorso del documento',
  'You have completed all the registration steps': 'Completó todos los pasos del registro',
  'If you would like to change any information you can go back to a previous step': 'Si desea cambiar la información puede volver a los pasos anteriores',
  'When you are ready, press finalize to complete the process': 'Cuando este listo, presione finalizar para terminar el proceso',
  'MISSING_REVIEW_CORRECTIONS': 'Hay correcciones del perfil pendientes. Asegurece de corregirlas antes de finalizar',
  'FACE_DOESNT_MATCH': 'La imagen cargada como foto de perfil no coincide con el frente de su documento de identidad',
  'INFORMATION_DOESNT_MATCH': 'Alguna de la información cargada en "Information personal" no coincide con la información de su documento.',
  'There was a validation error with the information provided': 'Hubo un error validando la información provista',
  'Go back to the previous steps and correct the information': 'Vuelva a los pasos anteriores y corrija la información pertinente',
  'If the information supplied is correct, click the following button to submit everything as is and a customer support representative will review your profile': 'Si la información provista es correcta, haga click en el siguiente boton para enviar todo en su estado actual. Un representante de atencion al cliente revisará su perfil',
  'Submit my profile for review': 'Enviar mi perfil a revision',
  'Your profile is under review by a customer service representative. We will notify you via email once the review is complete': 'Sú perfil esta bajo revisión por un representante de atención al cliente. Lo notificaremos via email cuando la revisión finalice',
  'Your profile has been rejected by a customer support representative': 'Sú perfil fue rechazado por un representante de atención al cliente',
  'Please correct the following fields and re-submit your profile to be reviewed again': 'Por favor corrija los siguientes campos y re-envie su perfil para ser revisado nuevamente',
  'name': 'nombre',
  'surname': 'apellido',
  'company': 'compañia',
  'company_role': 'cargo/posicion en la compañia',
  'birthdate': 'fecha de nacimiento',
  'id_type': 'Tipo de ID',
  'id_number': 'Número de ID',
  'cpf': 'Número de CPF',
  'profile_picture': 'Foto de perfil',
  'ID_FRONT': 'Foto documento frente',
  'ID_BACK': 'Foto documento dorso',
  'You have performed all the requested corrections. Submit your profile for review': 'Ha realizado todas las correciones solicitadas. Envie su perfil para ser revisado nuevamente',
  'Your profile is now complete and your account verified. You can start using podesubir': 'Sú perfil esta completo y su cuenta validada. Ya puede comenzar a utilizar podesubir',
  'true': 'No coincide con la información las imagenes de su documento',
  'Access code': 'Código de acceso',
  'employees': 'empleados',
  'Filter': 'filtrar',
  'Roles': 'Funciones',
  'Roles in the building': 'Funcionarios en el edificio',
  'users': 'usuarios',
  'Users': 'Usuarios',
  'Date': 'Fecha',
  'Time': 'Hora',
  'next': 'siguiente',
  'Add new role': 'Adicionar rol',
  'your session expired. Please log in again': 'Su sesión ha expirado y fue terminada por seguridad. Para volver a su credencial y reuniones por favor ingrese nuevamente',
  'You are trying to log in from an unknown device. We have sent you an email with a verification code. Please input the code below to complete your login': 'El dispositivo del que esta intentando ingresar es desconocido. Le hemos enviado un código por e-mail. Por favor, ingrese dicho codigo para completar el inicio de sesión',
  'Go back': 'Volver',
  'INVALID_AUTH': 'Usuario o contraseña incorrectos',
  'EMAIL_ALREADY_VERIFIED': 'La dirección de e-mail ya se encuentra verificada',
  'CELLPHONE_ALREADY_VERIFIED': 'El número de teléfono ya se encuentra verificado',
  'INVALID_TOKEN': 'El token no es valido',
  'FORBIDDEN': 'No tiene permisos para realizar esta acción',
  'USER_NOT_FOUND': 'Usuario no encontrador',
  'NOT_FOUND': 'Registro no encontrado',
  'MEETING_IS_PAST': 'La reunión está en el pasado. No se puede realizar la acción',
  'MISSING_INVITATIONS_PERMISSIONS': 'No tiene permisos para realizar invitaciones en este edificio',
  'USER_IS_NOT_GUEST': 'Usted no es un invitado. No se puede realizar la accion',
  'PAST_DUE': 'La reunión está en el pasado. No se puede realizar la acción',
  'CONFIRMATION_FAILED': 'La invitación no pudo ser confirmada',
  'REJECTION_FAILED': 'La invitación no pudo ser rechazada',
  'CANCELLATION_FAILED': 'La invitación no pudo ser cancelada',
  'EMAIL_ALREADY_IN_USE': 'El e-email ingresado ya se encuentra en uso',
  'CELLPHONE_ALREADY_IN_USE': 'El celular ingresado ya se encuentra en uso',
  'PROFILE_NOT_APPROVED': 'No tiene permisos para realizar esta acción',
  'VALIDATION_ERROR': 'Error de validación',
  'The cellphone entered is already in use': 'El celular ingresado ya se encuentra en uso',
  'The email entered is already in use': 'El e-email ingresado ya se encuentra en uso',
  'Registration': 'Registro',
  'Domain': 'Dominio',
  'Total users': 'Usuarios totales',
  'previous': 'anterior',
  'Total approved': 'Total aprobados',
  '% approved': '% aprobado',
  'Users growth': 'Crecimiento usuarios',
  'Approved growth': 'Crecimiento aprobados',
  'Compare data to previous': 'Comparar datos con el anterior',
  'Trimester': 'Trimestre',
  'Month': 'Mes',
  'Week': 'Semana',
  'Day': 'Día',
  'Hour': 'Hora',
  'Access list': 'Lista de accesos',
  'Security staff': 'Guardia de seguridad',
  'Access date': 'Fecha de ingreso',
  'PS user profile approved': 'Perfil usuario PS aprobado',
  'PS user profile complete': 'Perfil usuario PS completo',
  'PS user email': 'Email de usuario PS',
  'Has invitation in PS': 'Tiene invitacion en PS',
  'Download CSV': 'Descargar CSV',
  'Card code': 'Codigo tarjeta',
  'Used QR': 'Usó QR',
  'Only with corresponding invitation': 'Solo con invitacion en PS',
  'Only with corresponding user in PS': 'Solo con usuario en PS',
  'Only with profile completed in PS': 'Solo con usuario completo en PS',
  'Only with profile approved in PS': 'Solo con usuario aprobado en PS',
  'Request the guest to announce it\'s arrival to be allowed in': 'Solicitar que el invitado se anuncie al llegar',
  'Your guest arrived to the meeting. Click here to see more': 'Su invitado ha llegado a la reunion. Haga click aqui para ver mas',
  'The host delayed your entry to the meeting. Click here to see more': 'El anfitrion retrazo su acceso. Haga click aqui para ver mas',
  'The host allowed your entry to the meeting. Click here to see more': 'El anfitrion permitio su acceso. Click here to see more',
  'The waiting time is over. We have reminded the host about your arrival': 'El tiempo de espera ha terminado. Le hemos recordado al anfitrion sobre su llegada',
  'Your host requested for you to wait {{minutes}} minutes': 'El anfitrion ha solicitado que espere por {{minutes}} minutos',
  'When your access is authorized you will be able to access directly with the QR code that will appear here': 'Cuando su acceso sea autorizado podra acceder directamente con el codigo que aparecera aqui',
  'Close': 'Cerrar',
  'Your arrival has been notified': 'Su llegada ha sido notificada',
  'Announce arrival': 'Anunciar llegada',
  'Allow access': 'Permitir acceso',
  'Wait 5 minutes': 'Esperar 5 minutos',
  'Wait 10 minutes': 'Esperar 10 minutos',
  'Your guest arrived': 'Su invitado ha llegado',
  'The guest has been allowed access': 'Se le permitió acceso al invitado',
  'Allow access?': '¿permitir acceso?',
  'The guest has been notified of the delay': 'Se notificó al invitado del retraso',
  'Delay the access?': '¿pedir al invitado que espere?',
  'agenda belongs to': 'agenda de',
  'You must not be wearing a facemask or anything that partially covers your face': 'No debe estar usando mascaras, barbijos, ni ningun elemento que cubra parcialmente su cara',
  'For a better experience use Google Chrome or Microsoft Edge': 'Para una mejor experiencia use Google Chrome o Microsoft Edge',
  'New picture': 'Tomar nueva foto',
  'Search': 'Buscar',
  'Security guard': 'Guardia',
  'guard.access.ENTRY': 'ENTRADA AUTORIZADA',
  'guard.access.EXIT': 'SALIDA AUTORIZADA',
  'guard.access.DENY_ENTRY': 'ENTRADA DENEGADA',
  'Event': 'evento',
  'Access allowed by guards': 'Acceso permitido por guardias',
  'who.visited.who': 'quien visitó quien',
  'Employees per company': 'Personas por empresa',
  'Local': 'local',
  'Host name': 'Nombre del anfitrión',
  'Host surname': 'Apellido del anfitrión',
  'Host email': 'Email del anfitrión',  
  'Guest name': 'Nombre del invitado',
  'Guest surname': 'Apellido del invitado',
  'Guest email': 'Email del invitado',  
  'Guest phone': 'Teléfono del invitado',
  'Guest document': 'Documento del invitado',
  'Guest CPF': 'CURP del invitado',
  'Host company': 'Empresa del anfitrión',
  'Guest company': 'Empresa del invitado',
  'Export': 'exportar',
  'Order:': 'Ordenar:',
  'Badge number': 'Numero de placa',
  'Guest badge number': 'Numero de placa del invitado',
  'Date of visit': 'Fecha de la visita',
  'Start time': 'Hora de inicio',
  'Finish time': 'Hora de finalización',
  'Document number': 'Número del documento',
  'Document type': 'Tipo de Documento',
  'Date and time format': 'DD/MM/YYYY HH:mm',
  'Return': 'Regreso',
  'Are you sure about that?': '¿Estás seguro de eso?',
  'Access code linked to another user': 'Código de acceso vinculado a otro usuario',
  'Associate with new user': 'Unirse a un nuevo usuario ',
  'Unlinked user': 'Unlinked user',
  'Taking photo': 'Tomar foto',
  'Send image': 'Enviar imagen',
  'Max date Error': 'Período máximo de 3 meses',
  'Min date Error': 'No puede ser inferior a la fecha de inicio'
};
