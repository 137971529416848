import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { COMPANY_MANAGER } from '../../constants';

export default function({ company }) {
  const hasRole = company.Roles.find(r => r.role === COMPANY_MANAGER);
  const { t } = useTranslation();

  if (!hasRole) {
    return null;
  }

  return (
    <Button
      component={Link}
      variant="outlined"
      size="small"
      fullWidth
      color="primary"
      style={{ marginTop: '5px' }}
      to={`/app/buildings/${company.building_id}/companies/${company.id}/users`}
    >
      {t('Manage users')}
    </Button>
  );
}