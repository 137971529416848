import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt';

export function groupByDate(records, language) {
  moment.locale(language);

  const dataobj = records
    .reduce((acc, cur) => {
      const start = moment(cur.date_from).startOf('week');
      const end = moment(cur.date_from).endOf('week');
      const key = `${start.format('DD')} ${start.format('MMM')} - ${end.format('DD')} ${end.format('MMM')}`;
      const datekey = moment(cur.date_from).format('ddd DD');

      if (!acc[key]) {
        acc[key] = {};
      }

      if (!acc[key][datekey]) {
        acc[key][datekey] = [];
      }


      acc[key][datekey].push(cur);

      return acc;
    }, {});

  return Object.keys(dataobj).map((week) => {
    const weekly = Object.keys(dataobj[week]).map((day) => {
      return {
        day,
        records: dataobj[week][day],
      };
    });

    return {
      week,
      weekly,
    };
  });
}
