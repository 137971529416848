import * as React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Paper,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '10%',
    textTransform: 'lowercase',

    [theme.breakpoints.up('sm')]: {
      marginTop: '2%',
    },
  },
  paper: {
    padding: '10px',
    paddingBottom: '20px',
    marginBottom: '10px',
  },
  title: {
    marginBottom: '20px',
  },
}));

export default () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={10} lg={6} className={classes.root}>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" align="center">
            {t('My stats')}
          </Typography>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <Link
                to="/app/stats/visitors"
                className="gray-text block"
              >
                <i className="fa fa-address-book-o fa-fw"></i> {t('Visitors')}
                <span className="pull-right">
                  <i className="fa fa-chevron-right"></i>
                </span>
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/app/stats/stay"
                className="gray-text block"
              >
                <i className="fa fa-thumb-tack fa-fw"></i> {t('Visitors stay')}
                <span className="pull-right">
                  <i className="fa fa-chevron-right"></i>
                </span>
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/app/stats/registration"
                className="gray-text block"
              >
                <i className="fa fa-address-card-o fa-fw"></i> {t('Registration')}
                <span className="pull-right">
                  <i className="fa fa-chevron-right"></i>
                </span>
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/app/stats/access-list"
                className="gray-text block"
              >
                <i className="fa fa-list-alt fa-fw"></i> {t('Access list')}
                <span className="pull-right">
                  <i className="fa fa-chevron-right"></i>
                </span>
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/app/stats/guards-allowed-access"
                className="gray-text block"
              >
                <i className="fa fa-shield fa-fw"></i> {t('Access allowed by guards')}
                <span className="pull-right">
                  <i className="fa fa-chevron-right"></i>
                </span>
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/app/stats/who-visited-who"
                className="gray-text block"
              >
                <i className="fa fa-users fa-fw"></i> {t('who.visited.who')}
                <span className="pull-right">
                  <i className="fa fa-chevron-right"></i>
                </span>
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/app/stats/employees-per-company"
                className="gray-text block"
              >
                <i class="fa fa-building fa-fw"></i> {t('Employees per company')}
                <span className="pull-right">
                  <i className="fa fa-chevron-right"></i>
                </span>
              </Link>
            </li>
          </ul>
        </Paper>
      </Grid>
    </Grid>
  )
}