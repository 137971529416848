import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  Grid,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import { Input, Password } from '../Shared/BsInput';
import { LoginSchema, TwoStepSchema } from '../../validation-schemas';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
}) => {
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit} autoComplete="new-password">
      <input type="hidden" value="something" />
      <Input
        type="text"
        value={values.username}
        onChange={handleChange}
        name="username"
        label={t('Email or cellphone number')}
        errors={errors}
      />
      <Password
        value={values.password}
        onChange={handleChange}
        name="password"
        label={t('Password')}
        errors={errors}
      />
      <Typography align="right" className="my-2" variant="body2">
        <Link className={classes.link} to="/request-password-recovery">{t('I forgot my password')}</Link>
      </Typography>

      <Button
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting}
        fullWidth
      >
        {t('Login')}
      </Button>
    </form>
  );
};

const RenderFormTwo = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="new-password">
      <input type="hidden" value="something" />
      <Input
        type="text"
        value={values.code}
        onChange={handleChange}
        name="code"
        label={t('Verification code')}
        errors={errors}
      />
      <Button
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting}
        fullWidth
      >
        {t('Login')}
      </Button>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  logo: {
    textAlign: 'center',
    marginTop: '15%',
    marginBottom: '30px',

    '& img': {
      maxWidth: '60%',
    },
  },
  download: {
    maxWidth: '25%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '20%',
    },

    [theme.breakpoints.down('xs')]: {
      maxWidth: '35%',
    },
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'rgba(87,87,87,0.1)',
  },
  toolBar: {
    minHeight: '50px',
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    fontWeight: 'bold',

    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  },
}));

const StepOne = ({ onSubmit, initialValues, forcedLogout }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container direction="row" justify="center" spacing={3}>
      <Grid item xs={12} sm={8} md={4}>
        <div className={classes.logo}>
          <img src="/logo-alt.png" alt="logo" />
          <Typography variant="h6">
            {t('your best welcome')}
          </Typography>
        </div>
        {
          forcedLogout && (
            <div className="alert alert-danger text-center">
              {t('your session expired. Please log in again')}
            </div>
          )
        }
        <Formik
          initialValues={initialValues}
          validationSchema={LoginSchema}
          enableReinitialize={true}
          onSubmit={
            async (values, { setSubmitting, setErrors }) => {
              try {
                await onSubmit(values);
              } catch (err) {
                if (err.response && err.response.data.errors) {
                  setErrors(err.response.data.errors);
                }

                setSubmitting(false);
              }
            }
          }
          render={p => <RenderForm {...p} {...{ t }}/>}
        />
        <hr />
      </Grid>
    </Grid>
  );
};

const StepTwo = ({ onSubmit, initialValues, restart }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container direction="row" justify="center" spacing={3}>
      <Grid item xs={12} sm={8} md={4}>
        <div className={classes.logo}>
          <img src="/logo-alt.png" alt="logo" />
          <Typography variant="h6">
            {t('your best welcome')}
          </Typography>
        </div>
        <Alert severity="info" elevation={3}>
          {t('You are trying to log in from an unknown device. We have sent you an email with a verification code. Please input the code below to complete your login')}
        </Alert>
        <Formik
          initialValues={initialValues}
          validationSchema={TwoStepSchema}
          enableReinitialize={true}
          onSubmit={
            async (values, { setSubmitting, setErrors }) => {
              try {
                await onSubmit(values);
              } catch (err) {
                if (err.response && err.response.data.errors) {
                  setErrors(err.response.data.errors);
                }

                setSubmitting(false);
              }
            }
          }
          render={p => <RenderFormTwo {...p} {...{ t }}/>}
        />
        <Typography component="div" variant="body2" align="center">
          <a href="" onClick={restart}>
            {t('Go back')}
          </a>
        </Typography>
        <hr />
      </Grid>
    </Grid>
  );
};

export default ({ onSubmit, forcedLogout, restart, step, form }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <React.Fragment>
      {
        step === 1 && (
          <StepOne onSubmit={onSubmit} initialValues={form} forcedLogout={forcedLogout} />
        )
      }
      {
        step === 2 && (
          <StepTwo onSubmit={onSubmit} initialValues={form} restart={restart} />
        )
      }
      <Grid container direction="row" justify="center" spacing={3}>
        <Grid item xs={12} sm={8} md={4}>
          <div className="text-center">
            <Typography>
              {t('Download the Podesubir app here')}
            </Typography>
            <a href="https://apps.apple.com/us/app/podesubir-app/id1494951757?ls=1">
              <img src="https://api.puedesubir.com/static/app-store.png" className={classes.download} alt="apple" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.app.podesubirapp">
              <img src="https://api.puedesubir.com/static/google-play.png" className={classes.download} alt="google" />
            </a>
          </div>
        </Grid>
      </Grid>
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Typography align="center" variant="body2" component="div" style={{ flex: 1 }}>
            {t('You don\'t have an account?')} <Link className={classes.link} to="/register">{t('Register here')}</Link>
          </Typography>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}