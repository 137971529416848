import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { useStyles } from '../Meetings/meeting-card';

export default ({ invitation, cancel, confirm, reject }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const bigScreen = useMediaQuery(theme => theme.breakpoints.up('md'), { noSsr: true });
  const url = invitation.Host.profile_picture || '/profile-placeholder.png';

  return (
    <>
      {
        invitation.role_in_invitation === 'Secretary' && (
          <Box px={2} py={1} textAlign="center" className={classes.secretaryContainer} boxShadow={2}>
            <Box flex={1} className={classes.secretary}>
              <Typography component="span">
                {t('agenda belongs to')}
              </Typography>
              <img src={`${invitation.Guest.profile_picture || '/profile-placeholder.png'}?w=50&h=50`} />
              <Typography component="span">
                {invitation.Guest.name} {invitation.Guest.surname}
              </Typography>
            </Box>
          </Box>
        )
      }
      <Paper
        className={classes.paper}
        onClick={(e) => {
          e.stopPropagation();

          history.push(`/app/invitations/${invitation.id}`);
        }}
        elevation={3}
      >
        <Grid item container spacing={1}>
          <Grid item xs={3} md={2} lg={1}>
            <div className={classes.thumbContainer}>
              <img src={`${url}?w=300&h=300`} alt="guest-0" className={classes.thumbSingle} />
            </div>
          </Grid>
          <Grid item xs={9} md={10} lg={11} style={{ position: 'relative' }}>
            <div className={classes.actions}>
              <div className={classes.chipContainer}>
                <span className={classes.chip}>
                  <i className="fa fa-download"></i> {t('You were invited')}
                </span>
              </div>
              <div className={classes.buttonsContainer}>
                {
                  invitation.can_confirm && confirm && !bigScreen &&
                  <Button
                    className={[classes.iconBtn, classes.iconBtnSuccess].join(' ')}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();

                      confirm(invitation.id);
                    }}
                  >
                    <i className="fa fa-check"></i>
                  </Button>
                }
                &nbsp;
                {
                  invitation.can_cancel && cancel && !bigScreen &&
                  <Button
                    className={classes.iconBtn}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();

                      cancel(invitation.id);
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </Button>
                }
                &nbsp;
                {
                  invitation.can_reject && reject && !bigScreen &&
                  <Button
                    className={classes.iconBtn}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();

                      reject(invitation.id);
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </Button>
                }
              </div>
            </div>
            <Typography className={classes.subject} component="div">
              <b>{invitation.subject}</b>
              &nbsp;
              {moment(invitation.date_from).format('HH:mm')} - {moment(invitation.date_to).format('HH:mm')}
            </Typography>
            <Typography className={classes.location} component="div">
              {invitation.Building.name} - {invitation.Building.address}
            </Typography>
            {
              invitation.Company &&
              <Typography className={classes.location} component="div">
                {invitation.Company.name} - {invitation.Company.floor} - {invitation.Company.room}
              </Typography>
            }
            <Hidden smDown>
              <div className={[classes.actions, classes.webActions].join(' ')}>
                <div className={classes.buttonsContainer}>
                  <Button
                    component={Link}
                    to={`/app/invitations/${invitation.id}`}
                    color="primary"
                    variant="outlined"
                    size="small"
                    startIcon={<i className="fa fa-info-circle"></i>}
                  >
                    {t('Detail')}
                  </Button>
                  &nbsp;
                  {
                    invitation.can_cancel && cancel &&
                    <Button
                      className={classes.textBtnCancel}
                      size="small"
                      variant="outlined"
                      startIcon={<i className="fa fa-times"></i>}
                      onClick={(e) => {
                        e.stopPropagation();

                        cancel(invitation.id);
                      }}
                    >
                      {t('Cancel')}
                    </Button>
                  }
                </div>
              </div>
            </Hidden>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
