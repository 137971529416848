import * as React from 'react';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import ContainerCard from '../Shared/container-card';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import NoRecords from '../Shared/NoRecords';
import { Select } from '../Shared/BsInput';
import { StatsVisitorsSchema } from '../../validation-schemas';
import Tabs from './tabs';
import BuildingsSelect from './buildings-select';
import { Link } from 'react-router-dom';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
  isValid,
  setFieldValue,
  buildings,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid spacing={3} container>
        <Grid item xs={12} sm={6} md={4}>
          <DatePicker
            format={'DD-MM-YYYY'}
            value={values.from}
            disableFuture
            views={["year", "month", "date"]}
            onChange={(value) => {
              setFieldValue('from', value);
            }}
            fullWidth
            autoOk
            margin="normal"
            label={t('Date from')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DatePicker
            format={'DD-MM-YYYY'}
            value={values.to}
            disableFuture
            views={["year", "month", "date"]}
            onChange={(value) => {
              setFieldValue('to', value);
            }}
            fullWidth
            autoOk
            margin="normal"
            label={t('Date to')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Select
            className="pt-3"
            value={values.group}
            onChange={handleChange}
            name="group"
            label={t('Group data by')}
            errors={errors}
            options={[
              { value: 'month', label: t('Month') },
              { value: 'week', label: t('Week') },
              { value: 'day', label: t('Day') },
              { value: 'hour', label: t('Hour') },
            ]}
          />
        </Grid>
        <BuildingsSelect
          {...{
            errors,
            values,
            handleChange,
            setFieldValue,
            t,
            buildings,
          }}
        />
        <Grid item xs={12} md={2}>
          <Button
            className="mt-4"
            variant="contained"
            color="default"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            {t('Show')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({ onSubmit, buildings, form, chart, empty }) => {
  const { t } = useTranslation();

  if (!buildings.length) {
    return (
      <div className="alert alert-info text-center">
        {t('You need to be building manager to see reports')}
      </div>
    );
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ContainerCard
        xs={12}
        md={12}
        lg={12}
        header={
          <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between', alignItems: 'center'}}>
            <div />
            <Typography variant="h6" align="center">
              {t('Stay')}
            </Typography>
            <div className="pull-right">
              <Link to="/app/stats">
                <i className="fa fa-share fa-flip-horizontal"></i>
              </Link>
            </div>
          </div>
        }
        content={
          <>
            <Formik
              initialValues={form}
              validationSchema={StatsVisitorsSchema}
              onSubmit={
                async (values, { setSubmitting, setErrors }) => {
                  try {
                    await onSubmit(values);
                    setSubmitting(false);
                  } catch (err) {
                    if (err.response && err.response.data.errors) {
                      setErrors(err.response.data.errors);
                    }

                    setSubmitting(false);
                  }
                }
              }
              render={p => <RenderForm {...p} {...{ t, buildings }}/>}
            />
            <hr />
            {
              empty && <NoRecords />
            }

            {
              !empty && chart &&
              <div
                style={{
                  width: '90%',
                  height: '400px'
                }}
              >
                <HighchartsReact highcharts={Highcharts} options={chart} />
              </div>
            }

          </>
        }
        style={{ marginBottom: '15px' }}
      />
    </MuiPickersUtilsProvider>
  );
}
