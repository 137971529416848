import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
} from '@material-ui/core';

const styles = makeStyles(theme => ({
  paper: {
    padding: '10px',
    paddingBottom: '20px',
    marginBottom: '10px',

    [theme.breakpoints.width('md')]: {
      paddingTop: '20px',
    },
  },
  header: {
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      boxShadow: 'none',
      padding: theme.spacing(0),
    },
  },
}));

export default (props) => {
  const { xs, md, lg, header, content, style } = props;
  const classes = styles();

  return (
    <Grid container direction="row" justify="center" spacing={3} style={{marginTop: 50, ...style}}>
      <Grid item xs={xs || 12} md={md || 10} lg={lg || 6} className={classes.root}>
        <Paper elevation={3} className={classes.paper}>
          {header}
          {content}
        </Paper>
      </Grid>
    </Grid>
  );
}
