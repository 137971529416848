import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';
import InvitationsList from '../../components/Invitations/received';
import * as InvitationsApi from '../../api/invitations';
import { groupByDate } from '../../logic';

class InvitationsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invitations: [],
      pages: 0,
      page: 1,
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.confirm = this.confirm.bind(this);
    this.cancel = this.cancel.bind(this);
    this.reject = this.reject.bind(this);
  }

  async onPageChange({ page }) {
    const { data, headers } = await InvitationsApi.listReceivedInvitations({
      limit: 10,
      offset: (page - 1) * 10,
    });

    const pages = Math.ceil(parseInt(headers['x-total-count'], 10) / 10);

    this.setState({
      invitations: this.state.invitations.concat(data),
      pages,
      page,
    });
  }

  async confirm(invidationId) {
    const onConfirmClick = async () => {
      await InvitationsApi.confirm(invidationId);

      toast.success(this.props.t('The assistance to the invitation has been confirmed'));
      this.setState({ invitations: [] });
      this.onPageChange({ page: 1 });
    };

    confirmAlert({
      message: this.props.t('Confirm assistance?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  async cancel(invidationId) {
    const onConfirmClick = async () => {
      await InvitationsApi.cancel(invidationId);

      toast.success(this.props.t('The invitation has been cancelled'));
      this.setState({ invitations: [] });
      this.onPageChange({ page: 1 });
    };

    confirmAlert({
      message: this.props.t('Cancel invitation?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  async reject(invidationId) {
    const onConfirmClick = async () => {
      await InvitationsApi.reject(invidationId);

      toast.success(this.props.t('The invitation has been rejected'));
      this.setState({ invitations: [] });
      this.onPageChange({ page: 1 });
    };

    confirmAlert({
      message: this.props.t('Reject invitation?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  async componentDidMount() {
    this.onPageChange({ page: 1 });
  }

  render() {
    const { i18n } = this.props;
    const language = i18n.language.substr(0, 2);

    const dataset = groupByDate(this.state.invitations, language);

    return (
      <InvitationsList
        invitations={dataset}
        total={this.state.invitations.length}
        pages={this.state.pages}
        page={this.state.page}
        onPageChange={this.onPageChange}
        confirm={this.confirm}
        cancel={this.cancel}
        reject={this.reject}
      />
    );
  }
}

export default withTranslation()(InvitationsContainer);