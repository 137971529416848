import * as React from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import VisitorsList from '../../components/Stats/access-list';
import * as StatsApi from '../../api/stats';
import buildings from '../../reducers/buildings';

class VisitorsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      buildings: [],
      form: {
        building_id: null,
        from: moment().startOf('month'),
        to: moment().endOf('day'),
      },
      data: null,
      empty: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    this.setState({
      form: body
    });

    const { from, to, building_id } = body;
    const res = await StatsApi.visitorsList(building_id, { from, to });

    this.setState({ data: res.data });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.buildings && props.buildings.length && !state.form.building_id) {
      return {
        form: {
          ...state.form,
          building_id: props.buildings[0].id,
        },
      };
    }

    return null;
  }

  async componentDidMount() {
    const { i18n } = this.props;
    const language = i18n.language.substr(0, 2);
    moment.locale(language);
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }

    return (
      <VisitorsList
        buildings={this.props.buildings}
        onSubmit={this.onSubmit}
        form={this.state.form}
        data={this.state.data}
      />
    );
  }
}

const mapStateToProps = (state) => ({
    buildings: state.buildings.recordsAccessList?.filter((b) => b.permissions.reports) || [],
});

export default withTranslation()(connect(mapStateToProps)(VisitorsContainer));
