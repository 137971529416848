import { SET_BUILDINGS } from '../constants';

const defaultState = {
  records: [],
  fetchedAt: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_BUILDINGS: {
      return {
        records: action.payload.buildings,
        recordsAccessList: action.payload.buildingsAccessList,
        fetchedAt: new Date(),
      };
    }
    default: {
      return state;
    }
  }
}
