import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';
import SecretariesIndex from '../../components/Secretaries/index';
import * as SecretariesApi from '../../api/secretaries';

class SecretariesIndexContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      secretaries: [],
      pages: 0,
      page: 1,
      isLoading: true,
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  async onDelete(secretary) {
    const onConfirmClick = async () => {
      await SecretariesApi.remove(secretary.building_id, secretary.secretary_id);

      toast.success(this.props.t('The user has been removed from your secretaries'));
      this.onPageChange({ page: 1 });
    };

    confirmAlert({
      message: this.props.t('Remove user from your secretaries?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  async onPageChange({ page }) {
    const { data, headers } = await SecretariesApi.list({
      limit: 10,
      offset: (page - 1) * 10,
    });

    const pages = parseInt(headers['x-total-count'], 10);

    this.setState({
      secretaries: data,
      pages,
      page,
    });
  }

  async componentDidMount() {
    this.onPageChange({ page: 1 });
  }

  render() {
    return (
      <SecretariesIndex
        secretaries={this.state.secretaries}
        isLoading={this.state.isLoading}
        pages={this.state.pages}
        page={this.state.page}
        onPageChange={this.onPageChange}
        onDelete={this.onDelete}
      />
    );
  }
}

export default withTranslation()(SecretariesIndexContainer);
