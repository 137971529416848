import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  TableCell,
  TableRow,
  Button,
  Typography,
} from '@material-ui/core';
import NoRecords from '../Shared/NoRecords';
import ListTable from '../Shared/list-table';
import ContainerCard from '../Shared/container-card';

const getName = (secretary) => {
  if (secretary.name && secretary.surname) {
    return `${secretary.name} ${secretary.surname}`;
  }

  return `${secretary.email || secretary.cellphone}`;
}

const RenderTable = ({
  onPageChange, pages, secretaries, t, onDelete, page,
}) => {
  return (
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell>{t('Secretary')}</TableCell>
          <TableCell>{t('Building')}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      }
      body={
        <React.Fragment>
          {
            secretaries.map((secretary, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{getName(secretary.Secretary)}</TableCell>
                  <TableCell>{secretary.Building.name}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="secondary"
                      type="button"
                      size="small"
                      onClick={() => onDelete(secretary)}
                    >
                      {t('Remove')}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })
          }
        </React.Fragment>
      }
    />
  );
};

export default ({ onPageChange, pages, page, secretaries, onDelete }) => {
  const { t } = useTranslation();

  return (
    <ContainerCard
      xs={12}
      md={12}
      lg={12}
      header={
        <Typography variant="h6" align="center">
          {t('My secretaries')}
          <div className="pull-right">
            <Link to="/app/account-configuration">
              <i className="fa fa-share fa-flip-horizontal"></i>
            </Link>
          </div>
        </Typography>
      }
      content={
        <React.Fragment>
          <div className="text-right">
            <Button
              size="small"
              variant="outlined"
              color="primary"
              type="button"
              component={Link}
              to="/app/secretaries/create"
            >
              {t('New secretary')}
            </Button>
            <hr />
          </div>
          {
            !secretaries.length && <NoRecords />
          }
          {
            !!secretaries.length &&
            <RenderTable
              {...{ onPageChange, pages, page, secretaries, t, onDelete }}
            />
          }
        </React.Fragment>
      }
    />
  );
}
