import * as React from 'react';
import { Formik } from 'formik';
import {
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Password } from '../Shared/BsInput';
import { ChangePwdSchema } from '../../validation-schemas';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Password
        value={values.password}
        onChange={handleChange}
        name="password"
        label={t('Password')}
        errors={errors}
      />
      <Password
        value={values.password_confirm}
        onChange={handleChange}
        name="password_confirm"
        label={t('Confirm password')}
        errors={errors}
      />
      <Button
        className="mt-2"
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting}
        fullWidth
      >
        {t('Change password')}
      </Button>
    </form>
  );
};

export default ({ onSubmit, success }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" spacing={3} style={{ marginBottom: '20%' }}>
        <Grid item xs={12} sm={8} md={4}>
          <Typography variant="h6" align="center">
            {t('Password recovery')}
          </Typography>
          {
            success &&
            <div className="alert alert-success text-center mb-3">
              <Typography>
                {t('Your password as been restored. You can now access with your new password')}
              </Typography>
            </div>
          }
          {
            !success &&
            <div>
              <Formik
                initialValues={{
                  password: '',
                  password_confirm: '',
                }}
                validationSchema={ChangePwdSchema}
                enableReinitialize={true}
                onSubmit={
                  async (values, { setSubmitting, setErrors }) => {
                    try {
                      await onSubmit(values);
                    } catch (err) {
                      if (err.response && err.response.data.errors) {
                        setErrors(err.response.data.errors);
                      }

                      setSubmitting(false);
                    }
                  }
                }
                render={p => <RenderForm {...p} {...{ t }}/>}
              />
            </div>
          }
        </Grid>
      </Grid>
    </React.Fragment>
  );
}