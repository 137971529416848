import * as React from 'react';
import { Formik } from 'formik';
import { Button } from '@material-ui/core';
import countriesEN from 'i18n-iso-countries/langs/en.json';
import countriesES from 'i18n-iso-countries/langs/es.json';
import countriesPT from 'i18n-iso-countries/langs/pt.json';
import { useTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Input, MaskedInput, Select } from '../Shared/BsInput';
import { ProfileSchema } from '../../validation-schemas';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
  setFieldValue,
  countries,
}) => {
  let label = 'Passport';

  if (values.id_type === 'rg') {
    label = <span style={{ textTransform: 'uppercase' }}>{t('RG')}</span>;
  } else if (values.id_type === 'rne') {
    label = <span style={{ textTransform: 'uppercase' }}>{t('RNE')}</span>;
  } else if (values.id_type === 'hab') {
    label = 'Habilitaçao';
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        type="text"
        value={values.name}
        onChange={handleChange}
        name="name"
        label={t('Name')}
        errors={errors}
        disabled
      />
      <Input
        type="text"
        value={values.surname}
        onChange={handleChange}
        name="surname"
        label={t('Surname')}
        errors={errors}
        disabled
      />
      <Select
        value={values.nationality}
        onChange={handleChange}
        name="nationality"
        label={t('Country of residence')}
        errors={errors}
        options={Object.keys(countries.countries).map((key) => ({
          label: Array.isArray(countries.countries[key]) ? countries.countries[key][0] : countries.countries[key],
          value: key,
        }))}
        margin="dense"
        disabled
      />
      <DatePicker
        value={values.birthdate}
        disableFuture
        onChange={(value) => {
          setFieldValue('birthdate', value);
        }}
        ampm={false}
        fullWidth
        margin="normal"
        format="DD-MM-YYYY"
        views={["year", "month", "date"]}
        label={t('Birthdate')}
        openTo="year"
        disabled
      />
      <Input
        type="email"
        value={values.email}
        onChange={handleChange}
        name="email"
        label={t('Email')}
        errors={errors}
        disabled={true}
      />
      <Input
        type="text"
        value={values.cellphone}
        onChange={handleChange}
        name="cellphone"
        label={'Cellphone'}
        errors={errors}
        disabled={true}
      />
      <Select
        value={values.id_type}
        onChange={handleChange}
        name="id_type"
        label={'ID Type'}
        errors={errors}
        options={[
          { value: 'rg', label: (<span style={{ textTransform: 'uppercase' }}>{t('RG')}</span>) },
          { value: 'rne', label: (<span style={{ textTransform: 'uppercase' }}>{t('RNE')}</span>) },
          { value: 'hab', label: t('Habilitaçao') },
          { value: 'passport', label: t('Passport') },
        ]}
        disabled
      />
      <Input
        type="text"
        value={values.id_number}
        onChange={handleChange}
        name="id_number"
        label={label}
        errors={errors}
        disabled
      />

      {
        values.nationality === 'BR' && (
          <MaskedInput
            type="text"
            placeholder="xxx.xxx.xxx-xx"
            value={values.cpf}
            onChange={handleChange}
            name="cpf"
            id="cpf-input"
            label={<span style={{ textTransform: 'uppercase' }}>{t('CPF number')}</span>}
            errors={errors}
            disabled
          />
        )
      }
      <Input
        type="text"
        value={values.company}
        onChange={handleChange}
        name="company"
        label={t('Company')}
        errors={errors}
      />
      <Input
        type="text"
        value={values.company_role}
        onChange={handleChange}
        name="company_role"
        label={t('Company role')}
        errors={errors}
      />
      <hr />
      <Button
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting}
        fullWidth
      >
        {t('Save')}
      </Button>
    </form>
  );
};

export default ({ onSubmit, isLoading, profile }) => {
  const { t, i18n } = useTranslation();

  if (isLoading) {
    return null;
  }

  const lang = i18n.language.substr(0, 2);

  let countries = countriesEN;
  if (lang === 'pt') {
    countries = countriesPT;
  } else if (lang === 'es') {
    countries = countriesES;
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Formik
        initialValues={profile}
        enableReinitialize={true}
        validationSchema={ProfileSchema}
        onSubmit={
          async (values, { setSubmitting, setErrors }) => {
            try {
              const data = _.pick(values, [
                'company', 'company_role',
              ]);

              await onSubmit(data);
              setSubmitting(false);
            } catch (err) {
              if (err.response && err.response.data.errors) {
                setErrors(err.response.data.errors);
              }

              setSubmitting(false);
            }
          }
        }
        render={p => <RenderForm {...p} {...{ t, countries }}/>}
      />
    </MuiPickersUtilsProvider>

  );
}