import { TOS_ACCEPTED, UPDATE_USER_DATA } from '../constants';
import * as UsersApi from '../api/users';
import i18n from '../i18n';

export function updateUserData(data) {
  return {
    data,
    type: UPDATE_USER_DATA,
  };
}

export function tosAccepted() {
  return {
    type: TOS_ACCEPTED,
  };
}

export function updateUserLanguage(language) {
  return async (dispatch, getState) => {
    i18n.changeLanguage(language);

    const auth = getState().auth;
    
    if (auth.loggedIn && !!auth.user.profile_approved_at) {
      UsersApi.updateProfile({
        language
      });
    }
  };
}
