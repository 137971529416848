import React from 'react';
import ismobile from 'is-mobile';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Webcam from 'webcam-easy';
import ReactCrop from 'react-image-crop';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import 'react-image-crop/dist/ReactCrop.css';

const styles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  input: {
    display: 'none',
  },
  textRight: {
    textAlign: 'right',
  },
  camera: {
    width: '100%',
    '-webkit-transform': 'scaleX(1) !important',
    transform: 'scaleX(1) !important',
  },
  snapButton: {
    backgroundColor: '#333',
    color: 'white',
    borderRadius: '100px',
    padding: 0,
    width: '50px',
    height: '50px',
    '-webkit-box-shadow': '0 0 10px #fff',
    boxShadow: '0 0 10px #fff',
    border: 'none',
  },
  rotateButton: {
    backgroundColor: '#333',
    padding: 0,
    width: '40px',
    height: '40px',
    '-webkit-box-shadow': '0 0 10px #fff',
    boxShadow: '0 0 10px #fff',
    border: 'none',
  },
}));

const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

function rotateBase64Image90deg(base64Image, isClockwise, cb) {
  // create an off-screen canvas
  const offScreenCanvas = document.createElement('canvas');
  const offScreenCanvasCtx = offScreenCanvas.getContext('2d');

  // cteate Image
  var img = new Image();
  img.src = base64Image;

  img.onload = function () {
    // set its dimension to rotated size
    offScreenCanvas.height = img.height;
    offScreenCanvas.width = img.width;

    // rotate and draw source image into the off-screen canvas:
    offScreenCanvasCtx.scale(-1, 1);
    offScreenCanvasCtx.drawImage(img, -img.width, 0);

    // encode image to data-uri with base64
    return cb(offScreenCanvas.toDataURL());
  };
}

const getCroppedImg = (image, crop, filename, type) => {
  const cropWidth = image.naturalWidth * (crop.width / 100);
  const cropHeight = image.naturalHeight * (crop.height / 100);
  const startX = image.naturalWidth * (crop.x / 100);
  const startY = image.naturalHeight * (crop.y / 100);

  const canvas = document.createElement('canvas');
  canvas.width = cropWidth;
  canvas.height = cropHeight;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(image, startX, startY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

  // const base64Image = canvas.toDataURL();
  // return base64Image;
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        blob.name = filename;
        return resolve(blob);
      },
      type,
      0.85,
    );
  });
};

const UploadImageDialog = (props) => {
  const { onConfirm, opened, handleClose, btnLabel } = props;
  const { t } = useTranslation();
  const imgRef = React.useRef(null);
  const webcamRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  const rotationCanvas = React.useRef(null);
  const [webcam, setWebcam] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [preview, setPreview] = React.useState(null);
  const [crop, setCrop] = React.useState({ unit: '%', width: 100, height: 100 });
  const [completedCrop, setCompletedCrop] = React.useState(null);
  const [devices, setDevices] = React.useState(0);
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const restart = () => {
    setFile(null);
    setPreview(null);
    setCrop({ unit: '%', width: 100, height: 100 });
    setCompletedCrop(null);

    if (webcam) {
      webcam.stop();
    }
  };
  const onClose = () => {
    restart();
    handleClose();
  };

  const listDevices = async () => {
    const d = await navigator.mediaDevices.enumerateDevices();

    const video = d.filter((dd) => dd.kind === 'videoinput');

    setDevices(video.length);
  };

  const startWebcam = async () => {
    try {
      restart();

      const cam = new Webcam(webcamRef.current, 'user', canvasRef.current);

      await cam.start();

      setWebcam(cam);
    } catch (err) {
      console.error(err);
    }
  };

  const takeSnap = async () => {
    if (!webcam) {
      await startWebcam();
    }

    const picture = webcam.snap();

    webcam.stop();

    if (ismobile()) {
      const file = dataURLtoFile(picture, 'camera-capture.png');

      const reader = new FileReader();

      reader.onloadend = () => {
        setFile(file);
        setPreview(reader.result);
      };

      reader.readAsDataURL(file);
    } else {
      rotateBase64Image90deg(picture, true, (rotated) => {
        const file = dataURLtoFile(rotated, 'camera-capture.png');

        const reader = new FileReader();

        reader.onloadend = () => {
          setFile(file);
          setPreview(reader.result);
        };

        reader.readAsDataURL(file);
      });
    }
  };

  const flipCamera = () => {
    webcam.flip();
    webcam.stop();
    webcam.stream();
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (opened && canvasRef && canvasRef.current) {
        listDevices();
        startWebcam();
      }
    }, 200);
  }, [opened]);

  return (
    <Dialog
      open={opened}
      onClose={onClose}
      fullWidth
      maxWidth={'sm'}
      scroll='body'
      fullScreen={matches}
    >
      <Paper className={classes.paper}>
        <div className={classes.textRight}>
          <IconButton size='small' onClick={onClose}>
            <i className='fa fa-times'></i>
          </IconButton>
        </div>
        {!preview && !webcam && (
          <Alert severity='info'>Allow the website to use your camera to continue</Alert>
        )}
        <Box style={{ position: 'relative', display: preview ? 'none' : 'block' }}>
          <canvas ref={rotationCanvas} style={{ display: 'none' }}></canvas>
          <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
          <video ref={webcamRef} autoPlay playsInline className={classes.camera}></video>
          <Box textAlign='center' style={{ position: 'absolute', bottom: '20px', width: '100%' }}>
            <button className={classes.snapButton} type='button' onClick={takeSnap}>
              <i className='fa fa-camera' />
            </button>
            {devices > 0 && (
              <Box style={{ position: 'absolute', right: '0' }}>
                <button type='button' className={classes.rotateButton} onClick={flipCamera}>
                  <img src='/camera_flip_white.png' width='40px' />
                </button>
              </Box>
            )}
          </Box>
        </Box>
        <Box textAlign='center' mt={2}>
          <img src={preview} ref={imgRef} style={{ display: 'none' }} alt='hidden' />

          {!!preview && (
            <ReactCrop
              src={preview}
              crop={crop}
              onChange={(pixel, percent) => {
                setCrop(percent);
              }}
              onComplete={(pixel, percent) => {
                setCompletedCrop(percent);
              }}
            />
          )}
        </Box>
        {!!preview && (
          <>
            <Button
              type='button'
              component='label'
              variant='outlined'
              color='primary'
              onClick={startWebcam}
            >
              {t('New picture')}
            </Button>
            &nbsp;
            <Button
              type='button'
              component='label'
              variant='contained'
              color='primary'
              onClick={async () => {
                const blob = await getCroppedImg(
                  imgRef.current,
                  completedCrop,
                  file.name,
                  file.type,
                );

                onConfirm(blob);
                onClose();
              }}
            >
              {!!btnLabel && <>{btnLabel}</>}
              {!btnLabel && <>{t('Confirm and save')}</>}
            </Button>
          </>
        )}
      </Paper>
    </Dialog>
  );
};

export default UploadImageDialog;
