import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import Login from '../Login';
import Register from '../Register';
import VerifyEmail from '../Register/verify-email';
import ReqPwdRecovery from '../Login/request-pwd-recovery';
import PwdRecovery from '../Login/pwd-recovery';
import AccountConfig from '../Profile/config';
import Profile from '../Profile';
import ChangePwd from '../Profile/change-password';
import ChangeEmail from '../Profile/change-email';
import ChangePhone from '../Profile/change-phone';

import Dashboard from '../Dashboard';
import BuildingView from '../Buildings/view';
import BuildingIndex from '../Buildings/index';
import BuildingUsers from '../Buildings/users';
import BuildingUsersAdd from '../Buildings/add-user';
import BuildingUsersEdit from '../Buildings/edit-user';
import CompanyUsersAdd from '../Companies/add-user';
import CompanyUsersEdit from '../Companies/edit-user';
import CompaniesIndex from '../Companies/index';
import CompaniesCreate from '../Companies/create';
import CompaniesEdit from '../Companies/edit';
import CompaniesUsers from '../Companies/users';
import CompaniesMine from '../Companies/mine';
import CompaniesView from '../Companies/view';

import Invitations from '../Meetings/sent';
import MeetingsView from '../Meetings/view';
import MeetingsEdit from '../Meetings/edit';
import InvitationsReceived from '../Invitations/received';
import MeetingsCreate from '../Meetings/create';
import InvitationsView from '../Invitations/view';
import MeetingsAll from '../Meetings/all';

import SecretariesIndex from '../Secretaries';
import SecretariesCreate from '../Secretaries/create';

import CarEdit from '../Car/edit';

import Tabs from '../../components/Stats/tabs';
import WhoIsVisitedWho from '../Stats/who-is-visited-who';
import EmployeesPerCompany from '../Stats/employees-per-company';
import StatsVisitors from '../Stats/visitors';
import StatsStay from '../Stats/stay';
import StatsRegistration from '../Stats/registration';
import StatsAccessList from '../Stats/access-list';
import GuardsAllowedAccess from '../Stats/guards-allowed-access';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    rest.loggedIn
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
);

export const PublicOnlyRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    rest.loggedIn
      ? <Redirect to='/app' />
      : <Component {...props} />
  )} />
);

export default ({ loggedIn }) => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/app"
        component={Dashboard}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/account-configuration"
        component={AccountConfig}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/profile"
        component={Profile}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/change-password"
        component={ChangePwd}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/change-email"
        component={ChangeEmail}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/change-phone"
        component={ChangePhone}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings"
        component={BuildingIndex}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId"
        component={BuildingView}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/users"
        component={BuildingUsers}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/users/add/:email?"
        component={BuildingUsersAdd}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/users/edit/:userId"
        component={BuildingUsersEdit}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/companies"
        component={CompaniesIndex}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/companies/create"
        component={CompaniesCreate}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/companies/:companyId/edit"
        component={CompaniesEdit}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/companies/:companyId/users"
        component={CompaniesUsers}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/companies/:companyId/users/add/:email?"
        component={CompanyUsersAdd}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/companies/:companyId/users/edit/:userId"
        component={CompanyUsersEdit}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/companies"
        component={CompaniesMine}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/companies/:companyId"
        component={CompaniesView}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/meetings"
        component={Invitations}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/meetings/create"
        component={MeetingsCreate}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/meetings/all-events"
        component={MeetingsAll}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/meetings/:meetingId"
        component={MeetingsView}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/meetings/:meetingId/edit"
        component={MeetingsEdit}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/meetings/:meetingId/copy"
        component={MeetingsCreate}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/invitations"
        component={InvitationsReceived}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/invitations/:invitationId"
        component={InvitationsView}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/secretaries"
        component={SecretariesIndex}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/secretaries/create"
        component={SecretariesCreate}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/my-vehicle"
        component={CarEdit}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/stats"
        component={Tabs}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/stats/visitors"
        component={StatsVisitors}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/stats/who-visited-who"
        component={WhoIsVisitedWho}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/stats/employees-per-company"
        component={EmployeesPerCompany}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/stats/access-list"
        component={StatsAccessList}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/stats/stay"
        component={StatsStay}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/stats/registration"
        component={StatsRegistration}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/stats/guards-allowed-access"
        component={GuardsAllowedAccess}
        loggedIn={loggedIn}
      />
      <PublicOnlyRoute
        exact
        path="/login"
        component={Login}
        loggedIn={loggedIn}
      />
      <PublicOnlyRoute
        exact
        path="/register/:mail"
        component={Register}
        loggedIn={loggedIn}
      />
      <PublicOnlyRoute
        exact
        path="/register"
        component={Register}
        loggedIn={loggedIn}
      />
      <PublicOnlyRoute
        exact
        path="/request-password-recovery"
        component={ReqPwdRecovery}
        loggedIn={loggedIn}
      />
      <PublicOnlyRoute
        exact
        path="/password-recovery/:hash"
        component={PwdRecovery}
        loggedIn={loggedIn}
      />
      <Route
        path="/register/verify-email/:hash"
        component={VerifyEmail}
      />
      <Redirect to={loggedIn ? '/app' : '/login'} />
    </Switch>
  );
}