import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import EmailVerification from '../../containers/Register/steps/email-verification';
import CellphoneVerification from '../../containers/Register/steps/cellphone-verification';
import BasicInformation from '../../containers/Register/steps/basic-information';
import ProfilePicture from '../../containers/Register/steps/profile-picture';
import Documents from '../../containers/Register/steps/documents';
import ConfirmInformation from '../../containers/Register/steps/confirm-information';
import { REGISTRATION_STEPS_ORDER } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    '& .MuiStepLabel-labelContainer': {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  },
}));

const getStepContent = (activeStep, handleBack, handleNext) => {
  switch (activeStep) {
    case 0: {
      return <EmailVerification handleNext={handleNext} />
    }
    case 1: {
      return <CellphoneVerification />
    }
    case 2: {
      return <BasicInformation handleNext={handleNext} />
    }
    case 3: {
      return <ProfilePicture handleNext={handleNext} handleBack={handleBack} />
    }
    case 4: {
      return <Documents handleNext={handleNext} handleBack={handleBack} />
    }
    case 5: {
      return <ConfirmInformation handleBack={handleBack} handleNext={handleNext} />
    }
  }
};

export default function Steps(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = props;
  const [activeStep, setActiveStep] = React.useState(
    user.Review && user.Review.registration_steps
      ? REGISTRATION_STEPS_ORDER[user.Review.registration_steps[0]]
      : REGISTRATION_STEPS_ORDER[user.registration_step]
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  React.useEffect(() => {
    if (user.profile_rejected_at && user.Review && user.Review.registration_steps && user.Review.registration_steps.length) {
      setActiveStep(REGISTRATION_STEPS_ORDER[user.Review.registration_steps[0]]);
    } else {
      if (REGISTRATION_STEPS_ORDER[user.registration_step] !== activeStep) {
        setActiveStep(REGISTRATION_STEPS_ORDER[user.registration_step]);
      }
    }
  }, [user]);

  return (
    <div className={classes.root}>
      {
        !!user.profile_rejected_at && (
          <div>
            <div className="alert alert-danger">
              {
                !!user.Review && (
                  <>
                    {t('Your profile has been rejected by a customer support representative')}
                    <br />
                    {t('Please correct the following fields and re-submit your profile to be reviewed again')}
                    {
                      user.Review.fields
                        .map((field, i) => {
                          return (
                            <div key={i}>
                              -&nbsp;
                              {
                                field.changed && (<s>{t(field.field)}</s>)
                              }
                              {
                                !field.changed && <>{t(field.field)}</>
                              }
                            </div>
                          )
                        })
                    }
                  </>
                )
              }
              {
                !user.Review && (
                  <>
                    {t('You have performed all the requested corrections. Submit your profile for review')}
                  </>
                )
              }
            </div>
          </div>
        )
      }
      <Typography variant="h6" align="center">
      </Typography>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        classes={{
          horizontal: classes.stepper,
        }}
      >
        <Step>
          <StepLabel>
            {t('Email verification')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            {t('Cellphone verification')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            {t('Basic information')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            {t('Profile picture')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            {t('ID documents')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            {t('Finalize')}
          </StepLabel>
        </Step>
      </Stepper>
      <div>
        {getStepContent(activeStep, handleBack, handleNext)}
      </div>
      <Box mt={2} textAlign="center">
        <Typography variant="body2">
          {t('For a better experience use Google Chrome or Microsoft Edge')}
        </Typography>
      </Box>
    </div>
  );
}
