import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { onMessageListener } from '../../firebase';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Content = ({ data }) => {
  const { t } = useTranslation();

  switch (data.type) {
    case 'GDO': {
      return 'GDO';
    }
    case 'ARRIVAL_GUEST_ARRIVED': {
      return (
        <>
          {t('Your guest arrived to the meeting. Click here to see more')}
        </>
      );
    }
    case 'ARRIVAL_HOST_DELAYED': {
      return (
        <>
          {t('The host delayed your entry to the meeting. Click here to see more')}
        </>
      );
    }
    case 'ARRIVAL_HOST_ALLOWED': {
      return (
        <>
          {t('The host allowed your entry to the meeting. Click here to see more')}
        </>
      );
    }
    case 'ARRIVAL_WAITING_TIME_OVER': {
      return (
        <>
          {t('The waiting time is over. We have reminded the host about your arrival')}
        </>
      );
    }
    default: {

    }
  }
};


export default function CustomizedSnackbars() {
  const history = useHistory();
  const location = useLocation();
  const [last, setLast] = React.useState(null);
  const [queue, setQueue] = React.useState([]);
  const user = useSelector((s) => s.auth.user);

  React.useEffect(() => {
    if(navigator?.serviceWorker){
      navigator.serviceWorker.addEventListener('message', function (event) {
        const { data } = event;
        console.log('Message listener', data);
  
        if (data.data) {
          const { type } = data.data;
  
          if (['ARRIVAL_GUEST_ARRIVED', 'ARRIVAL_HOST_DELAYED', 'ARRIVAL_HOST_ALLOWED', 'ARRIVAL_WAITING_TIME_OVER'].includes(type)) {
            setLast(data);
            // return history.go(0);
          }
        }
      });
    }    
  }, []);

  React.useEffect(() => {
    if (last) {
      const { data, notification } = last;

      if (data.user_id && parseInt(data.user_id, 10) !== user.id) {
        console.log('Notification for another user?');

        return;
      }

      const meetingUrl = `/app/meetings/${data.meeting_id}`;
      const invitationUrl = `/app/invitations/${data.invitation_id}`;

      if (!notification && (location.pathname === meetingUrl || location.pathname === invitationUrl)) {
        return history.go(0);
      }

      toast.info(<Content data={data} />, {
        position: 'bottom-right',
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        onClick: () => {
          if (['ARRIVAL_HOST_DELAYED', 'ARRIVAL_HOST_ALLOWED', 'ARRIVAL_WAITING_TIME_OVER'].includes(data.type)) {
            if (location.pathname === invitationUrl) {
              history.go(0);
            } else {
              history.push(invitationUrl);
            }
          }

          if (['ARRIVAL_GUEST_ARRIVED'].includes(data.type)) {
            if (location.pathname === meetingUrl) {
              history.go(0);
            } else {
              history.push(meetingUrl);
            }
          }
        }
      });
      setQueue([
        ...queue,
        data,
      ]);
    }
  }, [last]);

  /*onMessageListener().then((msg) => {
    console.log('onMesasge', msg);

    if (msg && msg.data.type) {
      if (!['GDO', 'ARRIVAL_GUEST_ARRIVED', 'ARRIVAL_HOST_DELAYED', 'ARRIVAL_HOST_ALLOWED', 'ARRIVAL_WAITING_TIME_OVER'].includes(msg.data.type)) {
        return;
      }

      return setLast(msg);
    }
  });*/

  return null;
}