import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from 'react-i18next';
import BuildingUsers from '../../components/Buildings/users';
import * as BuildingsApi from '../../api/buildings';

class BuildingUsersContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buildingId: this.props.match.params.buildingId,
      users: [],
      pages: 0,
      page: 1,
      building: null,
      isLoading: true,
      where: {
        email: '',
        name: '',
        surname: '',
        role: '',
      },
    };

    this.onPageChange = this.onPageChange.bind(this);
  }

  async onPageChange({ page, ...rest }) {
    const { data, headers } = await BuildingsApi.users(this.state.buildingId, {
      limit: 10,
      offset: (page - 1) * 10,
      ...(!isEmpty(rest) ? rest : this.state.where),
    });

    const pages = parseInt(headers['x-total-count'], 10);

    this.setState({
      users: data,
      pages,
      page,
      where: (!isEmpty(rest) ? rest : this.state.where),
    });
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }

    return (
      <BuildingUsers
        users={this.state.users}
        pages={this.state.pages}
        page={this.state.page}
        isLoading={this.state.isLoading}
        buildingId={this.state.buildingId}
        building={this.state.building}
        onPageChange={this.onPageChange}
        removeUser={this.removeUser}
      />
    );
  }

  async componentDidMount() {
    const { data } = await BuildingsApi.get(this.state.buildingId);

    this.setState({
      building: data,
      isLoading: false,
    });

    this.onPageChange({ page: 1 });
  }
}

export default withTranslation()(BuildingUsersContainer);
