import firebase from 'firebase/app';
import 'firebase/messaging';
import querystring from 'querystring';

const {
  REACT_APP_FBC_API_KEY,
  REACT_APP_FBC_AUTH_DOMAIN,
  REACT_APP_FBC_DB_URL,
  REACT_APP_FBC_PROJECT_ID,
  REACT_APP_FBC_BUCKET,
  REACT_APP_FBC_MESSAGING_SENDER_ID,
  REACT_APP_FBC_APP_ID,
  REACT_APP_FBC_MEASUREMENT_ID,
  REACT_APP_FBC_MESSAGING_KEY
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FBC_API_KEY,
  authDomain: REACT_APP_FBC_AUTH_DOMAIN,
  databaseURL: REACT_APP_FBC_DB_URL,
  projectId: REACT_APP_FBC_PROJECT_ID,
  storageBucket: REACT_APP_FBC_BUCKET,
  messagingSenderId: REACT_APP_FBC_MESSAGING_SENDER_ID,
  appId: REACT_APP_FBC_APP_ID,
  measurementId: REACT_APP_FBC_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

let messaging = null;

if (firebase.messaging.isSupported()) {
  console.log('firebase supported');

  messaging = firebase.messaging();
}

export const onMessageListener = () => new Promise((resolve) => {
  if (messaging) {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  }
});

export const getToken = () => {
  if (!messaging) {
    return null;
  }

  if ('serviceWorker' in navigator) {
    return navigator.serviceWorker
      .register(`/firebase-messaging-sw.js?${querystring.stringify(firebaseConfig)}`)
      .then(function(registration) {
        return Notification.requestPermission().then((per) => {
          console.log(per);

          return messaging.getToken({
            vapidKey: REACT_APP_FBC_MESSAGING_KEY,
            serviceWorkerRegistration: registration,
          }).then((currentToken) => {

            if (currentToken) {
              localStorage.setItem('fcmtoken', currentToken);
            }

            return currentToken;
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
        })
      });
  }


}
