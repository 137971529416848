import * as React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Typography,
  Grid,
  Paper,
  IconButton,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChipInput from 'material-ui-chip-input';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Input, Select, Textarea, Checkbox } from '../Shared/BsInput';
import 'react-widgets/dist/css/react-widgets.css';
import { UpdateMeetingSchema, isValidEmailOrCellphone, AddInvitationSchema } from '../../validation-schemas';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '10%',

    [theme.breakpoints.up('sm')]: {
      marginTop: '2%',
    },
  },
  paper: {
    padding: '10px',
    paddingBottom: '20px',
    marginBottom: '10px',
  },
  back: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  guest: {
    padding: '5px',
    borderTop: '1px solid #cecece',
    display: 'flex',
    justifyContent: 'space-between',

    '& button': {
      padding: 0,
      color: '#b70909',
    },
  },
  btn: {
    marginTop: '39px',
  }
}));

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  t,
  isValid,
}) => {
  const verifyDisabled = () => {
    if(moment().isAfter(moment(values.date_from.date).subtract(15, 'minutes')) && values.status === 'CONFIRMED') {
      return true
    }

    return false
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        type="text"
        value={values.subject}
        onChange={handleChange}
        name="subject"
        label={t('Invitation subject/reason')}
        errors={errors}
      />
      <KeyboardDatePicker
        format={'DD-MM-YYYY'}
        value={values.date_from.date}
        disablePast
        onChange={(value) => {
          console.log(value);
          setFieldValue('date_from.date', value);

          const date = moment(
            `${moment(value).format('YYYY-MM-DD')} ${moment(values.date_from.time).format('HH:mm')}`, 'YYYY-MM-DD HH:mm'
          ).add(values.duration, 'hours');

          setFieldValue('date_to', date.toDate());
        }}
        disabled={verifyDisabled()}
        fullWidth
        margin="normal"
        style={{ marginBottom: '2.5rem' }}
        label={t('Date')}
      />
      <KeyboardTimePicker
        format={'HH:mm'}
        value={values.date_from.time}
        disablePast
        onChange={(value) => {
          if (value?.isValid) {
            setFieldValue('date_from.time', value);

            const date = moment(
              `${moment(values.date_from.date).format('YYYY-MM-DD')} ${moment(value).format('HH:mm')}`, 'YYYY-MM-DD HH:mm'
            ).add(values.duration, 'hours');

            setFieldValue('date_to', date.toDate());
          }
        }}
        disabled={verifyDisabled()}
        ampm={true}
        fullWidth
        margin="normal"
        style={{ marginBottom: '2.5rem' }}
        label={t('Time')}
      />
      <Select
        label={t('Allow access to the building for')}
        name="duration"
        value={values.duration}
        errors={errors}
        onChange={(e) => {
          const { value } = e.target;
          const date = moment(
            `${moment(values.date_from.date).format('YYYY-MM-DD')} ${moment(values.date_from.time).format('HH:mm')}`, 'YYYY-MM-DD HH:mm'
          ).add(value, 'hours');

          setFieldValue('date_to', date.toDate());
          handleChange(e);
        }}
        options={[
          { value: 1, label: t('1 hour') },
          { value: 2, label: t('2 hours') },
          { value: 3, label: t('3 hours') },
          { value: 5, label: t('5 hours') },
          { value: 12, label: t('12 hours') },
        ]}
      />
      {
        !!values.Company && values.Company.allow_arrival_announcement && (
          <Box pt={2}>
            <Checkbox
              name="announce_arrival"
              value={values.announce_arrival}
              onChange={handleChange}
              style={{ paddingLeft: 0 }}
            />&nbsp;
            <Typography component="span">
              {t('Request the guest to announce it\'s arrival to be allowed in')}
            </Typography>
          </Box>
        )
      }
      <Textarea
        value={values.notes}
        onChange={handleChange}
        name="notes"
        label={t('Notes')}
        errors={errors}
      />
      <hr />
      <Button
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting || !isValid}
        fullWidth
      >
        {t('Modify meeting')}
      </Button>
    </form>
  );
};


const RenderFormGuests = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  t,
  isValid,
}) => {
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid item container spacing={2}>
        <Grid item xs={9}>
          <ChipInput
            value={values.guest_contact}
            onAdd={(chip) => {
              if (!isValidEmailOrCellphone(chip)) {
                return;
              }

              setFieldValue('guest_contact', values.guest_contact.concat(chip));
            }}
            onDelete={(chip, index) => {
              setFieldValue('guest_contact', values.guest_contact.filter((e, i)  => i !== index));
            }}
            fullWidth
            label={t('Add guests to the meeting')}
            helperText={t('Multiple emails/cellphones separated by comma')}
            newChipKeyCodes={[9, 13, 188]}
            margin="normal"
            blurBehavior="add"
            error={errors && !!errors.guest_contact}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="outlined"
            color="default"
            type="submit"
            disabled={isSubmitting || !isValid}
            fullWidth
            className={classes.btn}
          >
            {t('Add')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default (props) => {
  const { onSubmit, isLoading, meeting, addInvitation, removeInvitation } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  if (isLoading) {
    return null;
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} md={10} lg={6} className={classes.root}>
          <Paper elevation={3} className={classes.paper}>
            <div style={{ display: 'flex' }}>
              <div>
                <span
                  className={classes.back}
                  onClick={(e) => {
                    e.preventDefault();

                    history.goBack();
                  }}
                >
                  <i className="fa fa-arrow-left"></i>
                </span>
              </div>
              <Typography variant="h6" align="center" component="div" style={{ flex: 1 }}>
                {t('Modify meeting')}
              </Typography>
            </div>

            <React.Fragment>
              {
                <Formik
                  initialValues={meeting}
                  validationSchema={UpdateMeetingSchema}
                  enableReinitialize={true}
                  onSubmit={
                    async (values, { setSubmitting, setErrors }) => {
                      try {
                        await onSubmit({
                          ...values,
                          date_from: moment(
                            `${moment(values.date_from.date).format('YYYY-MM-DD')} ${moment(values.date_from.time).format('HH:mm')}`, 'YYYY-MM-DD HH:mm'
                          ).toDate(),
                        });
                      } catch (err) {
                        if (err.response && err.response.data.errors) {
                          setErrors(err.response.data.errors);
                        }

                        setSubmitting(false);
                      }
                    }
                  }
                  render={fprops => <RenderForm {...fprops} {...{ t }}/>}
                />
              }
            </React.Fragment>
          </Paper>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" align="center">
              {t('Guests')}
            </Typography>
            <div>
              {
                meeting.Invitations.map((invitation, i) => {
                  return (
                    <div key={i} className={classes.guest}>
                      <Typography variant="body2" component="span">
                        {invitation.Guest.email || invitation.Guest.cellphone} ({t(invitation.status)})
                      </Typography>
                      <IconButton onClick={() => removeInvitation(invitation.id)}>
                        <i className="fa fa-trash"></i>
                      </IconButton>
                    </div>
                  );
                })
              }
            </div>
            <React.Fragment>
              {
                <Formik
                  initialValues={{
                    guest_contact: [],
                  }}
                  validationSchema={AddInvitationSchema}
                  enableReinitialize={true}
                  onSubmit={
                    async (values, { setSubmitting, setErrors, resetForm }) => {
                      try {
                        await addInvitation(values);
                        setSubmitting(false);
                        resetForm();
                      } catch (err) {
                        if (err.response && err.response.data.errors) {
                          setErrors(err.response.data.errors);
                        }

                        setSubmitting(false);
                      }
                    }
                  }
                  render={fprops => <RenderFormGuests {...fprops} {...{ t }}/>}
                />
              }
            </React.Fragment>
          </Paper>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}