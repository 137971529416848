import * as React from 'react';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import ContainerCard from '../Shared/container-card';
import NoRecords from '../Shared/NoRecords';
import { Select } from '../Shared/BsInput';
import { StatsRegistrationSchema } from '../../validation-schemas';
import Tabs from './tabs';
import BuildingsSelect from './buildings-select';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
  isValid,
  setFieldValue,
  buildings,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid spacing={3} container>
        <Grid item xs={12} sm={6} md={4}>
          <Select
            className="pt-3"
            value={values.group}
            onChange={handleChange}
            name="group"
            label={t('Compare data to previous')}
            errors={errors}
            options={[
              { value: 'trimester', label: t('Trimester') },
              { value: 'month', label: t('Month') },
              { value: 'week', label: t('Week') },
              { value: 'day', label: t('Day') },
              { value: 'hour', label: t('Hour') },
            ]}
          />
        </Grid>
        <BuildingsSelect
          {...{
            errors,
            values,
            handleChange,
            setFieldValue,
            t,
            buildings,
          }}
        />
        <Grid item xs={12} md={2}>
          <Button
            className="mt-4"
            variant="contained"
            color="default"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            {t('Show')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({ onSubmit, buildings, form, data, empty }) => {
  const { t } = useTranslation();

  if (!buildings.length) {
    return (
      <div className="alert alert-info text-center">
        {t('You need to be building manager to see reports')}
      </div>
    );
  }

  const csvData =  [
    [
      t('Domain'),
      `${t('Total users')} / ${t('previous')}`,
      `${t('Total approved')} / t('previous')}`,
      t('% approved'),
      t('Users growth'),
      t('Approved growth'),
    ],
    ...(data || []).map((d) => {
      return [
        `@${d.domain}`,
        `${d.registered} / ${d.previous_registered}`,
        `${d.approved} / ${d.previous_approved}`,
        `${parseFloat(d.approved * 100 / d.registered).toFixed(2)}%`,
        `+ ${d.growth_registered}`,
        `+ ${d.growth_approved}`,
      ];
    }),
  ];

  return (
    <>
      <ContainerCard
        xs={12}
        md={12}
        lg={12}
        header={
          <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between', alignItems: 'center'}}>
            <div />
            <Typography variant="h6" align="center">
              {t('Registration')}
            </Typography>
            <div className="pull-right">
              <Link to="/app/stats">
                <i className="fa fa-share fa-flip-horizontal"></i>
              </Link>
            </div>
          </div>
        }
        content={
          <>
            <Formik
              initialValues={form}
              validationSchema={StatsRegistrationSchema}
              onSubmit={
                async (values, { setSubmitting, setErrors }) => {
                  try {
                    await onSubmit(values);
                    setSubmitting(false);
                  } catch (err) {
                    if (err.response && err.response.data.errors) {
                      setErrors(err.response.data.errors);
                    }

                    setSubmitting(false);
                  }
                }
              }
              render={p => <RenderForm {...p} {...{ t, buildings }}/>}
            />
            <hr />
            {
              empty && <NoRecords />
            }

            {
              !empty && data &&
              <>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <Button
                    type="button"
                    variant="outlined"
                    size="small"
                  >
                    <CSVLink data={csvData} filename="podesubir-registration.csv">{t('Download CSV')}</CSVLink>
                  </Button>
                </Grid>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('Domain')}</TableCell>
                      <TableCell>{t('Total users')} / {t('previous')}</TableCell>
                      <TableCell>{t('Total approved')} / {t('previous')}</TableCell>
                      <TableCell>{t('% approved')}</TableCell>
                      <TableCell>{t('Users growth')}</TableCell>
                      <TableCell>{t('Approved growth')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      data.map((d, i) => (
                        <TableRow key={i}>
                          <TableCell>@{d.domain}</TableCell>
                          <TableCell align="center">{d.registered} / {d.previous_registered}</TableCell>
                          <TableCell align="center">{d.approved} / {d.previous_approved}</TableCell>
                          <TableCell align="center">{parseFloat(d.approved * 100 / d.registered).toFixed(2)}%</TableCell>
                          <TableCell align="center">+ {d.growth_registered}</TableCell>
                          <TableCell align="center">+ {d.growth_approved}</TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </>
            }

          </>
        }
        style={{ marginBottom: '15px' }}
      />
    </>
  );
}
