import * as React from 'react';
import {
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ContainerCard from '../Shared/container-card';

export default ({ success, error }) => {
  const { t } = useTranslation();

  return (
    <ContainerCard
      xs={12}
      md={4}
      header={
        <Typography variant="h6" align="center">
          {t('Verify email')}
        </Typography>
      }
      content={
        <React.Fragment>
          {
            success &&
            <div className="alert alert-success text-center">
              <Typography>{t('Your email has been verified')}</Typography>
            </div>
          }
          {
            error &&
            <div className="alert alert-danger text-center">
              <Typography>{t(error)}</Typography>
            </div>
          }
          <hr />
          <div className="text-center">
            <Typography>
              {t('Download the Podesubir app here')}
            </Typography>
            <a href="https://play.google.com/store/apps/details?id=com.app.podesubirapp">
              <img src="https://api.puedesubir.com/static/google-play.png" style={{ maxWidth: '20%' }}/>
            </a>
            <a href="https://apps.apple.com/us/app/podesubir-app/id1494951757?ls=1">
              <img src="https://api.puedesubir.com/static/app-store.png" style={{ maxWidth: '20%' }}/>
            </a>
          </div>
        </React.Fragment>
      }
    />
  );
}