import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { updateUserData } from '../../actions/users';
import Steps from '../../components/Register/steps';

class ReviewPending extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { user } = this.props;

    return (
      <Grid container spacing={2} justiryf="center">
        <Grid item xs={12}>
          <Alert severity="info" elevation={2}>
            {this.props.t('Your profile is under review by a customer service representative. We will notify you via email once the review is complete')}
          </Alert>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  updateUserData: (data) => dispatch(updateUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReviewPending));
