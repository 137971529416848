import * as React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from '../Shared/infinite-scroll';
import {
  Button,
  Grid,
  Typography,
  Collapse,
  IconButton
} from '@material-ui/core';
import MeetingCard from './meeting-card';
import ContainerCard from '../Shared/container-card';
import CreateButton from './create-floating-button';
import { Input } from '../Shared/BsInput';
import { Formik } from 'formik';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
  isValid,
  setFieldValue,
  buildings,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid spacing={3} container>
        <Grid item xs={12} md={4}>
          <Input
            type="text"
            value={values.name}
            onChange={handleChange}
            name="name"
            label={t('Name')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            type="text"
            value={values.surname}
            onChange={handleChange}
            name="surname"
            label={t('Surname')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            type="text"
            value={values.email}
            onChange={handleChange}
            name="email"
            label={t('Email')}
          />
        </Grid>
        <Grid item xs={0} md={10} />
        <Grid item xs={12} md={2}>
          <Button
            variant="contained"
            color="default"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            {t('Show')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({ onPageChange, total, pages, page, meetings, confirm, reject, cancel, onChangeFilter }) => {
  const { t } = useTranslation();
  const [expandFilter, setExpandFilter] = React.useState(false);
  
  const handleExpandFilter = () => {
    setExpandFilter(!expandFilter)
  }

  return (
    <>
      {!expandFilter && (
        <Grid container spacing={2} style={{marginTop: '-20px'}}>
          <Grid item xs={0} md={9} />
          <Grid item xs={12} md={3}>
            <Button
              variant="text"
              color="default"
              fullWidth
              onClick={handleExpandFilter}
              style={{textTransform: 'uppercase'}}
              >
                <i class="fa fa-angle-down" aria-hidden="true" style={{marginRight: '10px'}} />
                {t('Show Filters')}
            </Button>
          </Grid>
        </Grid>)
      }
      <Collapse in={expandFilter} timeout="auto" unmountOnExit>
        <ContainerCard
          xs={12}
          md={12}
          lg={12}
          content={
            <>
              <div style={{display: 'flex', justifyContent: 'end'}}>
                <IconButton size='small' aria-label="close" onClick={handleExpandFilter}>
                  <i className="fa fa-times" />
                </IconButton>
              </div> 
              <Formik
                initialValues={{
                  name: '',
                  surname: '',
                  email: ''
                }}
                onSubmit={
                  async (values, { setSubmitting, setErrors }) => {
                    try {
                      await onChangeFilter(values)
                      await onPageChange({ page: 1 });
                      setSubmitting(false);
                    } catch (err) {
                      if (err.response && err.response.data.errors) {
                        setErrors(err.response.data.errors);
                      }
                      setSubmitting(false);
                    }
                  }
                }
                onChange
                render={p => <RenderForm {...p} {...{ t }}/>}
              />
            </>
          }
          style={{ marginBottom: '15px', marginTop: "-20px" }}
        />
      </Collapse>
      <Grid container spacing={2}>
        <CreateButton />
        <InfiniteScroll
          dataLength={total}
          next={() => onPageChange({ page: page + 1 })}
          hasMore={page < pages}
          loader={<h4>Loading...</h4>}
          endMessage={
            <Grid item xs={12}>
              <Typography align="center" color="primary" component="div">
                {t('There are no more records')}
              </Typography>
            </Grid>
          }
          style={{ paddingBottom: '10%' }}
        >
          <Grid item container spacing={2}>
            {
              meetings.map((weekObj, i) => {
                return (
                  <React.Fragment key={i}>
                    <Grid item xs={12}>
                      <Typography variant="body1" style={{ color: '#575757', fontWeight: 'bold' }}>{weekObj.week}</Typography>
                    </Grid>
                    {
                      weekObj.weekly.map((dayObj, ii) => {
                        return (
                          <React.Fragment key={ii}>
                            <Grid item xs={1}>
                              <Typography>{dayObj.day}</Typography>
                            </Grid>
                            <Grid item xs={11}>
                              {
                                dayObj.records.map((meeting, iii) => {
                                  return (
                                    <MeetingCard key={iii} meeting={meeting} {...{ confirm, reject, cancel }} />
                                  );
                                })
                              }
                            </Grid>
                          </React.Fragment>
                        );
                      })
                    }
                  </React.Fragment>
                );
              })
            }
          </Grid>
        </InfiniteScroll>
      </Grid>
    </>
  );
}
