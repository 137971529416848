import * as React from 'react';
import Mask from 'react-text-mask';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'material-ui-phone-number';
import InputErrorClass from './InputErrorClass';
import {
  TextField,
  Select as MaterialSelect,
  Checkbox as MaterialCheckbox,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import MaterialAutoComplete from '@material-ui/lab/Autocomplete';

const MASKS = {
  'cpf-input': [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  'rg-input': [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/],
  'rne-input': [/[a-zA-Z]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /[a-zA-Z]/],
};

const getErrorForName = (errors, name) => {
  if (!errors) {
    return null;
  }

  if (errors[name]) {
    return errors[name];
  }

  if (name.includes('.')) {
    const split = name.split('.');

    if (errors[split[0]]) {
      let tmp = errors[split[0]];

      for (let i = 1; i < split.length; i += 1) {
        if (tmp[split[i]]) {
          tmp = tmp[split[i]];
        } else {
          tmp = null;
        }
      }

      if (tmp) {
        return tmp;
      }
    }
  }

  return null;
}

const getHelpOrError = (help, errors, name, t) => {
  const error = getErrorForName(errors, name);

  if (error) {
    return t(error);
  }

  if (help) {
    return help;
  }

  return null;
};

function TextMaskCustom(props) {
  const { inputRef, mask, ...other } = props;

  return (
    <Mask
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={MASKS[other.id]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

export const MaskedInput = (props) => {
  const { type, label, name, help, errors, value, ...rest } = props;
  const { t } = useTranslation();

  return (
    <TextField
      label={label || ''}
      margin="normal"
      type={type || 'text'}
      value={value || ''}
      fullWidth={true}
      error={!!getErrorForName(errors, name)}
      InputProps={{
        inputComponent: TextMaskCustom
      }}
      helperText={getHelpOrError(help, errors, name, t)}
      name={name}
      {...rest}
    />
  );
}

export const Password = (props) => {
  const [showPwd, setShowPwd] = React.useState(false);
  const { type, label, name, errors, value, help, ...rest } = props;
  const { t } = useTranslation();

  const changeShowPwd = () => {
    setShowPwd(!showPwd);
  };

  return (
    <TextField
      label={label || ''}
      margin="dense"
      name={name}
      type={showPwd ? 'text' : 'password'}
      value={value || ''}
      fullWidth={true}
      helperText={getHelpOrError(help, errors, name, t)}
      error={!!getErrorForName(errors, name)}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={changeShowPwd}
            >
              {showPwd ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
            </IconButton>
          </InputAdornment>

      }}
      {...rest}
    />
  );
}

export const Input = (props) => {
  const { type, label, name, errors, value, help, ...rest } = props;
  const { t } = useTranslation();

  return (
    <TextField
      label={label || ''}
      margin="dense"
      name={name}
      type={type || 'text'}
      value={value || ''}
      fullWidth={true}
      helperText={getHelpOrError(help, errors, name, t)}
      error={!!getErrorForName(errors, name)}
      {...rest}
    />
  );
}

export const Select = (props) => {
  const { type, label, name, errors, value, options, help, ...rest } = props;
  const { t } = useTranslation();

  return (
    <FormControl fullWidth margin={props.margin || 'none'}>
      {
        !!label &&
        <InputLabel>{label || ''}</InputLabel>
      }
      <MaterialSelect
        margin="dense"
        name={name}
        value={value}
        fullWidth={true}
        error={!!getErrorForName(errors, name)}
        {...rest}
      >
        {
          options.map((o, i) => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)
        }
      </MaterialSelect>
      <FormHelperText error={!!getErrorForName(errors, name)}>
        {getHelpOrError(help, errors, name, t)}
      </FormHelperText>
    </FormControl>
  );
}

export const AutoComplete = (props) => {
  const { type, label, name, errors, value, help, options, defaultValue,...rest } = props;
  const { t } = useTranslation();
  
  return (
    <MaterialAutoComplete
      name={name}
      fullWidth={true}
      defaultValue={{value: defaultValue, label: defaultValue}}
      options={options}
      getOptionLabel={(option) => option.label}
      {...rest}
      renderInput={(params) => 
        <TextField
          label={label || ''}
          margin="dense"
          name={name}
          type={'text'}
          fullWidth={true}
          helperText={getHelpOrError(help, errors, name, t)}
          error={!!getErrorForName(errors, name)}
          {...params}
        />}
    />
  );
}

export const InputAddon = (props) => {
  const { type, label, name, errors, onClick, ...rest } = props;

  return (
    <div className="form-group">
      {label && <label>{label}</label>}
      <div className="input-group">
        <input
          className={InputErrorClass({ errors, name })}
          name={name}
          type={type || 'text'}
          {...rest}
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={onClick}
          >
            <i className="fa fa-download"></i>
          </button>
        </div>
      </div>
      {errors[name] && <p className="invalid-feedback">{errors[name]}</p>}
    </div>
  );
}

export const Textarea = (props) => {
  const { label, name, value, help, errors, ...rest } = props;
  const { t } = useTranslation();

  return (
    <TextField
      label={label || ''}
      margin="dense"
      name={name}
      type={'text'}
      value={value || ''}
      fullWidth={true}
      helperText={getHelpOrError(help, errors, name, t)}
      error={!!getErrorForName(errors, name)}
      {...rest}
      multiline={true}
      rows={3}
    />
  );
}

export const Checkbox = (props) => {
  const { label, name, value, ...rest } = props;

  return (
    <MaterialCheckbox
      checked={value}
      value={value}
      name={name}
      {...rest}
    />
  );
}

export const Phone = (props) => {
  const { type, label, name, errors, value, help, ...rest } = props;
  const { t } = useTranslation();

  return (
    <PhoneInput
      defaultCountry={'br'}
      countryCodeEditable={false}
      disableAreaCodes={true}
      fullWidth
      onlyCountries={['br', 'ar', 'mx', 'us', 'co', 'gb', 've', 'il', 'it', 'ph', 'es', 'ma']}
      autoFormat={false}
      label={label || ''}
      name={name}
      helperText={getHelpOrError(help, errors, name, t)}
      error={!!getErrorForName(errors, name)}
      margin={'normal'}
      {...rest}
    />
  );
}
