import * as React from 'react';
import {
  Button,
  Typography,
  Grid,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import TakeImageDialog from '../../Shared/take-image-dialog';
import { Player } from '@lottiefiles/react-lottie-player';
import { isMobile } from '../../Shared/IsMobile';
import document_photo_back_pc from '../../../assets/lottieFiles/document_photo_back_pc.json'
import document_photo_front_pc from '../../../assets/lottieFiles/document_photo_pc.json'
import document_photo_back_mobile from '../../../assets/lottieFiles/document_photo_back_mobile.json'
import document_photo_front_mobile from '../../../assets/lottieFiles/document_photo_mobile.json'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '10px',
    paddingBottom: '20px',
    marginBottom: '10px',
    border: 'none',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  img: {
    maxWidth: '50%',
    cursor: 'pointer',
  }
}));

export default ({ onSubmit, user, handleBack, handleNext, errors }) => {
  const { t } = useTranslation();
  const [frontOpened, setFrontOpened] = React.useState(false);
  const [backOpened, setBackOpened] = React.useState(false);
  const [files, setFiles] = React.useState({
    id_front: null,
    id_back: null,
  });
  const [previews, setPreviews] = React.useState({
    id_front: null,
    id_back: null,
  });
  const classes = useStyles();
  const docIdFront = user.Documents.find((d) => d.type === 'ID_FRONT');
  const docIdBack = user.Documents.find((d) => d.type === 'ID_BACK');
  const docIdFrontRef = React.useRef(null);
  const docIdBackRef = React.useRef(null);

  const openUpload = (ref) => {
    ref.current.click();
  };

  const getPreview = (key, file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviews({
        ...previews,
        [key]: reader.result,
      });
    }
    reader.readAsDataURL(file);
  };

  const setFile = (key) => (file) => {
    getPreview(key, file);
    setFiles({
      ...files,
      [key]: file,
    });
  };

  const handleImageChange = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPreviews({...previews, [type]: reader.result});

        setFile(type)(file);
      };
    }
  };

  const srcFront = previews.id_front
    ? previews.id_front
    : docIdFront
    ? docIdFront.public_url
    : null;
  const srcBack = previews.id_back
    ? previews.id_back
    : (docIdBack ? docIdBack.public_url : null);

  return (
    <Paper square className={classes.paper}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12}>
          {
            errors && (
              <div className="alert alert-danger">
                {t('The following information does not match')}:
                {
                  Object.keys(errors).map((key) => (
                    <div key={key}>{t(key)}</div>
                  ))
                }
                {t('Make sure the pictures are clear and the information is readable')}.&nbsp;
                {t('If you made a mistake while entering some of your information you can go back to the previous step and try again')}
              </div>
            )
          }
        </Grid>
        <Grid item xs={12} sm={11} className={classes.center}>
          <div className="alert alert-info">
            <Typography align="left">
              . {t('Make sure the ID pictures are clear and all the information can be seen')}
            </Typography>
            <Typography align="left">
              . {t('The face in the front of the ID must match your profile picture')}.
            </Typography>
            <Typography align="left">
              . {t('The information in the ID must match the information you provided in the previous step')}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>&nbsp;</Grid>
        <Grid item xs={12} md={6} className={classes.center}>
          <Typography variant="h6" align="center">
            {t('ID picture front')}
          </Typography>
          
          {srcFront ? 
            <img
              alt='id'
              className={classes.img}
              src={srcFront}
              onClick={() => setFrontOpened(true)}
            /> : <Player
              src={isMobile() ? document_photo_front_mobile : document_photo_front_pc}
              className="player"
              autoplay={true}
              loop={true}
              style={{maxWidth: '35%'}}
            />
          }
          <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center'}}>
            {!isMobile() ? <Button
              className='mt-3'
              variant='contained'
              color='primary'
              fullWidth
              style={{maxWidth: '250px', textTransform: 'uppercase'}}
              onClick={() => setFrontOpened(true)}
            >
              {t('Taking photo')}
            </Button> : null}
            <input
              ref={docIdFrontRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e, 'id_front')}
            />
            <Button
              className='mt-3'
              variant={isMobile() ? 'contained' : 'outlined'}
              color='primary'
              fullWidth
              htmlFor="upload-image"
              onClick={() => openUpload(docIdFrontRef)}
              style={{maxWidth: '250px', textTransform: 'uppercase'}}
            >
              {t('Send image')}
            </Button>
          </div>
          <TakeImageDialog
            onConfirm={setFile('id_front')}
            handleClose={() => setFrontOpened(false)}
            opened={frontOpened}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.center}>
          <Typography variant="h6" align="center">
            {t('ID picture back')}
          </Typography>
          {srcBack ? 
            <img
              alt='id'
              className={classes.img}
              src={srcBack}
              onClick={() => setFrontOpened(true)}
            /> : <Player
            src={isMobile() ? document_photo_back_mobile : document_photo_back_pc}
              className="player"
              autoplay={true}
              loop={true}
              style={{maxWidth: '35%'}}
            />
          }
          <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center'}}>
            {!isMobile() ? (
              <Button
              className='mt-3'
              variant='contained'
              color='primary'
              fullWidth
              style={{maxWidth: '250px', textTransform: 'uppercase'}}              
              onClick={() => setBackOpened(true)}
            >
              {t('Taking photo')}
            </Button>
            ) : null}
            <input
              ref={docIdBackRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e, 'id_back')}
            />
            <Button
              className='mt-3'
              variant={isMobile() ? 'contained' : 'outlined'}
              color='primary'
              fullWidth
              onClick={() => openUpload(docIdBackRef)}
              style={{maxWidth: '250px', textTransform: 'uppercase'}}
            >
              {t('Send image')}
            </Button>
          </div>
          {/* <img alt='id' className={classes.img} src={srcBack} onClick={() => setBackOpened(true)} /> */}
          <TakeImageDialog
            onConfirm={setFile('id_back')}
            handleClose={() => setBackOpened(false)}
            opened={backOpened}
          />
        </Grid>
        <Grid item xs={12}>
          <hr/>
          <div className={classes.right}>
            <Button
              type="button"
              color="default"
              variant="outlined"
              size="small"
              onClick={handleBack}
            >
              {t('Back')}
            </Button>
            &nbsp;
            {
              !(files.id_back || files.id_front) &&
              user.registration_step === 'CONFIRM_INFORMATION' && (
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  size="small"
                  onClick={handleNext}
                >
                  {t('Continue')}
                </Button>
              )
            }
            {
              (files.id_back || files.id_front) && (
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  size="small"
                  onClick={() => onSubmit(files)}
                  disabled={!files.id_back && !files.id_front}
                >
                  {t('Continue')}
                </Button>
              )
            }
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}