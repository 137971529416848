import * as React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { updateUserData } from '../../../actions/users';
import EmailVerificationComponent from '../../../components/Register/steps/email-verification';
import * as UsersApi from '../../../api/users';
import * as AuthApi from '../../../api/auth';

class EmailVerification extends React.Component {
  constructor(props) {
    super(props);
  }

  fetchUser = async () => {
    const { data } = await UsersApi.me();

    if (!data.email_verified_at) {
      toast.error(this.props.t('Your email is still not verified. Please follow the received instructions'));
    }

    this.props.updateUserData(data);
  }

  resend = async () => {
    await AuthApi.resendEmail();

    toast.success(this.props.t('A new email was sent to your address'));
  }

  change = async (body) => {
    const { data } = await AuthApi.correctEmail(body);

    toast.success(this.props.t('Your email address was changed and a new verification email was sent'));

    this.props.updateUserData(data);
  }

  render() {
    const { user } = this.props;

    return (
      <EmailVerificationComponent
        user={user}
        fetchUser={this.fetchUser}
        resend={this.resend}
        change={this.change}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  updateUserData: (data) => dispatch(updateUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmailVerification));
