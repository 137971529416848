import * as React from 'react';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import MeetingView from '../../components/Meetings/view';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';
import * as MeetingsApi from '../../api/meetings';

class MeetingViewContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      meetingId: this.props.match.params.meetingId,
      meeting: null,
      isLoading: true,
    };

    this.cancel = this.cancel.bind(this);
    this.fetchMeeting = this.fetchMeeting.bind(this);
  }

  async cancel(invidationId) {
    const onConfirmClick = async () => {
      await MeetingsApi.cancel(invidationId);

      toast.success(this.props.t('The meeting has been cancelled'));
      this.fetchMeeting();
    };

    confirmAlert({
      message: this.props.t('Cancel meeting?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  allowAccess = async (invidationId) => {
    const onConfirmClick = async () => {
      await MeetingsApi.allowAccess(this.state.meetingId, invidationId);

      toast.success(this.props.t('The guest has been allowed access'));
      this.fetchMeeting();
    };

    confirmAlert({
      message: this.props.t('Allow access?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  delayAccess = async (invidationId, time) => {
    const onConfirmClick = async () => {
      await MeetingsApi.delayAccess(this.state.meetingId, invidationId, time);

      toast.success(this.props.t('The guest has been notified of the delay'));
      this.fetchMeeting();
    };

    confirmAlert({
      message: this.props.t('Delay the access?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  async fetchMeeting() {
    const { data } = await MeetingsApi.get(this.state.meetingId);

    this.setState({
      meeting: data,
      isLoading: false,
    });
  }

  render() {
    return (
      <MeetingView
        meeting={this.state.meeting}
        isLoading={this.state.isLoading}
        cancel={this.cancel}
        allowAccess={this.allowAccess}
        delayAccess={this.delayAccess}
      />
    );
  }

  async componentDidMount() {
    this.fetchMeeting();
  }
}

export default withTranslation()(MeetingViewContainer);