import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  TableRow,
  TableCell,
  Button,
  Typography,
} from '@material-ui/core';
import NoRecords from '../Shared/NoRecords';
import ManageUsersBtn from './manager-users-button';
import ManageCompaniesBtn from './manage-companies-button';
import ManageActiveButton from './manager-active-button';
import ContainerCard from '../Shared/container-card';
import ListTable from '../Shared/list-table';

const RenderTable = ({ onPageChange, pages, page, buildings, t }) => {
  return (
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell>{t('Name')}</TableCell>
          <TableCell>{t('My roles')}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      }
      body={
        <React.Fragment>
          {
            buildings.map((b, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{b.name}</TableCell>
                  <TableCell>
                    {
                      b.Roles.map((r, i) => <div key={i}>{t(r.role)}</div>)
                    }
                  </TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={`/app/buildings/${b.id}`}
                      variant="contained"
                      color="primary"
                      size="small"
                      fullWidth
                    >
                      {t('Detail')}
                    </Button>
                    <ManageUsersBtn building={b} />
                    <ManageCompaniesBtn building={b} />
                    {/* <ManageActiveButton building={b} /> */}
                  </TableCell>
                </TableRow>
              );
            })
          }
        </React.Fragment>
      }
    />
  );
};

export default ({ onPageChange, pages, page, buildings }) => {
  const { t } = useTranslation();

  return (
    <ContainerCard
      xs={12}
      md={12}
      lg={12}
      header={
        <Typography variant="h6" align="center">
          {t('My buildings')}
        </Typography>
      }
      content={
        <React.Fragment>
          {
            !buildings.length && <NoRecords />
          }
          {
            !!buildings.length && <RenderTable {...{ onPageChange, pages, page, buildings, t }} />
          }
        </React.Fragment>
      }

    />
  );
}
