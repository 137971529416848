import * as React from 'react';
import BuildingView from '../../components/Buildings/view';
import * as BuildingsApi from '../../api/buildings';

export default class BuildingViewContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buildingId: this.props.match.params.buildingId,
      building: null,
      isLoading: true,
    };
  }

  render() {
    return (
      <BuildingView
        building={this.state.building}
        isLoading={this.state.isLoading}
      />
    );
  }

  async componentDidMount() {
    const { data } = await BuildingsApi.get(this.state.buildingId);

    this.setState({
      building: data,
      isLoading: false,
    });
  }
}
