import * as React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import ProfileForm from './form';
import UploadPicture from '../../containers/Profile/upload-picture';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '10%',

    [theme.breakpoints.up('sm')]: {
      marginTop: '2%',
    },
  },
  paper: {
    padding: '10px',
    paddingBottom: '20px',
    marginBottom: '10px',
  },
  title: {
    marginBottom: '20px',
  },
}));

export default ({ onFileSubmit, onSubmit, user, profile, isLoading }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={10} lg={6} className={classes.root}>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" align="center" className={classes.title}>
            {t('My information')}
            <div className="pull-right">
              <Link to="/app/account-configuration">
                <i className="fa fa-share fa-flip-horizontal"></i>
              </Link>
            </div>
          </Typography>
          <React.Fragment>
            <UploadPicture
              profile_picture={user.profile_picture}
              onSubmit={onFileSubmit}
            />
            <hr />
            <ProfileForm
              onSubmit={onSubmit}
              profile={profile}
              isLoading={isLoading}
            />
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}