import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChangeEmailDialog from './change-email-dialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    boxShadow: 'none',
  },
  btnContainer: {
    textAlign: 'right',
  },
  actions: {
    textAlign: 'center',
  },
  margin: {
    marginTop: theme.spacing(4),
  },
}));

export default (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { user, fetchUser, resend, change } = props;

  const handleClose = () => setDialogOpen(false);
  const handleOpen = () => setDialogOpen(true);

  return (
    <>
      <ChangeEmailDialog
        opened={dialogOpen}
        handleClose={handleClose}
        user={user}
        onSubmit={(values) => {
          handleClose();
          change(values);
        }}
      />
      <Paper className={classes.paper} square>
        <Typography align="center">
          {t('We have sent you an email to your address')}
        </Typography>
        <Typography align="center" variant="h6">
          {user.email}
        </Typography>
        <Typography align="center">
          {t('Please follow the instructions and click on continue')}
        </Typography>
        <Typography align="center" className={classes.margin}>
          {t('If you did not received the email you can')}
          &nbsp;
          <a
            href="javascript:void(0)"
            onClick={resend}
          >
            {t('request a new verification email')}
          </a>. {t('Alternatively you can')}&nbsp;
          <a
            href="javascript:void(0)"
            onClick={handleOpen}
          >
            {t('change your email address')}
          </a>
        </Typography>

        <hr />
        <div className={classes.btnContainer}>
          <Button
            onClick={fetchUser}
            type="button"
            variant="contained"
            color="primary"
            size="small"
          >
            {t('Continue')}
          </Button>
        </div>
      </Paper>
    </>
  );
}
