import * as React from 'react';
import * as moment from 'moment';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '2%',
    marginBottom: '15%',
  },
  paper: {
    padding: '10px',
    marginBottom: '15px',
  },
  back: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  accepted: {
    backgroundColor: 'rgba(223, 255, 223, 0.5)',
  },
  acceptedText: {
    color: theme.palette.secondary.main,
  },
  cancelled: {
    backgroundColor: 'rgba(255, 228, 228, 0.5)',
  },
  cancelledText: {
    color: 'red',
  },
  subject: {
    marginBottom: '15px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.6rem',
    },
  },
  descriptionField: {
    fontWeight: 'bold',
    textTransform: 'lowercase',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
  descriptionValue: {
    lineHeight: '1rem',

    [theme.breakpoints.up('sm')]: {
      lineHeight: '1.5rem',
      fontSize: '1.2rem',
    },
  },
  reducedLineHeight: {
    lineHeight: '1rem',
  },
  paperInvitation: {
    padding: '10px',
    marginBottom: '5px',
    width: '100%',
  },
  profilePicture: {
    maxWidth: '100%',
  },
  guestFields: {
    lineHeight: '1rem',

    [theme.breakpoints.up('sm')]: {
      lineHeight: '1.5rem',
      fontSize: '1rem',
    },
  },
  button: {
    padding: 0,

    '& a': {
      textDecoration: 'none',
      color: 'inherit',

      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  btncontainer: {
    textAlign: 'right',
  },
}));

const displayName = (user) => {
  if (user.name) {
    return `${user.name} ${user.surname}`;
  }

  if (user.email) {
    return user.email;
  }

  if (user.cellphone) {
    return user.cellphone;
  }

  return 'Guest';
};

const GuestArrivedDialog = ({ meeting, allowAccess, delayAccess, cancel }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (meeting.announce_arrival && meeting.Invitations.some((i) => i.announce_arrival_status === 'GUEST_NOTIFIED_ARRIVAL')) {
      setOpen(true);
    }
  }, [meeting]);

  const invitation = meeting.Invitations.find((i) => i.announce_arrival_status === 'GUEST_NOTIFIED_ARRIVAL');

  if (!invitation) {
    return null;
  }

  return (
    <Dialog open={open} size="md" fullWidth onClose={() => setOpen(false)}>
      <Box p={2}>
        <Typography align="center">
          <b>{t('Your guest arrived')}</b>
        </Typography>
      </Box>
      <Box p={2} textAlign="center">
        <img src={`${invitation.Guest.profile_picture || '/profile-placeholder.png'}?w=300&h=300`} alt="guest" className={classes.profilePicture} />
        <Typography>
          <b>{displayName(invitation.Guest)}</b>
        </Typography>

        <Box p={4}>
          <Button
            type="button"
            onClick={() => allowAccess(invitation.id)}
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginBottom: '10px' }}
          >
            {t('Allow access')}
          </Button>
          <Button
            type="button"
            onClick={() => delayAccess(invitation.id, 5)}
            variant="outlined"
            color="primary"
            fullWidth
            style={{ marginBottom: '10px' }}
          >
            {t('Wait 5 minutes')}
          </Button>
          <Button
            type="button"
            onClick={() => delayAccess(invitation.id, 10)}
            variant="outlined"
            color="primary"
            fullWidth
            style={{ marginBottom: '10px' }}
          >
            {t('Wait 10 minutes')}
          </Button>
          {
            meeting.can_cancel && cancel && (
              <Button
                type="button"
                onClick={() => cancel(meeting.id)}
                variant="outlined"
                color="primary"
                fullWidth
              >
                {t('Cancel meeting')}
              </Button>
            )
          }
        </Box>
      </Box>
    </Dialog>
  );
};

export default ({ meeting, isLoading, cancel, allowAccess, delayAccess }) => {
  if (isLoading) {
    return (<div></div>);
  };
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  moment.locale(i18n.language.substr(0, 2));

  return (
    <div className={classes.root}>
      <GuestArrivedDialog meeting={meeting} cancel={cancel} allowAccess={allowAccess} delayAccess={delayAccess} />
      <Paper elevation={3} className={classes.paper}>
        <span
          className={classes.back}
          onClick={(e) => {
            e.preventDefault();

            history.goBack();
          }}
        >
          <i className="fa fa-arrow-left"></i>
        </span>
        <Typography variant="h5" align="center" className={classes.subject}>
          {meeting.subject}
        </Typography>
        <Grid container spacing={2} justify="center">
          <Grid item xs={3} md={2}>
            <Typography variant="body2" className={classes.descriptionField}>{t('Invited by')}</Typography>
          </Grid>
          <Grid item xs={7} md={4}>
            <Typography align="right" className={classes.descriptionValue}>
              {meeting.Host.name} {meeting.Host.surname}
              {
                meeting.Host.company &&
                <>
                  <br/>
                  {meeting.Host.company}
                </>
              }
              {
                meeting.Host.company_role &&
                <>
                  <br />
                  {meeting.Host.company_role}
                </>
              }
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justify="center">
          <Grid item xs={3} md={2}>
            <Typography variant="body2" className={classes.descriptionField}>{t('Meeting date')}</Typography>
          </Grid>
          <Grid item xs={7} md={4}>
            <Typography align="right" className={classes.descriptionValue}>
              {moment(meeting.date_from).format('ddd DD MMM  HH:mm')} - {moment(meeting.date_to).format('HH:mm')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justify="center">
          <Grid item xs={3} md={2}>
            <Typography variant="body2" className={classes.descriptionField}>{t('Building')}</Typography>
          </Grid>
          <Grid item xs={7} md={4}>
            <Typography align="right" className={classes.descriptionValue}>
              {meeting.Building.name}
              <br />
              {meeting.Building.address} {meeting.Company && <>{meeting.Company.floor} - {meeting.Company.room}</>}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.btncontainer}>
          {
            meeting.canBeEdited &&
            <IconButton className={classes.button} color="primary">
              <Link to={`/app/meetings/${meeting.id}/edit`}>
                <i className="fa fa-pencil"></i>
              </Link>
            </IconButton>
          }
          &nbsp;&nbsp;
          {
            meeting.can_cancel && cancel &&
            <IconButton className={[classes.button, classes.cancelledText].join(' ')} variant="outlined" onClick={() => cancel(meeting.id)}>
              <i className="fa fa-times"></i>
            </IconButton>
          }
        </div>
      </Paper>

      <Grid container spacing={2}>
        {
          meeting.Invitations.map((invitation, i) => {
            const url = invitation.Guest.profile_picture || '/profile-placeholder.png';

            return (
              <Grid key={i} item container xs={12} md={4}>
                <Paper className={clsx(classes.paperInvitation, {
                  [classes.accepted]: invitation.status === 'CONFIRMED',
                  [classes.cancelled]: invitation.status === 'CANCELLED' || invitation.status === 'REJECTED',
                })}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={3} md={4} lg={4}>
                      <img src={`${url}?w=300&h=300`} alt="guest" className={classes.profilePicture} />
                    </Grid>
                    <Grid item xs={9} md={8} lg={8}>
                      <Typography variant="body2" className={classes.guestFields}>
                        <b>{displayName(invitation.Guest)}</b>
                      </Typography>
                      <Typography variant="body2" className={classes.guestFields}>
                        {invitation.Guest.company} - {invitation.Guest.company_role}
                      </Typography>
                      <Typography variant="body2" className={clsx(classes.guestFields, {
                        [classes.acceptedText]: invitation.status === 'CONFIRMED',
                        [classes.cancelledText]: invitation.status === 'CANCELLED' || invitation.status === 'REJECTED',
                      })} style={{ textTransform: 'lowercase' }}>
                        {t(invitation.status)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          })
        }
      </Grid>
    </div>
  );
}
