import * as React from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import StatsVisitors from '../../components/Stats/visitors';
import * as StatsApi from '../../api/stats';

class VisitorsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      buildings: [],
      form: {
        building_id: null,
        company_id: null,
        from: moment().subtract(3, 'months'),
        to: moment().subtract(1, 'day'),
        group: 'week',
      },
      data: null,
      empty: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    this.setState({ form: body, empty: false });
    const res = await StatsApi.visitors(body);

    const { data, format, display } = res.data;

    this.setState({ data, format, display, empty: !data.length });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.buildings && props.buildings.length && !state.form.building_id) {
      return {
        form: {
          ...state.form,
          building_id: props.buildings[0].id,
          company_id: props.buildings[0].Companies && props.buildings[0].Companies.length
            ? props.buildings[0].Companies[0].id
            : '__none__',
        },
      };
    }

    return null;
  }

  async componentDidMount() {
    const { i18n } = this.props;
    const language = i18n.language.substr(0, 2);
    moment.locale(language);
  }

  render() {
    const { t } = this.props;

    if (this.state.isLoading) {
      return null;
    }

    let chart = null;

    if (this.state.data) {
      chart = {
        title: {
          text: t('Visitors by building'),
        },
        chart: {
          type: 'bar',
        },
        xAxis: {
          categories: this.state.data
            .map((data) => moment(data.dimension, this.state.format).format(this.state.display).replace('week', t('week')).replace('of', t('of'))),
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: t('Invitations'),
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          },
        },
        series: [{
          data: this.state.data
            .map((data) => data.metric),
        }],
      };
    }

    return (
      <StatsVisitors
        buildings={this.props.buildings}
        onSubmit={this.onSubmit}
        form={this.state.form}
        chart={chart}
        empty={this.state.empty}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  buildings: state.buildings.records.filter((b) => b.permissions.reports),
});

export default withTranslation()(connect(mapStateToProps)(VisitorsContainer));
