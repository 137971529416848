import * as React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { updateUserData } from '../../../actions/users';
import BasicInformationComponent from '../../../components/Register/steps/basic-information';
import * as AuthApi from '../../../api/auth';

class BasicInformation extends React.Component {
  constructor(props) {
    super(props);
  }

  onSubmit = async (body) => {
    const { data } = await AuthApi.basicInformation(body);

    this.props.updateUserData(data);
  }

  render() {
    const { user } = this.props;

    return (
      <BasicInformationComponent
        user={user}
        onSubmit={this.onSubmit}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  updateUserData: (data) => dispatch(updateUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BasicInformation));
