import * as React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';
import List from '../../components/Meetings/all';
import * as InvitationsApi from '../../api/invitations';
import * as MeetingsApi from '../../api/meetings';
import { groupByDate } from '../../logic';

class MeetingsAll extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      meetings: {
        page: 1,
        pages: 0,
        records: [],
      },
      invitations: {
        page: 1,
        pages: 0,
        records: [],
      },
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.fetchMeetings = this.fetchMeetings.bind(this);
    this.fetchInvitations = this.fetchInvitations.bind(this);
    this.cancelMeeting = this.cancelMeeting.bind(this);
    this.cancelInvitation = this.cancelInvitation.bind(this);
    this.confirmInvitation = this.confirmInvitation.bind(this);
  }

  async fetchMeetings({ page }) {
    if (page > this.state.meetings.pages && page > this.state.meetings.page) {
      return;
    }

    const { data, headers } = await MeetingsApi.listSent({
      limit: 10,
      offset: (page - 1) * 10,
    });

    const pages = Math.ceil(parseInt(headers['x-total-count'], 10) / 10);

    this.setState({
      meetings: {
        records: this.state.meetings.records
          .concat(
            data.map(d => ({ ...d, type: 'MEETING' })),
          ),
        pages,
        page,
      },
    });
  }

  async fetchInvitations({ page }) {
    if (page > this.state.invitations.pages && page > this.state.invitations.page) {
      return;
    }

    const { data, headers } = await InvitationsApi.listReceivedInvitations({
      limit: 10,
      offset: (page - 1) * 10,
    });

    const pages = Math.ceil(parseInt(headers['x-total-count'], 10) / 10);

    this.setState({
      invitations: {
        records: this.state.invitations.records
        .concat(
          data.map(d => ({ ...d, type: 'INVITATION' })),
        ),
        pages,
        page,
      },
    });
  }

  async onPageChange({ page }) {
    this.fetchInvitations({ page });
    this.fetchMeetings({ page });
  }

  reset = () => {
    this.setState({
      meetings: {
        records: [],
        page: 1,
        pages: 0
      },
      invitations: {
        records: [],
        page: 1,
        pages: 0
      },
    }, () => {
      this.onPageChange({ page: 1 });
    });
  }

  async cancelMeeting(meetingId) {
    const onConfirmClick = async () => {
      await MeetingsApi.cancel(meetingId);

      toast.success(this.props.t('The invitation has been cancelled'));
      this.reset();
    };

    confirmAlert({
      message: this.props.t('Cancel meeting?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  async confirmInvitation(invidationId) {
    const onConfirmClick = async () => {
      await InvitationsApi.confirm(invidationId);

      toast.success(this.props.t('The assistance to the invitation has been confirmed'));
      this.reset();
    };

    confirmAlert({
      message: this.props.t('Confirm assistance?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  async cancelInvitation(invidationId) {
    const onConfirmClick = async () => {
      await InvitationsApi.cancel(invidationId);

      toast.success(this.props.t('The invitation has been cancelled'));
      this.reset();
    };

    confirmAlert({
      message: this.props.t('Cancel invitation?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  async componentDidMount() {
    this.onPageChange({ page: 1 });
  }

  render() {
    const { i18n } = this.props;
    const language = i18n.language.substr(0, 2);

    const dataset = groupByDate(this.state.meetings.records.concat(this.state.invitations.records), language);

    return (
      <List
        records={dataset}
        total={this.state.meetings.records.length + this.state.invitations.records.length}
        pages={{
          invitations: this.state.invitations.pages,
          meetings: this.state.meetings.pages,
        }}
        page={{
          invitations: this.state.invitations.page,
          meetings: this.state.meetings.page,
        }}
        onPageChange={this.onPageChange}
        cancelMeeting={this.cancelMeeting}
        cancelInvitation={this.cancelInvitation}
        confirmInvitation={this.confirmInvitation}
        user={this.props.user}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default withTranslation()(connect(mapStateToProps)(MeetingsAll));
