import * as React from 'react';
import * as _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CompaniesForm from '../../components/Companies/form';
import * as BuildingsApi from '../../api/buildings';
import { listMainCompanies } from '../../api/companies';

class CompaniesCreateContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buildingId: this.props.match.params.buildingId,
      company: {
        name: '',
        floor: '',
        room: '',
        phone: '',
        access_before_minutes: 15,
        layout: 'default',
        logo: null,
        background: null,
      },
      mainCompanies: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    await BuildingsApi
      .createCompany(this.state.buildingId, _.pick(body, [
        'name', 'floor', 'room', 'access_before_minutes', 'parent_id',
        'layout',
      ]));

    toast.success(this.props.t('The company has been added to the building'));
    this.props.history.push(`/app/buildings/${this.state.buildingId}/companies`);
  }

  render() {
    return (
      <CompaniesForm
        company={this.state.company}
        onSubmit={this.onSubmit}
        mainCompanies={this.state.mainCompanies}
      />
    );
  }

  async componentDidMount() {
    const { data } = await listMainCompanies();

    this.setState({ mainCompanies: data });
  }
}

export default withTranslation()(CompaniesCreateContainer);