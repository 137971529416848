import * as React from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  Button,
} from '@material-ui/core';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';

export default ({ data }) => {
  const { t } = useTranslation();
  const [filters, setFilters] = React.useState({});

  React.useEffect(() => {
    setFilters({
      invitations: false,
      users: false,
      approved: false,
      completed: false,
    });
  }, [data]);

  return (
    <>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <div style={{ maxWidth: '100%' }}>
            <MaterialTable
              columns={[
                { title: t('Name'), field: 'User.name' },
                { title: t('Surname'), field: 'User.surname' },
                { title: t('Company'), field: 'User.company' },
                { title: t('Card code'), field: 'code' },
                { title: t('Security guard'), field: 'Guard', render: (d) =>  `${d.Guard.name} ${d.Guard.surname}` },
                { title: t('Event'), field: 'type', render: (d) =>  t(`guard.access.${d.type}`) },
                { title: t('Date'), field: 'created_at', type: 'datetime' },
              ]}
              data={data}
              options={{
                exportButton: true,
                pageSize: 100,
                pageSizeOptions: [100, 200, 500],
                headerStyle: { textTransform: 'lowercase' }
              }}
              title=""
            />
          </div>
        </Grid>
      </Grid>
    </>
  )
}