import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  TableRow,
  TableCell,
  Button,
  Typography,
} from '@material-ui/core';
import NoRecords from '../Shared/NoRecords';
import ContainerCard from '../Shared/container-card';
import ListTable from '../Shared/list-table';

const RenderTable = ({ onPageChange, pages, page, companies, t }) => {
  return (
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell>{t('Name')}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      }
      body={
        <React.Fragment>
          {
            companies.map((company, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{company.name}</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      variant="outlined"
                      color="primary"
                      size="small"
                      fullWidth
                      to={`/app/buildings/${company.building_id}/companies/${company.id}/edit`}
                    >
                      {t('Edit')}
                    </Button>
                    <Button
                      component={Link}
                      variant="outlined"
                      color="primary"
                      size="small"
                      fullWidth
                      style={{ marginTop: '5px' }}
                      to={`/app/buildings/${company.building_id}/companies/${company.id}/users`}
                    >
                      {t('Manage users')}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })
          }
        </React.Fragment>
      }
    />
  );
};

export default ({ onPageChange, pages, page, companies, building }) => {
  const { t } = useTranslation();

  return (
    <ContainerCard
      xs={12}
      md={12}
      lg={12}
      header={
        <Typography variant="h6" align="center">
          "{building.name}" {t('companies')}
        </Typography>
      }
      content={
        <React.Fragment>
          <div className="text-right">
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={`/app/buildings/${building.id}/companies/create`}
              size="small"
            >
              {t('Create company')}
            </Button>
            <hr/>
          </div>
          {
            !companies.length && <NoRecords />
          }
          {
            !!companies.length && <RenderTable {...{ onPageChange, pages, page, companies, t }} />
          }
        </React.Fragment>
      }
    />
  );
}
