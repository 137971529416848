import * as React from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import EmployeesPerCompany from '../../components/Stats/employees-per-company';
import * as StatsApi from '../../api/stats';

class EmployeesPerCompanyContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      buildings: [],
      form: {
        building_id: null,
        company_id: null,
        name: '',
        surname: '',
        email: '',
        key: '',
        role: 'all',
      },
      data: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    this.setState({ form: body });
    if(body.role === 'all') body.role = '';
    const res = await StatsApi.employeesPerCompany(body);
    const { t } = this.props;
    const translatedRes = res.data.map((prop) => {
      const roleTranslated = prop.role.split(",").map((prop) => {
        return t(prop)
      })

      return {
        ...prop,
        role: roleTranslated.join(' | ')
      }
    })
    this.setState({ data: translatedRes });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.buildings && props.buildings.length && !state.form.building_id) {

      return {
        form: {
          ...state.form,
          building_id: props.buildings[0].id,
          company_id: props.buildings[0].Companies && props.buildings[0].Companies.length
            ? 0
            : '__none__',
        },
      };
    }

    return null;
  }

  async componentDidMount() {
    const { i18n } = this.props;
    const language = i18n.language.substr(0, 2);
    moment.locale(language);
  }

  render() {
    const { t } = this.props;

    if (this.state.isLoading) {
      return null;
    }

    return (
      <EmployeesPerCompany
        buildings={this.props.buildings}
        onSubmit={this.onSubmit}
        form={this.state.form}
        data={this.state.data}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  buildings: state.buildings.records.filter((b) => b.permissions.reports),
});

export default withTranslation()(connect(mapStateToProps)(EmployeesPerCompanyContainer));
