import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    boxShadow: 'none',
  },
  btnContainer: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  margin: {
    marginTop: theme.spacing(4),
  },
}));

export default (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleBack, onSubmit, error, fields } = props;

  return (
    <>
      <Paper className={classes.paper} square>
        <Typography align="center">
          {t('You have completed all the registration steps')}
        </Typography>
        <Typography align="center">
          {t('If you would like to change any information you can go back to a previous step')}
        </Typography>
        <Typography align="center" className={classes.margin}>
          {t('When you are ready, press finalize to complete the process')}
        </Typography>
        {
          error && (
            <div className={classes.margin}>
              <Alert severity="error" elevation={3}>
                {t('There was a validation error with the information provided')}:
                <br />
                {t(error)}:
                <br />
                {
                  fields && (
                    <>
                      {
                        fields.map((f) => <span key={f}>- "{t(f)}"</span>)
                      }
                      <br />
                    </>
                  )
                }
                {t('Go back to the previous steps and correct the information')}
              </Alert>
              <Typography variant="body2" align="center" className={classes.margin}>
                {t('If the information supplied is correct, click the following button to submit everything as is and a customer support representative will review your profile')}
              </Typography>
              <div className={classes.btnContainer}>
                <Button
                  className={classes.margin}
                  type="button"
                  onClick={() => onSubmit({ force: true })}
                  variant="outlined"
                  size="small"
                >
                  {t('Submit my profile for review')}
                </Button>
              </div>
            </div>
          )
        }
        <hr/>
        <div className={classes.right}>
          <Button
            type="button"
            color="default"
            variant="outlined"
            size="small"
            onClick={handleBack}
          >
            {t('Back')}
          </Button>
          &nbsp;
          <Button
            color="primary"
            variant="contained"
            type="button"
            size="small"
            onClick={() => onSubmit({ force: false })}
          >
            {t('Finalize')}
          </Button>
        </div>
      </Paper>
    </>
  );
}
