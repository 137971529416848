export default {
  'Email': 'e-mail',
  'Password': 'senha',
  'Login': 'acessar',
  'You don\'t have an account?': 'não tem uma conta?',
  'Register here': 'abrir nova conta',
  'Role': 'alçada',
  'Name': 'nome',
  'Save': 'salvar',
  'Surname': 'sobrenome',
  'Confirm password': 'confirme a senha',
  'Register': 'inscrição',
  'Already have an account?': 'já tenho uma conta',
  'sign in': 'faça login',
  'My profile': 'meu perfil',
  'Logout': 'sair',
  'Change password': 'mudar senha',
  'Your profile picture was updated': 'sua imagem foi atualizada',
  'Your profile information was updated': 'sua informação foi atualizada',
  'New password': 'Nova senha',
  'Confirm new password': 'Confirme a senha',
  'Your password has been changed': 'Sua senha foi alterada',
  'Cancel': 'Cancelar',
  'Update information': 'Atualizar informação',
  'Upload new picture': 'Nova imagem',
  'Pending invitations for today': 'Invitações pendentes para hoje',
  'There are no pending invitations for today': 'Não há invitações pendentes para hoje',
  'My buildings': 'meus prédios',
  'My invitations': 'minhas invitações',
  'No buildings were found': 'Não há prédios encontrados',
  'Home': 'Inicio',
  'Building detail': 'Detalhes do prédio',
  'Address': 'Endereço',
  'Manager': 'Gerente',
  'My user roles in this building': 'Minha alçada neste prédio',
  'COMPANY_SECRETARY': 'Secretário(a) da empresa',
  'BUILDING_MANAGER': 'Administrador/gerenciador do prédio',
  'BUILDING_MANAGER.description': 'Permissão para gerenciar as informações e usuários do prédio, empresas, acessar ao prédio e criar convites de acesso',
  'COMPANY_MANAGER': 'Administrador da empresa condômina',
  'COMPANY_MANAGER.description': 'Permissão para gerenciar as informações e usuários da empresa, acessar ao prédio e criar convites de acesso',
  'EMPLOYEE': 'Usuário Básico',
  'EMPLOYEE.description': 'Permissão para acessar ao prédio',
  'HOST_EMPLOYEE': 'Usuário Anfitrião',
  'HOST_EMPLOYEE.description': 'Permissão para acessar ao prédio e criar convites de acesso',
  'SECURITY': 'Segurança/Guarda',
  'SECURITY.description': 'Permissões para acessar, criar convites e permitir o acesso de convidados manualmente',
  'Edit': 'Editar',
  'Manage companies': 'Gerenciar empresas',
  'No records found': 'Sem registros encontrados',
  'My roles': 'Minhas funções',
  'Manage users': 'Gerenciar usuários',
  'Detail': 'Detalhe',
  'The building information has been updated': 'A informação do prédio foi atualizada',
  'Building\'s users': 'Usuários do edifício',
  'Go to detail': 'Ir ao detalhe',
  'Add user to building': 'Adicionar usuário ao edifício',
  'Add user': 'Adicionar usuário',
  'Remove user': 'Remover usuário',
  'Remove': 'Remover',
  'User email': 'Email do usuário',
  'The user has been added to the building': 'O usuário foi adicionado ao edifício',
  'The user has been removed from the building': 'O usuário foi removido do edifício',
  'Remove user from building?': 'Remover usuário do edifício?',
  'Remove user from company?': 'Remover usuário da empresa?',
  'Yes': 'Sim',
  'No': 'Não',
  'My companies': 'minhas empresas',
  'Companies': 'Empresas',
  'Edit company': 'Editar empresa',
  'Create company': 'Criar empresa',
  'Manager email': 'Email do gerente',
  'The company has been added to the building': 'A empresa foi adicionada ao prédio',
  'The company has been updated': 'A empresa foi atualizada',
  'The user has been added to the company': 'O usuário foi adicionado à empresa',
  'The user has been removed from the company': 'O usuário foi removido da empresa',
  'Add user to company': 'Adicionar usuário a empresa',
  'Company\'s users': 'Usuários da empresa',
  'Back to the building\'s companies': 'Voltar as empresas do prédio',
  'My user roles in this company': 'Minha alçada nesta empresa',
  'Company detail': 'Detalhes da empresa',
  'New invitation': 'Novo convite',
  'The invitation has been sent': 'O convite foi enviado',
  'Building': 'edifício',
  'Host': 'Anfitrião',
  'You do not have invite permissions in any building': 'Voce não tem permissões de convite em nenhum edifício',
  'Guest\'s email': 'Email do convidado',
  'Guest': 'Convidado',
  'Invitation date': 'Horário',
  'As myself': 'Eu',
  'Invitation subject/reason': 'Assunto',
  'Invitation duration': 'Duração do convite',
  'Modify meeting': 'Atualizar',
  '1 hour': '1 hora',
  '2 hours': '2 horas',
  '3 hours': '3 horas',
  '5 hours': '5 horas',
  '12 hours': '12 horas',
  'Notes': 'Comentários',
  'Allow access to the building for': 'Duração',
  'Create invitation': 'Criar convite',
  'Invitation detail': 'Detalhe do convite',
  'Date': 'Data',
  'hours': 'Horas',
  'Access allowed for': 'Acesso permitido para',
  'You are hosting': 'você convidou',
  'Confirmed': 'Confirmado',
  'Waiting confirmation': 'Aguardando confirmação',
  'Rejected': 'Rejeitado',
  'Cancelled': 'Cancelado',
  'Status': 'Status',
  'Subject/Reason': 'Assunto',
  'Sent to': 'Enviado a/para',
  'Sent date': 'Data de envio',
  'I forgot my password': 'esqueci a minha senha',
  'Password recovery': 'Recuperação de senha',
  'Input your email and we will send you a link to restore your password': 'Digite seu email e nós lhe enviaremos um link para restaurar sua senha',
  'Back': 'Voltar',
  'Information was sent on how to restore your password': 'Foi enviada informação sobre como restaurar sua senha',
  'Request password recovery': 'Solicitar recuperação de senha',
  'Your password as been restored. You can now access with your new password': 'Sua senha foi mudada. Você já pode fazer o log in com a nova senha.',
  'Your email has been verified': 'seu e-mail foi verificado',
  'The email could not be verified': 'Não foi possível verificar o email',
  'Verify email': 'Verificar o e-mail',
  'PAST_DUE': 'Vencido',
  'CANCELLED': 'Cancelado',
  'REJECTED': 'Recusado',
  'CONFIRMED': 'Aceitado',
  'PENDING': 'Aguardando confirmação',
  'Confirm': 'Confirmar',
  'Reject': 'Recusar',
  'The assistance to the invitation has been confirmed': 'A assistência ao convite foi confirmada',
  'Confirm assistance?': 'Confirmar assistência?',
  'The invitation has been cancelled': 'O convite foi cancelado',
  'Cancel invitation?': 'Ao rejeitar este convite o anfitrião será notificado. Tem certeza de que quer rejeitar o convite?',
  'The invitation has been rejected': 'O convite foi rejeitado',
  'Reject invitation?': 'Ao rejeitar este convite o anfitrião será notificado. Tem certeza de que quer rejeitar o convite?',
  'Required': 'Campo obrigatório',
  'Invalid email': 'Email inválido',
  'Password confirm does not match': 'Confirmação de senha incorreta',
  'Select a building': 'Selecione um edifício',
  'Cellphone': 'Celular',
  'Birthdate': 'Data de nascimento',
  'Habilitaçao': 'Habilitaçao',
  'RNE number': 'RNE',
  'RG': 'RG',
  'RNE': 'RNE',
  'Passport': 'Passaporte',
  'ID Type': 'documento de identificação',
  'Invalid ID number': 'Número de identificação inválido',
  'RG number': 'RG',
  'CPF number': 'CPF',
  'Invalid RG number': 'RF inválido',
  'Invalid CPF number': 'CPF inválido',
  'Secretary': 'Assistente',
  'My secretaries': 'meus assistentes',
  'New secretary': 'definir assistente',
  'The user has been added as a secretary': 'O usuário foi adicionado como assistente',
  'No results': 'Nenhum resultado encontrado',
  'Search and select an user': 'Pesquise e selecione um usuário',
  'The user has been removed from your secretaries': 'O usuário foi removido das suas assistentes',
  'Remove user from your secretaries?': 'Remover usuário das suas assistentes?',
  '1. Select the building': '1. Selecionar o prédio',
  '2. Search a user and select from the list': '2. Pesquise e selecione da lista',
  'Send invitation as yourself or acting as a secretary for another user': 'Enviar o convite como você mesmo ou atuando como secretário de outro usuário',
  'Send invitation as': 'convidar como',
  'Building not found': 'Prédio não encontrado',
  'Invalid token': 'Token inválido',
  'Forbidden': 'Proibido',
  'There\'s no user registered with that email': 'Não há usuário cadastrado com esse email',
  'Email or password incorrect': 'Email ou senha incorretos',
  'The building manager can not be removed': 'O gerente do edifício não pode ser removido',
  'Company not found': 'Empresa não encontrada',
  'The company manager can not be removed': 'O gerente da empresa não pode ser removido',
  'You cannot invite in this building': 'Você não tem permissão de convidar neste edifício',
  'The user cannot invite in this building': 'O usuário não pode convidar neste edifício',
  'Missing file': 'Arquivo desaparecido',
  'You can not cancel a past invitation': 'Você não pode cancelar um convite passado',
  'The invitation can not be cancelled': 'O convite não pode ser cancelado',
  'You can not reject your own invitation': 'Você não pode rejeitar seu próprio convite',
  'You can not reject a past invitation': 'Você não pode rejeitar um convite passado',
  'The invitation can not be rejected': 'O convite não pode ser rejeitado',
  'You can not confirm your own invitation': 'Você não pode confirmar seu próprio convite',
  'You can not confirm a past invitation': 'Você não pode confirmar um convite passado',
  'The invitation can not be confirmed': 'O convite não pode ser confirmado',
  'Invitation not found': 'Convite não encontrado',
  'Can\'t create invitations on behalf user': 'Não é possível criar convites em nome de outro usuário',
  'The user can\'t create invitations in this building': 'O usuário não pode criar convites neste edifício',
  'You are not allowed to perform this action': 'Você não tem permissão para executar esta ação',
  'Multiple emails separated by comma': 'Vários emails separados por vírgula',
  'Sent invitations': 'Convites enviados',
  'Received invitations': 'convites',
  'Invitation data': 'Dados do convite',
  'Account configuration': 'configurações',
  'My information': 'meus dados',
  'Account': 'Configurações da conta',
  'My vehicle': 'meu veículo',
  'Your vehicle information was updated': 'as informações do seu veículo foram atualizadas',
  'Make': 'marca',
  'Model': 'modelo',
  'Color': 'cor',
  'License plate': 'placa',
  'The documents were uploaded': 'Os documentos foram carregados',
  'My documents': 'Meus documentos',
  'ID Front': 'atualizar foto da frente da identidade',
  'ID Back': 'atualizar foto do verso da identidade',
  'Email or cellphone number': 'email ou número de celular',
  'If using a cellphone number, it must be valid and include both country and area code. Ex (5511943210091)': 'No caso de usar um número de telefone celular, ele deve ser válido e incluir o país e o código de área. Ex (5511943210091)',
  'Verify cellphone': 'Verificar celular',
  'Your cellphone has been verified': 'Seu celular foi verificado',
  'The cellphone could not be verified': 'O celular não pôde ser verificado',
  'A message was sent to your number with a verification code': 'Uma mensagem foi enviada para o seu número com um código de verificação',
  'Skip': 'Pular',
  'Cellphone number': 'Celular',
  'Only numbers. Including country and area code': 'Apenas números. Incluindo o código do país e da área',
  'Verify cellphone number': 'Verificar número do celular',
  'Your cellphone is already verified': 'Seu celular já está verificado Seu celular já está verificado',
  'Request verification code': 'Solicitar código de verificação',
  'Verification code': 'Código de verificação',
  'Verify': 'Verificar',
  'Invalid number': 'Número invalido',
  'Invalid email or cellphone': 'e-mail ou celular inválido',
  'Change email': 'mudar email',
  'Change phone': 'mudar celular',
  'We have sent an email to your new email address. The change will be efective once you verify this new email': 'Enviámos um email para o seu novo endereço de email. A alteração será efetiva assim que você confirmar este novo e-mail',
  'Your phone number has been changed': 'Seu número do celular foi alterado',
  'After you submit we will send a verification link to this new email address. The change will only be effective after you have verified this new email address.': 'enviaremos um link de verificação pare este novo endereço de email. A alteração só será efetiva depois que você verificar este novo endereço de e-mail.',
  'Min 8 characters, one uppercase, one lowercase and one number': 'Mínimo de 8 caracteres, pelo menos um maiúscula e pelo menos um número',
  'The token you are trying to use is not valid': 'O token que você está tentando usar não é válido',
  'Access': 'Acesso',
  'There are not access codes available for the selected building': 'Não há códigos de acesso disponíveis para o edifício selecionado',
  'Company role': 'Cargo',
  'Company': 'Empresa',
  'Accept': 'Accept',
  'Terms and Conditions': 'Li e aceito os termos e condições',
  'Privacy policy': 'Li e aceito a política de privacidade',
  'There was changes in our Terms of service and Privacy Policy. Please review them and make sure you accept them before you continue using our application.': 'os termos e condições e a politica de privacidade foram atualizados.',
  'Changes in Terms of service and Privacy Policy': 'Alterações nos Termos de serviço e na Política de Privacidade',
  'Delete my account': 'Deletar minha conta',
  'Your account has been deleted.': 'Sua conta foi deletada.',
  'Deleting your account is irreversible. All your data will be lost, including your personal information, invitations you sent and received, associations to buildings and secretaries.': 'Excluir sua conta é irreversível. Todos os seus dados serão perdidos, incluindo suas informações pessoais, convites que você enviou e recebeu, associações a edifícios e secretárias. ',
  'Input your password to delete the account': 'Digite sua senha para excluir a conta',
  'I understand. Delete my account': 'Compreendo. Deletar minha conta',
  'Invitations': 'Convites',
  'Invitation': 'Convite',
  'The access code is being generated and will be visible here when the invitation date is due': 'O código de acesso está sendo gerado e está visível ao fechar os convites da correspondência',
  'Host\'s email': 'Email do Anfitrião',
  'Host\'s cellphone': 'Celular do Anfitrião',
  'View invitation ful detail': 'Ver detalhe da convite completo',
  'The access code will be visible here once the invitation is confirmed': 'O código de acesso estará visível uma vez no convite do mar',
  'Download the Podesubir app here': 'faça o download do app da Podesubir',
  'My Badge': 'meu crachá',
  'Visitor': 'visitante',
  'Meetings': 'Reuniões',
  'New meeting': 'nova reunião',
  'Other guests': 'Outros convidados',
  'ACTIVE': 'Esperando confirmação',
  'Invited': 'Convidado',
  'Multiple emails/cellphones separated by comma': 'Vários e-mails / celulares separados por vírgula',
  'Guest\'s contact': 'Contato do convidado',
  'Create meeting': 'Criar reunião',
  'Guests': 'Convidados',
  'The meeting has been modified': 'A reunião foi modificada',
  'Remove guest from the meeting?': 'Remover convidado da reunião?',
  'Remove guest from the meeting': 'Remover convidado da reunião',
  'Cancel meeting?': 'Ao cancelar este convite, o(s) convidado(s) serão notificados. Tem certeza de que quer cancelar?',
  'The meeting has been cancelled': 'A reunião foi cancelada',
  'Add guests to the meeting': 'Adicionar convidados à reunião',
  'Add': 'Adicionar',
  'For security reasons we encourage you to change your password.': 'Por motivos de segurança, recomendamos que você altere sua senha.',
  'None': 'Nenhum',
  'Floor': 'Piso',
  'Room': 'Sala/Escritório',
  'Main company': 'Empresa Principal',
  'Allow access before meeting starts (minutes)': 'Permitir acesso antes do início da reunião (minutos)',
  'Meetings & Invitations': 'reuniões e convites',
  'There are no more records': 'Não há mais registros',
  'You were invited': 'você foi convidado',
  'Invited by': 'Convidado por',
  'your best welcome': 'sua melhor recepção',
  'Sign up': 'Inscrever',
  'Meeting date': 'Horário',
  'terms': 'termos e condições',
  'privacypolicy': 'política de privacidade',
  'New email': 'novo email',
  'New cellphone number': 'Novo numero de celular',
  'Attention': 'Atenção',
  'Date from': 'Data de',
  'Date to': 'Data até',
  'Group data by': 'Agrupar dados por',
  'Month': 'Mês',
  'Week': 'Semana',
  'Day': 'Dia',
  'Hour': 'Hora',
  'Show': 'Mostrar',
  'Show Filters': 'Mostrar Filtros',
  'Visitors by building': 'Visitantes construindo',
  'My stats': 'Meus relatórios',
  'Visitors': 'Visitantes',
  'Visitors stay': 'Os visitantes ficam',
  'week': 'semana',
  'of': 'do',
  'Stats': 'Relatórios',
  'Stats: stay': 'Relatórios: fique',
  'Average duration (minutes)': 'Duração média (minutos)',
  'Average visits duration': 'Duração média da visita',
  'Stats: visitors': 'Relatórios: visitantes',
  'Stay': 'Duração da estadia',
  'You need to be building manager to see reports': 'Função de gerente de prédio necessária para visualizar relatórios',
  'Sent': 'enviados',
  'Received': 'recebidos',
  'All': 'Todos',
  'Pending': 'pendentes',
  'Your profile has been rejected for the following reasons': 'Seu perfil foi rejeitado pelos seguintes motivos',
  'Please complete all of your profile information in order to start using Podesubir': 'Por favor, preencha todas as informações do seu perfil para começar a usar Podesubir',
  'Missing fields': 'Campos ausentes / inválidos',
  'Your profile is under review. You will receive a response within 24hs': 'O seu perfil está em revisão. Você receberá uma resposta dentro de 24 horas',
  'Profile picture': 'Foto do perfil',
  'ID number': 'Número de identificação',
  'Documets ID Front picture': 'Foto da frente da identificação',
  'Documents ID Back picture': 'Foto do dorso da sua identificação',
  'Click here to correct the relevant fields': 'Clique aqui para corrigir os campos necessários',
  'Email verification': 'Verificação de e-mail',
  'Cellphone verification': 'Verificação por telefone celular',
  'Basic information': 'Informação pessoal',
  'ID documents': 'Documento de identidade',
  'Finalize': 'Finalizar',
  'We have sent you an email to your address': 'Enviámos um email para o seu endereço de email',
  'Please follow the instructions and click on continue': 'Siga as instruções e clique em continuar',
  'If you did not received the email you can': 'Se você não recebeu o e-mail, pode',
  'request a new verification email': 'Pedir um novo e-mail de verificação',
  'Alternatively you can': 'Como alternativa, você pode',
  'change your email address': 'Mude seu endereço de email',
  'Continue': 'Continuar',
  'Change email': 'Mude o e-mail',
  'A new email was sent to your address': 'Um novo email foi enviado para o seu endereço',
  'Change email address': 'Mude o endereço de email',
  'Your email address was changed and a new verification email was sent': 'O seu endereço de email foi alterado e um novo email de verificação foi enviado',
  'If you made a mistake or want to change your phone number': 'Se você cometeu um erro ou deseja alterar seu número de telefone',
  'Change cellphone': 'Alterar número de telefone',
  'We will now verify your cellphone number': 'Vamos verificar o seu número de telefone',
  'We will send you a 4 digit verification code via SMS': 'Enviaremos um código de verificação de quatro dígitos por SMS',
  'I am ready, send me the verification code': 'Estou pronto, envie o código de verificação',
  'click here': 'Clique aqui',
  'Change cellphone number': 'Alterar número de telefone',
  'An SMS was sent to your cellphone with the verification code': 'Enviámos um SMS para o seu número com o código de verificação',
  'Invalid code': 'O código digitado é inválido ou expirou. Marque sua caixa de SMS ou solicite um novo SMS de verificação, se necessário ',
  'Your cellphone number was changed and a new verification sms was sent': 'O seu número de telefone foi alterado e um novo SMS de verificação foi enviado',
  'Country of residence': 'País de residência',
  'cpf is a required field': 'Requeridos',
  'Your face must be visible and centered in the picture frame': 'Seu rosto deve estar visível e centralizado na imagem',
  'No other people should be visible in the picture': 'Nenhuma outra pessoa deve aparecer na imagem',
  'The picture must match the picture in the ID documents from the next step': 'A imagem deve corresponder à foto da sua próxima etapa da documentação',
  'Take picture': 'Tire uma foto',
  'NO_FACES': 'Nenhum rosto foi detectado na imagem. Leia as instruções e tente novamente ',
  'MULTIPLE_FACES': 'Mais de um rosto foi detectado na imagem. Leia as instruções e tente novamente ',
  'Confirm and save': 'Confirme',
  'Make sure the ID pictures are clear and all the information can be seen': 'Verifique se as imagens no seu documento estão claras com todas as informações visíveis',
  'The face in the front of the ID must match your profile picture': 'O rosto na frente do documento deve corresponder ao rosto na foto do perfil',
  'The information in the ID must match the information you provided in the previous step': 'As informações no documento devem corresponder às informações da etapa anterior',
  'ID picture front': 'frente do documento',
  'ID picture back': 'verso do documento',
  'You have completed all the registration steps': 'Concluiu todas as etapas de registro',
  'If you would like to change any information you can go back to a previous step': 'Se você quiser alterar as informações, volte para as etapas anteriores',
  'When you are ready, press finalize to complete the process': 'Quando estiver pronto, pressione Concluir para finalizar o processo',
  'MISSING_REVIEW_CORRECTIONS': 'Há correções de perfil pendentes. Corrija-os antes de terminar ',
  'FACE_DOESNT_MATCH': 'A imagem carregada como uma foto de perfil não corresponde à frente do seu documento de identidade',
  'INFORMATION_DOESNT_MATCH': 'Algumas das informações carregadas em "Informações pessoais" não correspondem às informações no seu documento.',
  'There was a validation error with the information provided': 'Ocorreu um erro ao validar as informações fornecidas',
  'Go back to the previous steps and correct the information': 'Volte para as etapas anteriores e corrija as informações relevantes',
  'If the information supplied is correct, click the following button to submit everything as is and a customer support representative will review your profile': 'Se as informações fornecidas estiverem corretas, clique no botão a seguir para enviar tudo em seu estado atual. Um representante do serviço ao cliente analisará seu perfil ',
  'Submit my profile for review': 'Enviar meu perfil para revisão',
  'Your profile is under review by a customer service representative. We will notify you via email once the review is complete': 'Seu perfil está sendo analisado por um representante de atendimento ao cliente. Notificaremos você por e-mail quando a revisão estiver concluída ',
  'Your profile has been rejected by a customer support representative': 'Seu perfil foi rejeitado por um representante de atendimento ao cliente',
  'Please correct the following fields and re-submit your profile to be reviewed again': 'Corrija os seguintes campos e reenvie seu perfil para ser analisado novamente',
  'name': 'Nome',
  'surname': 'Sobrenome',
  'company': 'companhia',
  'email': 'E-mail',
  'company_role': 'posição / posição na empresa',
  'birthdate': 'data de nascimento',
  'id_type': 'Tipo de ID',
  'id_number': 'Número de identidade',
  'cpf': 'Número CPF',
  'profile_picture': 'Imagem de perfil',
  'ID_FRONT': 'Frente do documento fotográfico',
  'ID_BACK': 'Documento com foto',
  'You have performed all the requested corrections. Submit your profile for review': 'Você fez todas as correções solicitadas. Envie seu perfil para ser analisado novamente ',
  'Your profile is now complete and your account verified. You can start using podesubir': 'Seu perfil está completo e sua conta validada. Você pode começar a usar podesubir ',
  'true': 'As imagens no seu documento não correspondem às informações',
  'Access code': 'Código de acesso',
  'employees': 'funcionários',
  'Filter': 'filtrar',
  'Roles': 'Cargos',
  'Roles in the building': 'Alçadas no edifício',
  'users': 'usuarios',
  'Users': 'usuarios',
  'Time': 'Hora',
  'next': 'prosseguir',
  'Add new role': 'Adicionar alçadas',
  'your session expired. Please log in again': 'sessão expirada. Faça o login novamente',
  'You are trying to log in from an unknown device. We have sent you an email with a verification code. Please input the code below to complete your login': 'Você está tentando fazer login de um dispositivo desconhecido. Enviamos a você um e-mail com um código de verificação. Por favor insira o código abaixo para completar o seu login',
  'Go back': 'Voltar',
  'INVALID_AUTH': 'Usuário ou senha incorretos',
  'EMAIL_ALREADY_VERIFIED': 'O endereço de e-mail já foi verificado',
  'CELLPHONE_ALREADY_VERIFIED': 'O número de telefone já foi verificado',
  'INVALID_TOKEN': 'O token não é válido',
  'FORBIDDEN': 'Você não tem permissão para realizar esta ação',
  'USER_NOT_FOUND': 'Usuário não encontrado',
  'NOT_FOUND': 'Registro não encontrado',
  'MEETING_IS_PAST': 'A reunião está no passado. Não consigo realizar a ação ',
  'MISSING_INVITATIONS_PERMISSIONS': 'Você não tem autorização para fazer convites neste prédio',
  'USER_IS_NOT_GUEST': 'Você não é um convidado. A ação não pode ser realizada ',
  'PAST_DUE': 'A reunião está no passado. Não consigo realizar a ação ',
  'CONFIRMATION_FAILED': 'O convite não pôde ser confirmado',
  'REJECTION_FAILED': 'O convite não pôde ser recusado',
  'CANCELLATION_FAILED': 'O convite não pôde ser cancelado',
  'EMAIL_ALREADY_IN_USE': 'O e-mail inserido já está em uso',
  'CELLPHONE_ALREADY_IN_USE': 'O celular inserido já está em uso',
  'PROFILE_NOT_APPROVED': 'Você não tem permissão para realizar esta ação',
  'VALIDATION_ERROR': 'Erro de validação',
  'The cellphone entered is already in use': 'O celular inserido já está em uso',
  'The email entered is already in use': 'O e-mail inserido já está em uso',
  'Registration': 'Cadastro',
  'Domain': 'Domínio',
  'Total users': 'Total de usuários',
  'previous': 'anterior',
  'Total approved': 'Total aprovados',
  '% approved': '% aprovado',
  'Users growth': 'Crescimento usuários',
  'Approved growth': 'Crescimento aprovados',
  'Compare data to previous': 'Compare os dados com o anterior',
  'Trimester': 'Trimestre',
  'Month': 'Mês',
  'Week': 'Semana',
  'Day': 'Dia',
  'Hour': 'Hora',
  'Access list': 'Lista de acesso',
  'Security staff': 'Guarda de segurança',
  'Access date': 'Data de admissão',
  'PS user profile approved': 'Perfil de usuário PS aprovado',
  'PS user profile complete': 'Perfil de usuário PS completo',
  'PS user email': 'E-mail do usuário PS',
  'Has invitation in PS': 'Você tem um convite no PS',
  'Download CSV': 'Baixar CSV',
  'Card code': 'Código do cartão',
  'Used QR': 'QR usado',
  'Only with corresponding invitation': 'Apenas com convite em PS',
  'Only with corresponding user in PS': 'Somente com usuário no PS',
  'Only with profile completed in PS': 'Somente com usuário completo no PS',
  'Only with profile approved in PS': 'Somente com usuário aprovado em PS',
  'Request the guest to announce it\'s arrival to be allowed in': 'Solicite ao seu convidado que anuncie a sua chegada para poder entrar',
  'Your guest arrived to the meeting. Click here to see more': 'Seu convidado chegou à reunião. Clique aqui para ver mais',
  'The host delayed your entry to the meeting. Click here to see more': 'O anfitrião atrasou sua entrada na reunião. Clique aqui para ver mais',
  'The host allowed your entry to the meeting. Click here to see more': 'O anfitrião permitiu sua entrada na reunião. Clique aqui para ver mais',
  'The waiting time is over. We have reminded the host about your arrival': 'O tempo de espera acabou. Nós lembramos o anfitrião sobre sua chegada',
  'Your host requested for you to wait {{minutes}} minutes': 'Seu anfitrião pediu para você esperar {{minutos}} minutos',
  'When your access is authorized you will be able to access directly with the QR code that will appear here': 'Quando seu acesso for autorizado você poderá acessar diretamente com o código QR que aparecerá aqui',
  'Close': 'Ok',
  'Your arrival has been notified': 'Sua chegada foi notificada',
  'Announce arrival': 'Anunciar a chegada',
  'Allow access': 'Permitir acesso',
  'Wait 5 minutes': 'Espere 5 minutos',
  'Wait 10 minutes': 'Esperar 10 minutos',
  'Your guest arrived': 'Seu convidado chegou',
  'The guest has been allowed access': 'O acesso foi permitido ao convidado',
  'Allow access?': 'permitir acesso?',
  'The guest has been notified of the delay': 'o convidado foi notificado do tempo de espera',
  'Delay the access?': 'pedir para esperar?',
  'agenda belongs to': 'agenda de',
  'You must not be wearing a facemask or anything that partially covers your face': 'Você não deve usar máscaras ou qualquer coisa que cubra parcialmente seu rosto',
  'For a better experience use Google Chrome or Microsoft Edge': 'Para uma melhor experiência use o Google Chrome ou Microsoft Edge',
  'New picture': 'Nova foto',
  'Search': 'Procurar',
  'Security guard': 'Guarda',
  'guard.access.ENTRY': 'ENTRADA AUTORIZADA',
  'guard.access.EXIT': 'SAIDA AUTORIZADA',
  'guard.access.DENY_ENTRY': 'ENTRADA NEGADA',
  'Event': 'evento',
  'Access allowed by guards': 'Acesso permitido pelos guardas',
  'who.visited.who': 'quem visitou quem',
  'Employees per company': 'Pessoas por empresa',
  'Local': 'local',
  'Host name': 'Nome do anfitrião',
  'Host surname': 'Sobrenome do anfitrião',
  'Host email': 'E-mail do anfitrião',  
  'Guest name': 'Nome do visitante',
  'Guest surname': 'Sobrenome do visitante',
  'Guest email': 'E-mail do convidado',  
  'Guest phone': 'Celular do convidado',
  'Guest document': 'Documento do convidado',
  'Guest CPF': 'CPF do convidado',
  'Host company': 'Empresa do anfitrião',
  'Guest company': 'Empresa do visitante',
  'Export': 'exportar',
  'Order:': 'Ordenar:',
  'Badge number': 'Número do crachá',
  'Guest badge number': 'Número do crachá do convidado',
  'Date of visit': 'Data da visita',
  'Start time': 'Hora de início',
  'Finish time': 'Hora fim',
  'Document number': 'Número de documento',
  'Document type': 'Tipo do Documento',
  'Date and time format': 'DD/MM/YYYY HH:mm',
  'This access code is associated with:': 'Este código de acesso se encontra associado a:',
  'Return': 'Voltar',
  'Are you sure about that?': 'Você tem certeza disso?',
  'Access code linked to another user': 'Código de acesso vinculado a outro usuário',
  'Associate with new user': 'Associar ao novo usuário',
  'Unlinked user': 'Usuário desvinculado',
  Copy: 'Copiar',
  'Taking photo': 'Tirar foto',
  'Send image': 'Enviar imagem',
  'Max date Error': 'Periodo máximo de 3 meses',
  'Min date Error': 'Não pode ser menor que data inicio'
};