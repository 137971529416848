import * as React from 'react';
import PwdRecoveryForm from '../../components/Login/pwd-recovery';
import * as AuthApi from '../../api/auth';

export default class PwdRecoveryContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      hash: this.props.match.params.hash,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    await AuthApi.pwdRestore({
      token: this.state.hash,
      ...body,
    });

    this.setState({ success: true });
  }

  render() {
    return (
      <PwdRecoveryForm
        onSubmit={this.onSubmit}
        success={this.state.success}
      />
    )
  }
}
