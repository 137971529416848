import axios from 'axios';
import request from './index';
import { toast } from 'react-toastify';
import { req, res } from '../actions/ui';
import store from '../store';
import i18n from '../i18n';

const BASEURL = process.env.REACT_APP_API_URL;

const catchError = (error) => {
  const { data } = error.response;

  toast.error(i18n.t(data.message || data.error));

  store.dispatch(res());

  throw error;
}

export async function getDeviceId() {
  const id = localStorage.getItem('device_id');

  if (id) {
    return id;
  }

  try {
    const { data } = await axios.get('http://ip-api.com/json');

    const s = btoa(`${data.query}-${navigator.platform || navigator.userAgent}`);

    localStorage.setItem('device_id', s);

    return s;
  } catch (err) {
    const s = btoa(navigator.userAgent);

    localStorage.setItem('device_id', s);

    return s;
  }
}

export function register(data) {
  store.dispatch(req());

  return axios({
    data,
    method: 'POST',
    url: `${BASEURL}/api/auth/register`,
  })
    .then((response) => {
      store.dispatch(res());

      return response;
    })
    .catch(catchError);
}

export function me() {
  return request({
    method: 'GET',
    path: '/api/users',
  });
}

export function logout() {
  return axios({
    data: { token: localStorage.getItem('fcmtoken') },
    method: 'POST',
    url: `${BASEURL}/api/auth/logout`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  })
}

export function login(data) {
  store.dispatch(req());

  return axios({
    data,
    method: 'POST',
    url: `${BASEURL}/api/auth/login`,
  })
    .then((response) => {
      store.dispatch(res());

      return response;
    })
    .catch(catchError);
}

export function verifyEmail(token) {
  store.dispatch(req());

  return axios({
    method: 'POST',
    url: `${BASEURL}/api/auth/verify-email/${token}`,
  })
    .then((response) => {
      store.dispatch(res());

      return response;
    })
    .catch(catchError);
}

export function requestPwdRestore(data) {
  store.dispatch(req());

  return axios({
    data,
    method: 'POST',
    url: `${BASEURL}/api/auth/request-password-restore`,
  })
    .then((response) => {
      store.dispatch(res());

      return response;
    })
    .catch(catchError);
}

export function pwdRestore(data) {
  store.dispatch(req());

  return axios({
    data,
    method: 'POST',
    url: `${BASEURL}/api/auth/password-restore`,
  })
    .then((response) => {
      store.dispatch(res());

      return response;
    })
    .catch(catchError);
}

export function resendEmail() {
  return request({
    method: 'POST',
    path: '/api/auth/request-email-verification',
  });
}

export function correctEmail(body) {
  return request({
    body,
    method: 'POST',
    path: '/api/auth/correct-email',
  });
};

export function sendSms() {
  return request({
    method: 'POST',
    path: '/api/auth/request-cellphone-verification',
  });
}

export function verifyCellphone(body) {
  return request({
    body,
    method: 'POST',
    path: '/api/auth/verify-cellphone',
  });
}

export function correctCellphone(body) {
  return request({
    body,
    method: 'POST',
    path: '/api/auth/correct-cellphone',
  });
};

export function basicInformation(body) {
  return request({
    body,
    method: 'POST',
    path: '/api/auth/basic-information',
  });
}

export function profilePicture(file) {
  const fd = new FormData();
  fd.append('profile_picture', file);

  store.dispatch(req());

  return axios.post(`${BASEURL}/api/auth/profile-picture`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  }).then((response) => {
    store.dispatch(res());

    return response;
  }).catch(catchError);
}

export function documents(filesObj) {
  const fd = new FormData();
  Object.keys(filesObj).forEach((key) => {
    fd.append(key, filesObj[key]);
  });

  store.dispatch(req());

  return axios.post(`${BASEURL}/api/auth/documents`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  }).then((response) => {
    store.dispatch(res());

    return response;
  }).catch(catchError);
}

export function confirmInformation(body) {
  return request({
    body,
    method: 'POST',
    path: '/api/auth/confirm-information',
  });
}
