import request from './index';

export function list(params) {
  return request({
    params,
    method: 'GET',
    path: '/api/buildings',
  });
}

export function get(id) {
  return request({
    method: 'GET',
    path: `/api/buildings/${id}`,
  });
}

export function listKeys(id) {
  return request({
    method: 'GET',
    path: `/api/buildings/${id}/keys/list`,
  });
}

export function update(id, body) {
  return request({
    body,
    method: 'PUT',
    path: `/api/buildings/${id}`,
  });
}

export function activate(id, body) {
  return request({
    body,
    method: 'PUT',
    path: `/api/buildings/activate/${id}`,
  });
}

export function deactivate(id, body) {
  return request({
    body,
    method: 'PUT',
    path: `/api/buildings/deactivate/${id}`,
  });
}

export function usersAutocomplete(id, params) {
  return request({
    params,
    method: 'GET',
    path: `/api/buildings/${id}/users/autocomplete`,
  })
}

export function users(id, params) {
  return request({
    params,
    method: 'GET',
    path: `/api/buildings/${id}/users`,
  })
}

export function getUser(buildingId, userId) {
  return request({
    method: 'GET',
    path: `/api/buildings/${buildingId}/users/${userId}`,
  });
}

export function addUser(id, body) {
  return request({
    body,
    method: 'PUT',
    path: `/api/buildings/${id}/add-user`,
  });
}

export function searchUserByKey(id, params) {
  return request({
    params,
    method: 'GET',
    path: `/api/buildings/${id}/building-keys/search`,
  });
}

export function searchUser(id, params) {
  return request({
    params,
    method: 'GET',
    path: `/api/buildings/${id}/users/search`,
  })
}

export function removeUser(buildingId, id) {
  return request({
    method: 'DELETE',
    path: `/api/buildings/${buildingId}/remove-user/${id}`,
  });
}

export function removeRoles(buildingId, userId) {
  return request({
    method: 'DELETE',
    path: `/api/buildings/${buildingId}/remove-roles/${userId}`,
  });
}

export function listCompanies(id, params) {
  return request({
    params,
    method: 'GET',
    path: `/api/buildings/${id}/companies`,
  });
}

export function getCompany(buildingId, companyId) {
  return request({
    method: 'GET',
    path: `/api/buildings/${buildingId}/companies/${companyId}`,
  });
}

export function createCompany(buildingId, body) {
  return request({
    body,
    method: 'POST',
    path: `/api/buildings/${buildingId}/companies`,
  });
}

export function updateCompany(buildingId, companyId, body) {
  return request({
    body,
    method: 'PUT',
    path: `/api/buildings/${buildingId}/companies/${companyId}`
  });
}

export function getAccessConfig(buildingId) {
  return request({
    method: 'GET',
    path: `/api/buildings/${buildingId}/access-code`,
  });
}
