import * as React from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  Grid,
  Paper,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Input } from '../Shared/BsInput';
import { CarSchema } from '../../validation-schemas';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        type="text"
        value={values.make}
        onChange={handleChange}
        name="make"
        label={t('Make')}
        errors={errors}
      />
      <Input
        type="text"
        value={values.model}
        onChange={handleChange}
        name="model"
        label={t('Model')}
        errors={errors}
      />
      <Input
        type="text"
        value={values.color}
        onChange={handleChange}
        name="color"
        label={t('Color')}
        errors={errors}
      />
      <Input
        type="text"
        value={values.license}
        onChange={handleChange}
        name="license"
        label={t('License plate')}
        errors={errors}
      />
      <hr />
      <Button
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting}
        fullWidth
      >
        {t('Save')}
      </Button>
    </form>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '10%',

    [theme.breakpoints.up('sm')]: {
      marginTop: '2%',
    },
  },
  paper: {
    padding: '10px',
    paddingBottom: '20px',
    marginBottom: '10px',
  },
  title: {
    marginBottom: '20px',
  },
}));

export default ({ onSubmit, isLoading, car }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={10} lg={6} className={classes.root}>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" align="center">
            {t('My vehicle')}
            <div className="pull-right">
              <Link to="/app/account-configuration">
                <i className="fa fa-share fa-flip-horizontal"></i>
              </Link>
            </div>
          </Typography>
          {
            !isLoading && <Formik
              initialValues={car}
              enableReinitialize={true}
              validationSchema={CarSchema}
              onSubmit={
                async (values, { setSubmitting, setErrors }) => {
                  try {
                    await onSubmit(values);
                    setSubmitting(false);
                  } catch (err) {
                    if (err.response && err.response.data.errors) {
                      setErrors(err.response.data.errors);
                    }

                    setSubmitting(false);
                  }
                }
              }
              render={p => <RenderForm {...p} {...{ t }}/>}
            />
          }
        </Paper>
      </Grid>
    </Grid>
  );
}
