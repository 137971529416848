import { LOGIN_SUCCESS, LOGOUT, UPDATE_USER_DATA, TOS_ACCEPTED } from '../constants';

const defaultState = {
  loggedIn: false,
  user: null,
  forcedLogout: false,
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case TOS_ACCEPTED: {
      return {
        ...state,
        user: {
          ...state.user,
          tos: true,
          privacy: true,
        },
      };
    }
    case UPDATE_USER_DATA: {
      const { data } = action;

      localStorage.setItem('user', JSON.stringify(data));

      return Object.assign({}, state, {
        user: data,
      });
    }
    case LOGIN_SUCCESS: {
      const { user, token } = action.data;

      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));

      return Object.assign({}, state, {
        loggedIn: true,
        user,
        forcedLogout: false,
      });
    }
    case LOGOUT: {
      localStorage.removeItem('token');
      localStorage.removeItem('user');

      return Object.assign({}, state, {
        loggedIn: false,
        user: null,
        forcedLogout: action.force,
      });
    }
    default: {
      return state;
    }
  }
}