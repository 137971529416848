import * as React from 'react';
import * as _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CarForm from '../../components/Car/form';
import * as UsersApi from '../../api/users';

class CarContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      car: null,
      isLoading: true,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    await UsersApi.updateCar(_.pick(body, ['make', 'model', 'color', 'license']));

    toast.success(this.props.t('Your vehicle information was updated'));
    this.props.history.push('/app/account-configuration');
  }

  render() {
    return (
      <CarForm
        onSubmit={this.onSubmit}
        car={this.state.car}
        isLoading={this.state.isLoading}
      />
    );
  }

  async componentDidMount() {
    const { data } = await UsersApi.getCar();

    this.setState({
      car: data,
      isLoading: false,
    });
  }
}


export default withTranslation()(CarContainer);
