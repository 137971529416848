import * as React from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  Grid,
} from '@material-ui/core';
import { Input, Phone, Password, Checkbox } from '../Shared/BsInput';
import { RegisterSchema } from '../../validation-schemas';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  isValid,
  t,
  blockMail
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        type="email"
        value={values.email}
        onChange={handleChange}
        name="email"
        label={t('Email')}
        errors={errors}
        //disabled={blockMail}
      />
      <Phone
        onChange={(v) => {
          setFieldValue('cellphone', v);
        }}
        label={t('Cellphone number')}
        errors={errors}
        name="cellphone"
      />
      <Password
        value={values.password}
        onChange={handleChange}
        name="password"
        label={t('Password')}
        errors={errors}
      />
      <Password
        value={values.password_confirm}
        onChange={handleChange}
        name="password_confirm"
        label={t('Confirm password')}
        errors={errors}
      />
      <hr />
      <div>
        <Checkbox
          name="tos"
          value={values.tos}
          onChange={handleChange}
          errors={errors}
        />
        <>
          <a href="http://www.podesubir.com/termos-e-condicoes" target="_blank" rel="noopener noreferrer">{t('Terms and Conditions')}</a>
        </>
      </div>
      <div>
        <Checkbox
          name="privacy"
          value={values.privacy}
          onChange={handleChange}
          errors={errors}
        />
        <>
          <a href="http://www.podesubir.com/politica-de-privacidade" target="_blank" rel="noopener noreferrer">{t('Privacy policy')}</a>
        </>
      </div>
      <Button
        className="mt-2"
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting || !isValid}
        fullWidth
      >
        {t('Sign up')}
      </Button>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  toolBar: {
    minHeight: '50px',
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',

    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  },
}));

export default ({ onSubmit, mail }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const decodeMail = () => {
    return Buffer.from(mail, 'base64').toString('binary');
  }

  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" spacing={3} style={{ marginBottom: '20%' }}>
        <Grid item xs={12} sm={8} md={4}>
          <Typography align="center" variant="h6">
            {t('Register')}
          </Typography>
          <Formik
            initialValues={{
              email: mail ? decodeMail() : '',
              cellphone: '',
              password: '',
              password_confirm: '',
              tos: false,
              privacy: false,
            }}
            validationSchema={RegisterSchema}
            enableReinitialize={true}
            onSubmit={
              async (values, { setSubmitting, setErrors }) => {
                try {
                  const clean = {
                    ...values,
                    cellphone: values.cellphone.replace('+', ''),
                  };

                  await onSubmit(clean);
                } catch (err) {
                  if (err.response && err.response.data.errors) {
                    setErrors(err.response.data.errors);
                  }

                  setSubmitting(false);
                }
              }
            }
            render={p => <RenderForm {...p} {...{ t }} blockMail={!!mail}/>}
          />
        </Grid>
      </Grid>
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Typography align="center" variant="body2" component="div" style={{ flex: 1 }}>
            {t('Already have an account?')} <Link className={classes.link} to="/login">{t('sign in')}</Link>
          </Typography>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}