import axios from 'axios';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { logout } from '../actions/auth';
import { req, res } from '../actions/ui';
import store from '../store';
import i18n from '../i18n';

const BASEURL = process.env.REACT_APP_API_URL;

export function catchError(error) {
  if (!error.response) {
    console.log(error);

    return;
  }

  const {status, data} = error.response;


  switch (status) {
    case 401:
      store.dispatch(logout());
      window.location.href = '/login';
      break;
    default: {
      toast.error(i18n.t(data.message || data.error));
    }
  }

  store.dispatch(res());

  throw error;
}

function removeEmptyQs(params) {
  return Object.keys(params).reduce((acc, key) => {
    if (params[key] || params[key] === 0) {
      acc[key] = params[key];
    }

    return acc;
  }, {});
}

export default function request({method, params, body, path}) {
  const options = {
    method,
    url: `${BASEURL}${path}`,
    headers: {},
  };

  if (localStorage.getItem('token')) {
    options.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }

  if (params) {
    if (params.where) {
      params.where = removeEmptyQs(params.where);
    } else {
      params = {
        ..._.pick(params, ['attributes', 'limit', 'offset', 'order']),
        ...removeEmptyQs(_.omit(params, ['attributes', 'limit', 'offset', 'order'])),
      };
    }

    options.params = params;
  }

  if (body) {
    options.data = Object.keys(body).reduce((acc, key) => {
      if (body[key] === '__none__') {
        return acc;
      }

      acc[key] = body[key];

      return acc;
    }, {});
  }

  store.dispatch(req());

  return axios(options)
    .then((response) => {
      store.dispatch(res());

      return response;
    }).catch(catchError);
}


export function sseListen({path, onData, onError}) {
  const authToken = localStorage.getItem('token');

  const url =  `${BASEURL}${path}`.replace(/\?$/, '') + `?jwt=${authToken}`;
  const events = new EventSource(url);

  events.addEventListener('message', event => {
    try {
      onData(JSON.parse(event.data));
    } catch (error) {
      console.error('[access-code-sse] Error in data parse', error);
      onError && onError(error);
    }
  }, false);

  events.addEventListener('open', () => {
    console.log('[access-code-sse] Opened stream');
  }, false);

  events.addEventListener('error', error => {
    console.error('[access-code-sse] Error in stream', error);
    onError && onError(error);
  }, false);

  return {
    close: () => {
      events.close();
    }
  };
}
