import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import ReviewPending from '../Register/review-pending';
import { PrivateRoute } from './routes';

export default ({ loggedIn }) => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/app"
        component={ReviewPending}
        loggedIn={loggedIn}
      />
      <Redirect to={loggedIn ? '/app' : '/login'} />
    </Switch>
  );
}