import * as React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Paper,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '10%',
    textTransform: 'lowercase',

    [theme.breakpoints.up('sm')]: {
      marginTop: '2%',
    },
  },
  paper: {
    padding: '10px',
    paddingBottom: '20px',
    marginBottom: '10px',
  },
  title: {
    marginBottom: '20px',
  },
}));

export default (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user } = props;

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={10} lg={6} className={classes.root}>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" align="center">
            {t('Account')}
          </Typography>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <Link
                to="/app/profile"
                className="gray-text block"
              >
                <i className="fa fa-list-alt fa-fw"></i> {t('My information')}
                <span className="pull-right">
                  <i className="fa fa-chevron-right"></i>
                </span>
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/app/my-vehicle"
                className="gray-text block"
              >
                <i className="fa fa-car fa-fw"></i> {t('My vehicle')}
                <span className="pull-right">
                  <i className="fa fa-chevron-right"></i>
                </span>
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/app/change-password"
                className="gray-text block"
              >
                <i className="fa fa-lock fa-fw"></i> {t('Change password')}
                <span className="pull-right">
                  <i className="fa fa-chevron-right"></i>
                </span>
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/app/change-email"
                className="gray-text block"
              >
                <i className="fa fa-envelope-open fa-fw"></i> {t('Change email')}
                <span className="pull-right">
                  <i className="fa fa-chevron-right"></i>
                </span>
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/app/change-phone"
                className="gray-text block"
              >
                <i className="fa fa-phone fa-fw"></i> {t('Change phone')}
                <span className="pull-right">
                  <i className="fa fa-chevron-right"></i>
                </span>
              </Link>
            </li>
            {
              !user.cellphone_verified_at && (
                <li className="list-group-item">
                  <Link
                    to="/app/verify-phone"
                    className="gray-text block"
                  >
                    <i className="fa fa-check fa-fw"></i> {t('Verify phone')}
                    <span className="pull-right">
                      <i className="fa fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              )
            }
            <li className="list-group-item">
              <Link
                to="/app/secretaries"
                className="gray-text block"
              >
                <i className="fa fa-users fa-fw"></i> {t('My secretaries')}
                <span className="pull-right">
                  <i className="fa fa-chevron-right"></i>
                </span>
              </Link>
            </li>
            <li className="list-group-item">
              <a href="http://www.podesubir.com/termos-e-condicoes" target="_blank" rel="noopener noreferrer" className="gray-text block">
                <i className="fa fa-book fa-fw"></i> {t('terms')}
                <span className="pull-right">
                  <i className="fa fa-chevron-right"></i>
                </span>
              </a>
            </li>
            <li className="list-group-item">
              <a href="http://www.podesubir.com/politica-de-privacidade" target="_blank" rel="noopener noreferrer" className="gray-text block">
                <i className="fa fa-user-secret fa-fw"></i> {t('privacypolicy')}
                <span className="pull-right">
                  <i className="fa fa-chevron-right"></i>
                </span>
              </a>
            </li>
          </ul>
        </Paper>
      </Grid>
    </Grid>
  );
}